import React from 'react'
import {Link} from "react-router-dom";
import styles from "../../../scss/Card.module.scss"

function VerifyIdentity() {
  return (
    <div className='' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
        
      {/* <div>
    <Link to='/reviewbusinessinfo'>
      <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div> */}
        <h2 className='text-center mt-5'>Complete account verification</h2>
    
    <div className=' bg-white conatiner text-center mt-1 mt-5 mt-8 p-2 shadow-sm p-10 mb-5  rounded d-flex justify-content-center'>

    <div className='container  '>
        <div>
        {/* <h2>Complete account verification</h2> */}
        </div>
       <div><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={10} width={20} alt='' />2 minutes
       </div>
       <div className=' container p-2 text-dark bg-opacity-25 col-7 p-5flex-end rounded  mt-10'>
          <p className='text-start mt-5 text-muted'>Take full advantage of GS App, we recommend completing this step now by submitting a government ID. Doing this now will give you full access to all of GS features </p>
          <p className='text-start mt-5 text-muted' >You may skip this step but we'll likely ask you again sometime soon</p>
        </div>
        <div className='container p-2 text-dark bg-opacity-25 col-7 p-5flex-end rounded mt-10'>
        <Link to="/auth/finalstep">
        <button className='btn btn-warning  col-lg-12 ' type="submit">Continue</button>
       </Link>
       </div>
      </div> 
    </div>
    
       </div>
  )
}

export default VerifyIdentity;
