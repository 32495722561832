import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../_metronic/helpers";
import styles from "../scss/Card.module.scss";
import Toster from "../Components/AdminPanelToster";
import Services from "../Services";
import Constant from "../Constant";
import { userInfo } from "os";

export default function Send() {
  const navitage = useNavigate();
  const imageUrl = Constant.BASE_URL_UPLOADS;
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [gsTagUsers, setGsTagUsers] = useState<number>();
  const [gsTagUsersAcount, setGsTagUsersgsTagUsersAcount] = useState<number>();
  const [gsTagUsersFirstName, setGsTagUsersFirstName] = useState<string>("");
  const [gsTagUsersLastName, setGsTagUsersLastName] = useState<string>("");
  const [amountError, setAmountError] = useState("");
  const [gsTagUsersError, setGsTagUsersError] = useState("");
  const [gsTagUsersId, setGsTagUsersId] = useState("");
  const [amount, setAmount] = useState<any>();
  const [imageUser, setimageUser] = useState("");
  const [balanceGsGold, setBalanceGSGold] = useState<number>(
    Number(localStorage.getItem("Gsbalance")) || 0
  );

  interface ToasterState {
    type: string;
    title: string;
    text: string;
    visiblity: string;
  }

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const gsbalance = localStorage.getItem("Gsbalance");

  const exceptThisSymbols = ["+", "e", "E", "-"];
  const goToNextStep = () => {
    let isValid = true;

    localStorage.setItem("currentStep", (currentStep + 1).toString());
    // Move to the next step
    setCurrentStep(currentStep + 1);
  };
  const goToPreviousStep = () => {
    localStorage.setItem("currentStep", (currentStep - 1).toString());
    // Move to the previous step
    setCurrentStep(currentStep - 1);
  };
  //get uses gstag
  const getUserByGsTag = async (query) => {
    try {
      const response: any = await Services.User.GET_USER_BY_GSTAG(query);
      
      setGsTagUsersFirstName(response.data.gs.firstName);
      setGsTagUsersLastName(response.data.gs.lastName);
      setGsTagUsersgsTagUsersAcount(response.data.data.accountNumber);
      setimageUser(response.data.gs.profile);
      // setBalanceGSGold()

      setGsTagUsersId(response.data.gs.id);
      setGsTagUsersError("");
    } catch (error) {
      setGsTagUsersError("User Not Found ");
      setGsTagUsersFirstName("");
    }
  };

  useEffect(() => {
    const savedStep = localStorage.getItem("currentStep");
    // Check if there's a saved step, and update the current step state accordingly
    if (savedStep) {
      // setCurrentStep(parseInt(savedStep, 10));
    }
  }, []);

  //get account
  const gsAccount = sessionStorage.getItem("gsAccount"); // Get the string from sessionStorage

  const handleInputChange = (e: any) => {
    getUserByGsTag(e.target.value);
    setGsTagUsers(e.target.value);
  };

  const SendAmmount = async () => {
    const data = {
      // id: sessionStorage.getItem("id"),
      balance: amount,
      toAccount: gsTagUsersId,
    };

    try {
      const response = await Services.User.EXCHANGE_GS_TO_GS(data);
      if (response) {
        setToaster({
          type: "success",
          title: "Successful",
          text: "Exchange  successfully",
          visiblity: "show",
        });

        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 1000);
        setTimeout(() => {
          navitage("/sendsuccessful");
          localStorage.removeItem("currentStep");
        }, 500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function amountHandle(val: any) {
    if (val.length !== "") {
      setAmount(val);
      setAmountError("");
    }
    if (val.length >= 7) {
      setAmountError("Value Most Be 7 Digits");
    } else if (val > balanceGsGold) {
      setAmountError("Insufficient wallet balance  ");
    } else {
    }
  }

  return (
    <div>
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      {currentStep === 1 && (
        <div>
          <h2 className="text-center mb-6">Send Money</h2>
          <div
            className={`bg-white p-15 shadow-lg p-3 mb-5 bg-body-tertiary rounded ${styles.send}`}
          >
            <div
              className="input-group mb-3 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              {/* <span className="input-group-text" id="basic-addon1">@</span> */}
              <input
                type="number"
                className="form-control"
                placeholder="Gs tag,email,phone number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={gsTagUsers}
                onKeyDown={(e: any) => {
                  exceptThisSymbols.includes(e.key) && e.preventDefault();

                  if (
                    e.target.value.length >= 10 &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete"
                  ) {
                    e.preventDefault();
                    setGsTagUsersError("User Not Found ");
                  }
                }}
                onChange={handleInputChange}
              />
            </div>
            <div
              className="text-center fs-5 p-5"
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              {" "}
              <span className="">Send to anyone and anywhere</span>
              <br />
              <span className="mt-10">Send again to :</span>
            </div>

            {gsTagUsersError && gsTagUsersError ? (
              <>
                <div className="text-center  p-5">
                  <h5 className="" style={{ color: "red" }}>
                    {gsTagUsersError}
                  </h5>
                  <br />
                </div>
              </>
            ) : (
              <>
                <div className="container">
                  <div className=" p-3">
                    <div className="col-lg-12  d-flex justify-content-between">
                      <div className="content  m-2">
                       
                       {imageUser &&  <img
                          className=""
                          src={imageUrl + imageUser}
                          crossOrigin=""
                          height={100}
                          width={100}
                          alt=""
                        />
                       }
                        <br></br>
                        <div className="m-1 fs-3 text-center">
                          {gsTagUsersFirstName} &nbsp;{gsTagUsersLastName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div
              className=""
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <button
                className="btn btn-warning col-lg-12 mt-10"
                disabled={
                  !(
                    gsTagUsersAcount &&
                    gsTagUsersFirstName &&
                    gsTagUsersLastName
                  )
                }
                onClick={goToNextStep}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div>
          <div className="text-center">
            <h3> Send Amount</h3>
          </div>

          <div className=" p-6 bg-white container  mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded">
            <div className="col-lg-12 d-flex justify-content-between ">
              <i
                className="fs-1 bi bi-arrow-left-circle-fill"
                onClick={goToPreviousStep}
              ></i>

              <span className="fw-semibold">
                <div className="fv-row">
                  <label className="form-label mb-3 p-3">Enter Amount</label>
                  <input
                    type="number"
                    min="0"
                    className="mb-3 form-control form-control-lg form-control-solid"
                    name="account_name"
                    placeholder="Amount"
                    value={amount}
                    onKeyDown={(e: any) => {
                      exceptThisSymbols.includes(e.key) && e.preventDefault();

                      if (
                        e.target.value.length >= 7 &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e: any) => {
                      amountHandle(e.target.value);
                      if (e.target.value.length < 7) {
                        setAmountError("");
                      }
                      if (e.target.value > balanceGsGold) {
                        setAmountError("Insufficient wallet balance ");
                      }
                      if (e.target.value.length == "") {
                        setAmountError("Required");
                      }
                    }}
                  />
                  {amountError && (
                    <>
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                        }}
                      >
                        {amountError}
                      </span>
                    </>
                  )}
                </div>
              </span>
            </div>
            &nbsp; &nbsp; &nbsp;
            <div className=" fv-row ">
              <div className="row mb-2" data-kt-buttons="true">
                <span>Balance is : {localStorage.getItem("Gsbalance")}</span>

                <label className="d-flex align-items-center form-label mb-3">
                  Choose Coin
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Provide your team size to help us setup your billing"
                  ></i>
                </label>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="5"
                    />
                    <span className="fw-bold fs-3">5</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="10"
                    />
                    <span className="fw-bold fs-3">10</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="20"
                    />
                    <span className="fw-bold fs-3">20</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="50"
                    />
                    <span className="fw-bold fs-3">50</span>
                  </label>
                </div>
                <div className="">
                  <button
                    className="btn btn-warning col-lg-12 mt-10  "
                    disabled={!(amount > 0) || amount > balanceGsGold}
                    onClick={() => {
                      goToNextStep();
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div
          className=""
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="d-flex">
            <div>
              <i
                className="fs-1 bi bi-arrow-left-circle-fill"
                onClick={goToPreviousStep}
              ></i>
            </div>
            <h2 className={styles.head}>Confirm</h2>
          </div>

          <div className="mt-10 bg-white shadow-sm p-4 mb-5 bg-body-tertiary rounded">
            <p className="text-center mt-10 fs-3">
              Please review the details and confirm your exchange
            </p>
            <div className="container col-lg-12">
              <div className="d-flex justify-content-between align-items-start mt-20">
                <div className="">
                  {" "}
                  <span>From</span>
                </div>

                <div className="">
                  {" "}
                  <span className="fw-bolder"> Gs Account Number</span> <br />
                  <span> {gsAccount}</span>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-start mt-10">
                <div className="">
                  {" "}
                  <span>To</span>
                </div>

                <div className="">
                  {" "}
                  <span className="fw-bolder">GS Gold Account Number</span>{" "}
                  <br />
                  <span>{gsTagUsersAcount}</span>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-start mt-10">
                <div className="">
                  {" "}
                  <span>Ammount</span>
                </div>

                <div className="">
                  {" "}
                  <span className="fw-bolder">Ammount</span> <br />
                  <span>{amount}$</span>
                </div>
              </div>

              <div className="mt-10 mb-10 d-flex justify-content-between">
                <button className="btn btn-warning " onClick={goToPreviousStep}>
                  Cancel Send
                </button>
                <button className="btn btn-warning " onClick={SendAmmount}>
                  Finish Send{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
