import React, { useEffect, useState } from 'react'
import Spinner from '../../Components/Spinner';
import { Link, useLocation } from 'react-router-dom';
import Services from '../../Services';
import masterIcon from '../../../public/media/icons/master-card-icon.png'
import { KTIcon, toAbsoluteUrl } from '../../_metronic/helpers';
import moment from 'moment';

// master-card-icon.png
export default function MyCard() {
  const [cardList, setCardList] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // eslint-disable-next-line
  const [limit, setLimit] = useState(10); // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [bin, setBin] = useState({ list: [], selectedBin: "" });
  const queryParams = useLocation();

  const [cardData, setCardData] = useState<any>([])

  const GET_CARD = async () => {
    try {
      const response: any = await Services.Card.GET_CARD();


      setCardData(response.data.data);
    } catch (error) { }
  };
useEffect(() => {
    GET_CARD()
    //   getBinList();
    //   getCardList ();
  }, []);

  return (
    <div>
      <body
        id="kt_app_body"
        data-kt-app-header-stacked="true"
        data-kt-app-header-primary-enabled="true"
        data-kt-app-header-secondary-enabled="true"
        className="app-default"
      >



        <div >
          {/* begin::Header */}
          <h1 className='text-center'>Card</h1>
          <div className='card-header border-0 pt-5'>

            <div className='card-toolbar float-end' >
              <a href='#' className='btn btn-sm btn-light-primary'>
                <KTIcon iconName='plus' className='fs-2' />
                Add New Card
              </a>
            </div>
          </div>

          <div className='card-body py-3'>

            <div className='table-responsive'>

              <table className='table align-middle gs-0 gy-4'>

                <thead>

                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-250px rounded-start'>Card</th>
                    <th className='min-w-200px'>Balance</th>
                    <th className='min-w-200px'>CVV</th>
                    <th className='min-w-200px'>Type</th>
                    <th className='min-w-200px'>Expiry Date</th>

                    <th className='min-w-200px'>Action</th>



                  </tr>
                </thead>

                <tbody>

                  {cardData.map((item: any, key) => {
                    return (
                      <tr>
                        <td key={key}>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-5'>
                              <span className="svg-icon svg-icon-primary svg-icon-3x">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <rect fill="#000000" opacity="0.3" x="2" y="5" width="20" height="14" rx="2" />
                                    <rect fill="#000000" x="2" y="8" width="20" height="3" />
                                    <rect fill="#000000" opacity="0.3" x="16" y="14" width="4" height="2" rx="1" />
                                  </g>
                                </svg></span>


                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                {item.cardName}
                              </a>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {item.cardNumber}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {item.balance}
                          </a>

                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {item.cvv}
                          </a>

                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {item.type}
                          </a>

                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {moment(item.expiryDate).format('MMM DD, YYYY')}
                          </a>

                        </td>

                        <td className=''>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTIcon iconName='switch' className='fs-3' />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </a>
                          <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                            <KTIcon iconName='trash' className='fs-3' />
                          </a>
                        </td>
                      </tr>
                    );
                  })}


                </tbody>

              </table>

            </div>

          </div>

        </div>



      </body>
    </div>
  )
}
