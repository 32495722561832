/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import constant from "../Constant";
import api from "../api";

export default {
  LOGIN_USER: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.LOGIN(),

          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
     
        sessionStorage.setItem(
          "access_token",
          response.data.tokens.access.token
        );
        sessionStorage.setItem("email", response.data.user.email);
        sessionStorage.setItem("id", response.data.user.id);
        sessionStorage.setItem("role", response.data.user.role);
      
        sessionStorage.setItem(
          "user_Details",
          JSON.stringify(response.data.user)
        );
     
        Axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${sessionStorage.getItem("access_token")}`;
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  REGISTER_USER: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.REGISTER(),
          data
          // {
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          // }
        );
        sessionStorage.setItem(
          "access_token",
          response.data.tokens.access.token
        );
        sessionStorage.setItem("expires", response.data.tokens.access.expires);
        // sessionStorage.setItem("email", response.data.user.email);
        sessionStorage.setItem("role", response.data.role);
        // sessionStorage.setItem("id", response.data.user.id);
        // sessionStorage.setItem(
        //   "user_Details",
        //   JSON.stringify(response.data.user)
        // );
       
        Axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${sessionStorage.getItem("access_token")}`;
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  IMAGE_UPLOAD: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.IMAGE_UPLOAD(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  PROFILE_UPLOAD: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.PROFILE_UPLOAD(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
 
  },
  SEND_OTP: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.SEND_OTP(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
    
  },
  RESEND_OTP: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Auth.RESEND_OTP(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
    
  },
  VERIFY_OTP: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.VERIFY_OTP(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
    // RESEND_OTP
  },

  GET_GSTAG: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Auth.GET_GSTAG()
        );
        // alert("resxpomseeeeeeeee")
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  UPDATE_IMAGE: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Auth.UPDATE_IMAGE(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_iMAGE: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Auth.GET_iMAGE()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  FORGOT_PASSWORD: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Auth.FORGOT_PASSWORD(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
};
