import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../_metronic/helpers'

export default function WithdrawSuccesfull() {
  return (
    <div>
      <div className='' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">

      <div  className='container mt-20  col-log-lg-12 shadow-sm p-3 mb-5 bg-body-tertiary rounded'> 
            <div className='d-flex justify-content-center align-items-center mt-5'>
        <div > 
            <img src={toAbsoluteUrl('/media/logos/tick.png')} alt='' height={300} width={300}/><br/>
            <h4 className='fs-bolder mt-5 ms-2 text-center'>Withdrawal Successful</h4>
        </div>
        </div>
        <div className='d-flex justify-content-center align-items-center col-lg-12'>  
          <Link to="/transact"> <button className='btn btn-warning m-6 '>Transact</button></Link>
          <Link to="/"> <button className='btn btn-warning  m-6 '>Home</button></Link>


        </div>
      </div>
    </div>
    </div>
  )
}
