/* eslint-disable import/no-anonymous-default-export */
import Auth from "./Auth";
import Transaction from "./Transaction";
import AccountLink from "./AccountLink";
import User from "./User";
import Wallet from './Wallet'
import Card from "./Card";
import Support from "./Support";
import WithdrawalAc from "./WithdrawalAc";
import Move from "./Move"
import GsAccount from "./GsAccount";

export default {
  Auth,
  GsAccount,
  Transaction,
  AccountLink,
  User,
  Wallet,
  Card,
  Support,
  Move,
  WithdrawalAc
};
