import React, { useEffect, useState } from "react";

import Services from "../../Services";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../../scss/Card.module.scss";
import Toster from "../../Components/Toster";
import Constant from "../../Constant";

// import styles  from "../../../src/_metronic/assets/sass/_cardstyle.scss";
// /home/atpl2/Desktop/GS App/gs-webapp/src/_metronic/assets/sass/_cardstyle.scss

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

function ViewDetails() {
  const imagePic = Constant.BASE_URL_UPLOADS;
  // const isIdentityVerify: Number =1;
  // const [showPopup, setShowPopup] = useState(false);
  const [Approve, setApprove] = useState("");
  // const [Reject, setReject] = useState<Number>(3);

  const [FundAccount, setFundAcccount] = useState("");
  const [MoveAccount, setMoveAcccount] = useState("");
  const [sendAccount, setSendAcccount] = useState("");
  const [withdrawalAccount, setWithdrawalAcccount] = useState("");
  // const [FundAccount, setFundAcccont] = useState("");
  // const [FundAccount, setFundAcccont] = useState("");

  //  const [isIdentityVerify, setIsIdentityVerify] = useState(1);

  const { id } = useParams();
  let navigate = useNavigate();
  let navigate1 = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleConfirm = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [firstName, setFirstName] = useState("");
  const [gsTag, setGSTag] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailVerify, setEmailVerify] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberVerify, setPhoneNumberVerify] = useState("");
  const [dob, setDOB] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [documentID, setDocumentID] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [role, setRole] = useState("");
  const [image, setImage] = useState("");
  const [accountVerify, setAccountVerify] = useState("");
  const [error, setError] = useState("");
  

  // const [isDisabled, setIsDisabled] = useState(true);

  const [isImageVisible, setIsImageVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [useridDetails, setuseridDetails] = useState({
    isIdentityVerify: 0,
    comment: 1,
  });

  const [transactionHistroy, setTransactionHistory] = useState([]);
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const [comment, setComment] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState(0);
  interface Data {
    comment: string;

    isIdentityVerify: Number;
  }

  // let query: any;
  // let isIdentityVerify: any;

  // const [tab, setTab] = useState("1");

  const updateApprove = async () => {
    setComment("");
    setApprove("1");

    setIsSubmit(1);
    const data: any = { isIdentityVerify: 1 };
    try {
      const response: any = await Services.User.UPDATE_VERIFY_BY_ID(id, data);

      if (response.data) {
        setTimeout(() => {
          navigate("/user");
        }, 700);
        setToaster({
          type: "success",
          title: "Successfull",
          text: "Approved successfully",
          visiblity: "show",
        });
      }
    } catch (error) {
      setIsSubmit(0);

      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);
    }
  };
  const updateReject = async () => {
    setIsSubmit(3);
    const data: any = { isIdentityVerify: 3, comment };

    try {
      const response: any = await Services.User.UPDATE_VERIFY_BY_ID(id, data);
      if (response.data) {
        setTimeout(() => {
          navigate("/user");
        }, 700);
        setToaster({
          type: "success",
          title: "Successfull",
          text: "Rejected successfully",
          visiblity: "show",
        });
        setIsSubmit(3);
      }
    } catch (error) {
      setIsSubmit(3);

      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);
    }
  };

  const getBYID = async () => {
    try {
      const response: any = await Services.User.GET_BY_ID(id);
      setuseridDetails(response.data.data);
      setRole(response.data.data.role);
      setGSTag(response.data.data.gsTag);
      setFirstName(response.data.data.firstName);
      setImage(response.data.data.image);
      setLastName(response.data.data.lastName);
      setEmail(response.data.data.email);
      setEmailVerify(response.data.data.emailVerify);
      setPhoneNumber(response.data.data.phoneNumber);
      setPhoneNumberVerify(response.data.data.numberVerify);
      setDOB(response.data.data.dob);
      setAddress(response.data.data.address);
      setCountry(response.data.data.country);
      setDocumentID(response.data.data.documentId);
      setDocumentName(response.data.data.documentName);
      setAccountVerify(response.data.data.accountVerify);
    } catch (error) {}
  };

  useEffect(() => {
    getBYID();
    handlefundaccount();
  }, []);

  // const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   setComment(e.target.value);
  // };

  const handlefundaccount = async () => {
    setFundAcccount("1");

    try {
      const response: any = await Services.Transaction.GET_TRANSACTION_BY_ID(
        id,
        1
      );
      setTransactionHistory(response.data.data);
      // console.log("funddata===========================", response.data.data);
    } catch {}
  };

  const handlemoveaccount = async () => {
    setMoveAcccount("2");

    try {
      const response: any = await Services.Transaction.GET_TRANSACTION_BY_ID(
        id,
        2
      );
      setTransactionHistory(response.data.data);
      // console.log("movedata===========================", response.data.data);
    } catch {}
  };

  const handlesendaccount = async () => {
    setSendAcccount("3");

    try {
      const response: any = await Services.Transaction.GET_TRANSACTION_BY_ID(
        id,
        3
      );
      setTransactionHistory(response.data.data);
      // console.log("movedata===========================", response.data.data);
    } catch {}
  };

  const handlewithdrawalaccount = async () => {
    setWithdrawalAcccount("4");

    try {
      const response: any = await Services.Transaction.GET_TRANSACTION_BY_ID(
        id,
        4
      );
      const getWithdrawalList = async () => {
        const response: any =
          await Services.Transaction.GET_USER_WITHDRAW_TRANSACTIONS();
        const darrrr = response?.data?.data.filter(
          (item) => item.requestStatus === "Accepted"
        );
        setTransactionHistory(darrrr);
      };
      getWithdrawalList();
    } catch {}
  };

  const handlesubmit = (image) => {
    setSelectedImage(image);
    setIsImageVisible(true);
  };
  const handleCloseImage = () => {
    setSelectedImage(null);
    setIsImageVisible(false);
  };
  const handlecomment = (e)=>{
    if (e.key === " ") {
      e.preventDefault();
    }
  }
  return (
    <div>
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="" data-aos="fade-up-left">
        <div className={`d-flex justify-content-around `}>
          <div className={`col-lg-8  bg-white p-20 shadow p-3 mb-5 bg-body-tertiary rounded`}>
            <div className=''>
              <div className=''>
                <div>
                <div className="d-flex justify-content-between gap-4">

                  <div className=" mt-6  col-6 rounded p-4 mb-2   bg-body-secondary text-emphasis-dark">
                    <label> GS Tag : </label>
                    <span className="ms-3"> {gsTag.length > 10
                      ? `${gsTag.slice(0, 10)}...`
                      : gsTag}</span>
                  </div>

                  <div className=" d-flex justify-content-between mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
                    <div className="">
                      <label> Image : </label>
                    </div>
                    <div className="ms-2">
                      <button
                        onClick={() => handlesubmit(image)}
                        className="btn btn-secondary"
                        disabled={!image}
                      >
                        See Document
                      </button>
                    </div>
                  </div>

                  {isImageVisible && (
                    <div className="image-modal">
                      <div className="image-container"></div>
                      <img
                        crossOrigin="anonymous"
                        src={imagePic + selectedImage}
                        alt="image"
                        className={styles.fullscreenimage}
                      />

                      <button
                        className={styles.closebutton}
                        onClick={handleCloseImage}
                      >
                        <i className="fs-1 bi bi-x-circle-fill"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              </div>
              <div className="d-flex justify-content-between gap-3">
                <div className="mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> First Name : </label>
                  <span className="ms-3"> {firstName.length > 10
                      ? `${firstName.slice(0, 10)}...`
                      : firstName}</span>
                </div>
                <div className="mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> Last Name : </label>
                  <span className="ms-3"> {lastName.length > 10
                      ? `${lastName.slice(0, 10)}...`
                      : lastName}</span>
                </div>
              </div>

              <div className="gap-3 d-flex justify-content-between">
                <div className="mt-6  col-lg-6 p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> Email : </label>
                  <span className="ms-3"> {email.length > 10
                      ? `${email.slice(0, 10)}...`
                      : email}</span>
                </div>
                <div className="mt-6  col-lg-6 p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> Phone Number : </label>
                  <span className="ms-3"> {phoneNumber.length > 10
                      ? `${phoneNumber.slice(0, 10)}...`
                      : phoneNumber}</span>
                </div>
                {/* <div className="mt-6  col-lg-6 p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> Email Verify : </label>
                  <span className="">{emailVerify}</span>
                </div> */}
              </div>
              <div className="gap-3 d-flex justify-content-between">
                {/* <div className="mt-6  col-lg-6 p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> Phone Number Verify : </label>
                  <span className="">{phoneNumberVerify}</span>
                </div> */}
              </div>
              <div className="gap-3 d-flex justify-content-between">
                <div className="mt-6  col-lg-6 p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> DOB : </label>
                  <span className="ms-3"> {dob.length > 10
                      ? `${dob.slice(0, 10)}...`
                      : dob}</span>
                </div>
                <div className="mt-6  col-lg-6 p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> Address : </label>
                  <span className="ms-3"> {address.length > 10
                      ? `${address.slice(0, 10)}...`
                      : address}</span>
                </div>
              </div>

              <div className="gap-3 d-flex justify-content-between">
           
            
              </div>
     
            </div>

            <div className=" text-center"></div>
          </div>

          <div
            className={` ms-10  bg-white p-10 shadow p-3 mb-5 bg-body-tertiary rounded ${styles.box}`}
          >
            <div className="position-relative w-lg-250px ">
              <div>
                <div className=" flex-column p-3 d-flex justify-content-between ">
                   {/* (useridDetails?.isIdentityVerify === 0 || someOtherCondition) ( */}
                  {useridDetails?.isIdentityVerify === 0 || useridDetails?.isIdentityVerify === 2 ?(
                    <>
                      <div
                        className="form-group mb-5 "
                        data-aos="fade-up-right"
                      >
                        <label className=" fs-6 fw-semibold mb-2">
                          Comment <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control form-control-solid"
                          id="exampleFormControlTextarea1"
                          placeholder="Enter comment"
                          
                          rows={6}
                          value={comment}
                          onKeyDown={(e)=>{
                            handlecomment(e)
                          }}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                        />
                        <div className="mt-10 ms-1">
                          <span
                            className={`badge badge-pill ${
                              comment && comment?.length < 251
                                ? "badge-success"
                                : "badge-danger"
                            }`}
                          >
                            {comment?.length ? comment?.length : 0}/250
                          </span>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between gap-5"
                        data-aos="fade-up-left"
                      >
                        <div className="me-0">
                          <button
                            className="mt-10 fs-3 btn btn-success "
                            data-kt-menu-trigger="click"
                            title="req.body.tittle"
                            // data-kt-menu-placement="top-center"

                            disabled={!image}
                          >
                            <span className=" svg-icon svg-icon-2 text-start"></span>
                            Approve
                          </button>
                          <div
                            className={`menu menu-sub menu-sub-dropdown me-20 mb-20 w-400px w-md-500px ${styles.confirm}`}
                            data-kt-menu="true"
                            data-kt-menu-placement="center"
                          >
                            <div className="px-7 py-5">
                              <div className="fs-5 text-dark fw-bolder">
                                Confirmation
                              </div>
                            </div>

                            <div className="separator border-gray-200"></div>

                            <div className="px-7 py-5">
                              <div className="mb-10">
                                <p>Are you sure you want to approved ?</p>
                              </div>

                              <div className="d-flex justify-content-end">
                                <button
                                  type="reset"
                                  className="btn btn-sm btn-light btn-active-light-primary me-2"
                                  data-kt-menu-dismiss="true"
                                >
                                  close
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-sm btn-primary"
                                  data-kt-menu-dismiss="true"
                                  onClick={() => updateApprove()}
                                >
                                  Approve
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="me-0">
                          <button
                            className="mt-10 fs-4 btn btn-danger "
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                            data-kt-menu-flip="top-end"
                            disabled={
                              !comment || comment?.length > 250 || !image
                            }
                          >
                            <span className="svg-icon svg-icon-2 text-start"></span>
                            Reject
                          </button>
                          <div
                            className="menu menu-sub menu-sub-dropdown w-250px w-md-500px"
                            data-kt-menu="true"
                          >
                            <div className="px-7 py-5">
                              <div className="fs-5 text-dark fw-bolder">
                                Confirmation
                              </div>
                            </div>

                            <div className="separator border-gray-200"></div>

                            <div className="px-7 py-5">
                              <div className="mb-10">
                                <p>Are you sure you want to Reject ?</p>
                              </div>

                              <div className="d-flex justify-content-end">
                                <button
                                  type="reset"
                                  className="btn btn-sm btn-light btn-active-light-primary me-2"
                                  data-kt-menu-dismiss="true"
                                >
                                  close
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-sm btn-primary"
                                  data-kt-menu-dismiss="true"
                                  onClick={() => updateReject()}
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : useridDetails?.isIdentityVerify === 1 ? (
                    <div
                      className="d-flex flex-column"
                      data-aos="fade-down-right"
                    >
                      Comment :
                      <div className=" text-muted mb-3">
                        {useridDetails?.comment}
                      </div>
                      Status:
                      <div className=" text-success">Approved</div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column" data-aos="fade-up-left">
                      Comment :
                      <div className=" text-muted mb-3">
                        {useridDetails?.comment}
                      </div>
                      Status:
                      <div className=" text-danger">Rejected</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <>
              <div
                className="modal fade"
                id="exampleModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">...</div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>

        <div className={styles.transaction} data-aos="fade-up-left">
          <div className="bg-white p-10 col-lg-12 shadow p-3 mb-5 bg-body-tertiary rounded">
            <h1 className="mb-20">Transaction List </h1>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="" role="presentation">
                <Link to="">
                  <div
                    className="fs-3 m-3 text-dark"
                    onClick={() => handlefundaccount()}
                  >
                    Fund Account List
                  </div>
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="">
                  <div
                    className="fs-3 m-3 text-dark"
                    onClick={() => handlemoveaccount()}
                  >
                    Move Account List
                  </div>
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="">
                  <div
                    className="fs-3 m-3 text-dark"
                    onClick={() => handlesendaccount()}
                  >
                    Send Account List
                  </div>
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="">
                  <div
                    className="fs-3 m-3 text-dark"
                    onClick={() => handlewithdrawalaccount()}
                  >
                    Withdrawal Account List
                  </div>
                </Link>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                ...
              </div>
            </div>
            <div className="container col-lg-12" data-aos="fade-up-left">
              {transactionHistroy && (
                <div>
                  <div className={styles.table}>
                    <table
                      className=" bg-white table-hover table align-middle table-row-dashed fs-6 gy-7 gx-8 "
                      id="kt_table_users"
                    >
                      <thead data-aos="fade-left thead-dark">
                        <tr className="text-center text-muted fw-bold fs-7 text-uppercase gs-0 ">
                          <th className=" text-dark">Ammount</th>

                          <th className="min-w-300px text-dark ">
                            From Account
                          </th>
                          <th className="min-w-300px text-dark ">To Account</th>
                          <th className=" text-dark ">Type</th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-gray-600 fw-semibold"
                        data-aos="fade-right"
                      >
                        {transactionHistroy.map((item: any, index) => {
                          return (
                            <tr className="hover  text-center">
                              <td>{item.balance}</td>
                              <td className="pt-12">
                                {(item.type === "CR" && (
                                  <span className="">Primary Acccount </span>
                                )) ||
                                  (item.type === "DR" && (
                                    <span className="">Gs Gold Account</span>
                                  )) ||
                                  ""}
                              </td>
                              <td className="pt-12">
                                {(item.type === "CR" && (
                                  <span className="">Gs Gold Account </span>
                                )) ||
                                  (item.type === "DR" && (
                                    <span className="">Primary Acccount</span>
                                  )) ||
                                  ""}
                              </td>
                              {/* <td>{fromAccount?.type}</td>
              <td>{toAccount?.type}</td> */}

                              <td className="pt-12">
                                {(item.type === "CR" && (
                                  <span className="text-success">Credit</span>
                                )) ||
                                  (item.type === "DR" && (
                                    <span className="text-danger">Debit</span>
                                  )) ||
                                  ""}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewDetails;
