import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function LogOut() {
  let navigate = useNavigate();



  useEffect(() => {
    sessionStorage.removeItem('email');
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('user_Details');
        localStorage.clear()
        navigate("/auth/login");

    
  },[])
  return (<></>
 
  )
}
