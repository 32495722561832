import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Services from "../Services";
import Styles from "../scss/Card.module.scss";
import Toster from "../Components/AdminPanelToster";
import Spinner from "../Components/Spinner";

export default function Move() {
  interface ToasterState {
    type: string;
    title: string;
    text: string;
    visiblity: string;
  }

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const [amount, setAmount ] = useState<number>();

  const [primaryAccount, setPrimaryAccount] = useState("");
  const [gsGoldAccount, setGSGoldAccount] = useState("");
  const [fromAccount, setFromAccount] = useState("");
  const [toAccount, setToAccount] = useState("");
  const [storeData, setStoreData] = useState({});
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [fromId, setFromID] = useState("");
  const [toId, setToID] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [amountError, setAmountError] = useState("");
  const [balancePrimary, setBalancePrimary] = useState<number>(0);
  const [balanceGsGold, setBalanceGSGold] = useState<number>(0);

  const navigate = useNavigate();
  const exceptThisSymbols = ["+", "-", " ", "*", "/", "e", "E"];

  const getMoveAccount = async () => {
    try {
      const response: any = await Services.Move.GET_MOVE_ACCOUNT();
      let data = response.data;
      data.gsAccount.type = "gsAccount";
      data.primaryAccount.type = "primaryAccount";
      setPrimaryAccount(data?.primaryAccount?.type);
      setGSGoldAccount(data?.gsAccount?.type);
      setFromAccount(data?.primaryAccount?.type);
      setToAccount(data?.gsAccount?.type);
      setFromID(response?.data?.primaryAccount?.id);
      setToID(response?.data?.gsAccount?.id);
      setBalanceGSGold(response?.data?.gsAccount?.balance);
      setBalancePrimary(response?.data?.primaryAccount?.balance);
    } catch {}
  };

  useEffect(() => {
    getMoveAccount();
  }, []);

  const handleFromAccountChange = (event) => {
    const selectedAccount = event.target.value;

    setFromAccount(selectedAccount);
    if (selectedAccount === gsGoldAccount) {
      setToAccount(primaryAccount);
    } else if (selectedAccount === primaryAccount) {
      setToAccount(gsGoldAccount);
    } else {
      setToAccount(gsGoldAccount);
    }
  };


  const goToNextStep = () => {
    localStorage.setItem("currentStep", (currentStep + 1).toString());
    setCurrentStep(currentStep + 1);
    localStorage.setItem("currentStep", (currentStep + 1).toString());
    setStoreData({
      fromAccount,
      toAccount,
      amount,
    });
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    localStorage.setItem("currentStep", (currentStep - 1).toString());

    setCurrentStep(currentStep - 1);
  };

  const payload = {
    fromAccount: fromAccount === "primaryAccount" ? fromId : toId,
    toAccount: fromAccount === "primaryAccount" ? toId : fromId,
    amount: amount,
    type: fromAccount,
  };

  const handlesubmit = async () => {
    try {
      const response: any = await Services.Move.UPDATE_MOVE_ACCOUNT(payload);
      if (response) {
        setToaster({
          type: "success",
          title: "Successful",
          text: "Exchange  successfully",
          visiblity: "show",
        });

        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 1000);
        setTimeout(() => {
          navigate("/Transactionsuccessfull");
        }, 1500);
      }
    } catch (error) {}
  };

//    const handleamount =() =>{

   
//   if(amount && amount > balancePrimary  ){
//     setAmountError("Insufficent Balance");
//   } else {
//     setAmountError("");
//   }
// }
// useEffect(()=>{
//   setAmountError("")
//   if(amount && amount > balancePrimary || amount && amount > balanceGsGold ){
//     setAmountError("Insufficent Balance");
//   } else {
//     setAmountError("");
//   }
  
 
// },[amount])



  return (
    <div>
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      {!isLoaded && <Spinner />}
      {currentStep === 1 && (
        <div
          className=""
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <h2 className="text-center">Exchange</h2>
          <div className="container">
            <div className="bg-white container mt-8 p-6 shadow p-3 mb-5 bg-body-tertiary rounded">
              <div className="d-flex align-content-start p-5">
                <div className="col-lg-12">
                  {" "}
                  <h3>From Account</h3>
                  <div className="mb-3">
                    <select
                      id="disabledSelect"
                      className="form-select p-3 mb-2 text-gray fw-semibold"
                      value={fromAccount}
                      onChange={handleFromAccountChange}
                    >
                      <option value={primaryAccount}>Primary Account</option>
                      <option value={gsGoldAccount}>Gs Gold Account</option>
                    </select>
                    Balance is {fromAccount == primaryAccount && balancePrimary}
                    {fromAccount == gsGoldAccount && balanceGsGold}

                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white container mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded">
              <div className="d-flex align-content-start p-5">
                <div className="col-lg-12">
                  {" "}
                  <h3>To Account</h3>
                  <div className="mb-3">
                    <div className="d-flex align-content-start p-2">
                      <select
                        id="disabledSelect"
                        disabled={true}
                        className="form-select p-3 mb-2 text-gray fw-semibold"
                        value={toAccount}
                      >
                        <option value={primaryAccount}>Primary Account</option>
                        <option value={gsGoldAccount}>Gs Gold Account</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" p-6 bg-white container  mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded">
              <div className="col-lg-12 d-flex justify-content-between ">
                <div className="col-lg-10 ms-1">
                  {" "}
                  <h3>Amount</h3>
                </div>
                <span className="fw-semibold">
                  <div className="fv-row">
                    <label className="form-label mb-3 p-3">Enter Amount</label>

                    <input
                      type="number"
                      min="0"
                      className={`mb-3 form-control form-control-lg form-control-solid ${Styles.enterammount}`}
                      name="account_name"
                      placeholder="Amount"
                      value={amount}
                      // onKeyDown={handleamount}
                      onChange={(e) => {
                        if (amount && amount > balancePrimary) {
                        
                          setAmountError("chl");
                        }  
                        
                        else {
                          setAmountError("");
                          setAmount(+e.target.value);
                        }


                        if (e.target.value.length > 7) {
                          setAmountError("Value Must Be 7 Digits ");
                        } 
                        
                        else {
                          setAmountError("");
                          setAmount(+e.target.value);
                          // setAmount(+e.target.value);
                        } 
                     
                        
                    
                      }}
                      
                       
                    />
                 
                    {amountError && (
                      <>
                       
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                          }}
                        >
                          {amountError}
                        </span>
                      </>
                    )}
                  </div>
                </span>
              </div>
            </div>
            {fromAccount === primaryAccount && <div className=" fv-row ">
              <label className="d-flex align-items-center form-label mb-3">
                Choose Amount
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="tooltip"
                  title="Provide your team size to help us setup your billing"
                ></i>
              </label>
              <div className="row mb-2" data-kt-buttons="true">
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <button
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e:any) => setAmount(+e.target.value)}
                      value="100"
                    />
                    <span className="fw-bold fs-3">100</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <button
                     
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e:any) => setAmount(+e.target.value)}
                      value="500"
                    />
                    <span className="fw-bold fs-3">500</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <button
                      className="btn-check"
                      onClick={(e:any) => setAmount(+e.target.value)}
                      name="account_team_size"
                      value="1000"
                    />
                    <span className="fw-bold fs-3">1000</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <button
                      className="btn-check"
                      onClick={(e:any) => setAmount(+e.target.value)}
                      name="account_team_size"
                      value="3000"
                    />
                    <span className="fw-bold fs-3">3000</span>
                  </label>
                </div>
              </div>
            </div>}

            <div className="">
              
              <button
                className="btn btn-warning col-lg-12 mt-10  "
                disabled={

                  !amount ||
                  !(amount > 0 )||
                  (fromAccount === primaryAccount && balancePrimary < amount) 
                  ||
                  (fromAccount === gsGoldAccount && balanceGsGold < amount)
            
                }
              
                onClick={() => {
                  goToNextStep();
                }}
              >
                
                Continue
              </button>
              
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div
          className=""
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="d-flex">
            <div></div>
            <h2 className={Styles.head}>Confirm</h2>
          </div>

          <div className={`mt-10 bg-white shadow-sm p-10 mb-5 bg-body-tertiary rounded ${Styles.shadowconfirmmove}`}>
            <p className="text-center mt-10 fs-3 ">
              Please review the details and confirm your exchange
            </p>
            <div className="container col-lg-12">
              <div className="d-flex justify-content-between align-items-start mt-20">
                <div className="">
                  {" "}
                  <span>From</span>
                </div>

                <div className="">
                  {" "}
                  <span className="fw-bolder"> {fromAccount}</span>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-start mt-10">
                <div className="">
                  {" "}
                  <span>To</span>
                </div>

                <div className="">
                  {" "}
                  <span className="fw-bolder">{toAccount}</span> <br />
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-start mt-10">
                <div className="">
                  {" "}
                  <span>Amount</span>
                </div>

                <div className="">
                  {" "}
                  <span className="fw-bolder">{amount}</span> <br />
                </div>
              </div>

              <div className="mt-10  d-flex justify-content-between">
                {" "}
                <button
                  className="btn btn-warning "
                  onClick={() => goToPreviousStep()}
                >
                  Cancel Exchange
                </button>{" "}
                <button
                  className="btn btn-warning "
                  onClick={() => {
                    handlesubmit();
                  }}
                >
                  Finish Exchange
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
