import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from "../../../scss/Card.module.scss"

function Createaccount() {
 
  const [GStag, setGStag] = useState('');
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  
  

  const handleGStagChange = (event) => {
    setGStag(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  
  return (
    <div className='' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
      
      <div className='d-flex'>
      <div>
    <Link to='/'>
      <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div>
        <h2 className={styles.head}>GS App</h2>
    </div>
    <div className='bg-white mt-8 p-2 shadow-sm p-10 mb-5  rounded'>
        <div className='mt-5'>
        <h6 className='text-start ms-6'>Let's create your account</h6>
        <div className='mt-8 '>
        <input
        className="form-control m-5 mt-2" 
          type="text"
          placeholder='GS tag'
          value={GStag}
          onChange={handleGStagChange}
        />
        </div>
      </div>
     <div className='mt-10'>
        <input
        className="form-control m-5 mt-2" 
          type="text"
          placeholder='Email'
          value={Email}
          onChange={handleEmailChange}
        />
    </div>

    <div className='mt-10'>
        <input
        className="form-control m-5 mt-2" 
          type="password"
          placeholder='password'
          value={Password}
          onChange={handlePasswordChange}
        />
    </div>
    <div className='mt-8 ms-6 mb-20'>
    <button type="button" className={styles.button}></button>
        <span className='fs-4 ms-3'> I agree with the Terms of use and Privacy Policy</span>
    </div>
     
      <Link to="/info">
      <button className='btn btn-warning m-4 col-lg-12' type="submit">Continue</button>
      </Link>
    </div>
    </div>
  )
}

export default Createaccount;



