import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from "react-router-dom";
import Services from '../../Services';
import Toster from '../../Components/Toster';
import Spinner from '../../Components/Spinner';
import { useSearchParams } from 'react-router-dom';

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

export default function FundAccount() {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accountType = searchParams.get('type')
  const [storeData, setStoreData] = useState({});
  const [currentStep, setCurrentStep] = useState<number>(1);

  const [fromAccount, setFromAccount] = useState('');
  const [ammountError, setAmmountError] = useState("");
  const [toAccount, setToAccount] = useState('');
  const [message, setMessage] = useState('');
  const [toAccountType, setToAccountType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [selectedCard, setSelectedCard] = useState('');
  const [cardData, setCardData] = useState<any>([])

  const [gsAccountData, setGsAccountData] = useState<any>({})
  const [primaryAccountId, setPrimaryAccountId] = useState<any>({})
  const [primaryAccountData, setPrimaryAccountData] = useState<any>({})
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const exceptThisSymbols = ["+", "-", " ", "*","/", "e", "E"];
  const [isLoaded, setIsLoaded] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false); ///set--true

  const GET_CARD = async () => {
    try {
      const response: any = await Services.Card.GET_CARD();
      setCardData(response.data.data);
    } catch (error) { }
  };


  const GET_GS_ACCOUNT = async () => {
    try {
      const response: any = await Services.GsAccount.GET_GS_ACCOUNT();
      const data = response.data
      data.gsAccount.type = "gsAccount";
      data.primaryAccount.type = "primaryAccount";
      setPrimaryAccountData(data.primaryAccount.type);
      setGsAccountData(data.gsAccount.type);
      setAccountNumber(data.gsAccount.accountNumber)
      setPrimaryAccountId(data.primaryAccount.id)



    } catch (error) { }
  };

  useEffect(() => {
    if (accountType == 'gs') {
      setToAccount(gsAccountData);
      setToAccountType('GS Gold Account')
    } else {
      setToAccount(primaryAccountData);
      setToAccountType('GS Primary Account')

    }

  }, [accountType, primaryAccountData, gsAccountData]);
  useEffect(() => {
    if (toAccount == 'gsAccount') {

      setToAccountType('GS Gold Account')
    } else {

      setToAccountType('GS Primary Account')

    }

  }, [toAccount]);
  useEffect(() => {
    if (fromAccount) {
      cardData.map((item) => {
        if (item.id == fromAccount) {
          setSelectedCard(item.cardName + ' ' + item.cardNumber)
        }
      })
    }

  }, [fromAccount]);

  useEffect(() => {
    GET_CARD()
    GET_GS_ACCOUNT()
    // const savedStep = localStorage.getItem("currentStep");

    // // Check if there's a saved step, and update the current step state accordingly
    // if (savedStep) {
    //   setCurrentStep(parseInt(savedStep, 10));
    // }

  }, []);


  const handleAddFund = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();


    try {
      let data = {
        // cardId: fromAccount,
        // type: toAccount.trim(),
        balance: amount.val,
        // accountNumber:accountNumber,
        primaryAccountId
      };
      const response: any = await Services.GsAccount.ADD_FUND(data)
      localStorage.removeItem("currentStep")

      localStorage.setItem("card_id", response.id)

      setTimeout(() => {
        navigate("/accountfundding");
      }, 700);
      setToaster({
        type: "success",
        title: "Successfull",
        text: "Add balance successfully",
        visiblity: "show",
      });

    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }

  };


  const [amount, setAmount] = useState<any>({
    val: null,
    isValid: true,
    err: "required",
    validate: false,
  });
  function amountHandle(val: any) {
    if (!val.trim()) {
      setAmount({
        isValid: false,
        err: "Requeird",
        val: null,
        validate: true,
      });
    } else if (val.trim().length > 7) {
      setIsDisabled(true)
      setAmount({
        isValid: false,
        err: "Value Must Be 7 Digits",
        val: val.substr(0, 7),
        validate: true,
      });
    } else if (val==0) {
      setAmount({
        isValid: false,
        err: "Requeird",
        val: "",
        validate: true,
      });
    }
    else if (parseInt(val) <= 0) {
      setIsDisabled(true)
      setAmount({
        isValid: false,
        err: "Amount should be greater than 0",
        val: val,
        validate: true,
      });
    } else {
      setAmount({
        isValid: true,
        err: "",
        val: val,
        validate: true,
      });
    }
  }

  const goToNextStep = () => {
    // alert("jjjjjjjjj")
    let isValid = true;

    setStoreData({
      amount,
      fromAccount,
      toAccount

    });
    localStorage.setItem("currentStep", (currentStep + 1).toString());

    // Move to the next step
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    // alert("jjjjjjjjj")
    setStoreData({
      amount,
      fromAccount,
      toAccount

    });

    localStorage.setItem("currentStep", (currentStep - 1).toString());

    // Move to the previous step
    setCurrentStep(currentStep - 1);
  };
  return (
    <div className=" bg-white  col-12  " data-aos="fade-left">
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      {!isLoaded && <Spinner />}



      {currentStep === 1 && (
        <div className='' data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500" >
          <h2 className='text-center'>Fund Account</h2>
          <div className='container'>

            <div>
              <div className=' bg-white container mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded' >
                <div className='d-flex align-content-start p-5 ' >
                  <div className='col-lg-12 bg-white'> <h3>From  Account</h3>
                    <div className="mb-3">
                      <select id="disabledSelect" className="form-select p-3 mb-2 text-gray fw-semibold "
                        name="fromAccount"
                        value={fromAccount}
                        onChange={(e) => setFromAccount(e.target.value)}
                      >
                        <option className='fw-semibold ' value={''}>Select From Account</option>
                        {cardData.map((item: any, key) => {
                          return (
                            <option key={key} value={item.id} >
                              {item.cardName && item.cardNumber ? item.cardName + ' ' + item.cardNumber : '-'}
                            </option>
                          )
                        })}

                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=' bg-white container  mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded' >
              <div className='d-flex align-content-start p-5 '>
                <div className='col-lg-12'> <h3>To Account</h3>
                  <div className="mb-3">
                    <select id="disabledSelect" className="form-select p-3 mb-2 text-gray fw-semibold"
                      name="toAccount"
                      value={toAccount}
                      onChange={(e) => { setToAccount(e.target.value) }}
                    > <option className='fw-semibold ' value={primaryAccountData}>Primary Account</option>
                      {/* <option className='fw-semibold '>Select To Account</option>
                     
                      <option className='fw-semibold ' value={gsAccountData}>GS Gold Account</option> */}

                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className=' bg-white container  mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded ' >

              <div className='col-lg-12 d-flex justify-content-between h-100px'>
                <div className='col-lg-10 ms-1'> <h3>Amount</h3></div>
                <span className='fw-semibold'>
                  <div className="fv-row w-100">
                    <label className="form-label mb-3">
                      Enter Amount
                    </label>

                    <input
                      type="number"
                      min="0"
                      className="form-control form-control-lg form-control-solid"
                      name="account_name"
                      placeholder="Amount"
                      value={amount.val}
                      //   onInput={}
                      onChange={(e) =>
                        amountHandle(e.target.value)
                      }
                      onKeyDown={(e:any) => {
                        exceptThisSymbols.includes(e.key) &&
                          e.preventDefault();
                        // if (
                        //   e.target.value.length >=13 &&
                        //   e.key !== "Backspace" &&
                        //   e.key !== "Delete"
                        // ) {
                        //   e.preventDefault();
                        
                        // }
                      }}
                    />
                      {!amount.isValid && (
                                    <div 
                                      className=" mt-2"
                                      style={{ color: "red" }}
                                    >
                                      <small>{amount?.err}</small>
                                    </div>
                                  )}
                  </div>
                 


                 
                </span>
               


              </div>
            


            </div>
            <div className=" fv-row ">
              <label className="d-flex align-items-center form-label mb-3">
                Choose Amount
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="tooltip"
                  title="Provide your team size to help us setup your billing"
                ></i>
              </label>

              <div
                className="row mb-2"
                data-kt-buttons="true"
              >
               <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="100"
                    />
                    <span className="fw-bold fs-3">100</span>
                  </label>
                </div>

                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="500"
                    />
                    <span className="fw-bold fs-3">500</span>
                  </label>
                </div>

                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="1000"
                    />
                    <span className="fw-bold fs-3">1000</span>
                  </label>
                </div>

                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="3000"
                    />
                    <span className="fw-bold fs-3">3000</span>
                  </label>
                </div>
              </div>
            </div>

            <div className=''>
              {/* <Link to='/confirmdetails'> */}
              <button className='btn btn-warning col-lg-12 mt-6 ' disabled={!(amount.val&&fromAccount&&toAccount)} onClick={goToNextStep} >Continue</button>
              {/* </Link> */}
              <p>{message}</p>
            </div>
          </div>

        </div>
      )}

      {currentStep === 2 && (
        <div className='bg-white' data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500">
        
           
           <h2 className='text-center'>Confirm</h2>
      
          

          <div className='bg-white mt-10  '>
          
            <div className='container col-lg-12 shadow-sm p-10 mb-5 bg-body-tertiary rounded'>
            <p className='text-center mt-10 fs-3'>Please review the details and confirm your exchange</p>
              <div className='d-flex justify-content-between align-items-start mt-20'>
                <div className=''> <span>To</span></div>


                <div className=''> 
                  <div className=''> <span className='fw-bolder'>{toAccountType}</span> <br />
                  <span> {accountNumber}</span>
                </div>
                {/* <span className='fw-bolder'>Debit Card</span> <br />

                  <span> {selectedCard}</span> */}
                </div>
              </div>

              <div className='d-flex justify-content-between align-items-start mt-10'>
                <div className=''> <span>From</span></div>


                <div className=''> 
                <span className='fw-bolder'>Debit Card</span> <br />

                  <span> {selectedCard}</span>
                {/* <span className='fw-bolder'>{toAccountType}</span> <br />
                  <span> {accountNumber}</span> */}
                </div>
              </div>

              <div className='d-flex justify-content-between align-items-start mt-10'>
                <div className=''> <span>Ammount</span></div>


                <div className=''> 
                {/* <span className='fw-bolder' >00.00</span> <br /> */}
                  <span >{amount.val}</span>
                </div>
              </div>



              <div className='  mt-20 d-flex justify-content-between'>
              <button className='btn btn-warning col-4 mb-10' onClick={goToPreviousStep}>Cancel </button>
                <button className='btn btn-warning col-4 mb-10' onClick={handleAddFund}>Finish Funding</button></div>

            </div>
          </div>
        </div>
      )}
    </div>
  )
}
