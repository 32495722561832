import React from 'react'
import { Link } from 'react-router-dom';
import styles from "../../../scss/Card.module.scss"

export default function AccountSetting() {
  return (
    <div className='' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
      <div className='d-flex'>
      <div>
    <Link to='/setting'><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div>
        <h2 className={styles.head}>Account Setting</h2>
    </div>
       <div className='bg-white d-flex justify-content-start ms-4 mt-10 shadow p-3 mb-5 bg-body-tertiary rounded'>
        <div className=' mt-8 col-lg-12  p-5' >
          <div className='conatiner d-flex justify-content-between '>
            <div ><h5>Display & Language</h5>
            </div>
            <div className=''>
              <Link to='/languagedisplaysetting'>  <i className="text-warning fs-2 bi bi-chevron-right"></i></Link>
             
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-10'>
            <div >
                <h5>Monthly Statement</h5>
            </div>
            <div className=''>
            <Link to='/statments' >
            <i className="text-warning fs-2 bi bi-chevron-right"></i>

            </Link>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-10'>
            <div ><h5>2 Step Verification</h5>
              </div>
              <div className="form-check form-switch">
  <input className="border-warning form-check-input ms-3" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
  {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
</div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-10'>
            <div ><h5>Require pin for Transactions</h5>
             </div>
             <div className="form-check form-switch ms-5">
  <input className="border-warning form-check-input ms-3" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
  {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
</div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-10'>
            <div ><h5>App Version</h5>
             </div>
            <div className='mt-1'>
                <p className='fw-bold '>1.00</p>
              {/* <img src='https://img.icons8.com/?size=1x&id=86520&format=png' height={30} width={30} alt='' /> */}
            </div>
          </div>

          <div className='conatiner d-flex justify-content-between mt-5'>
            <p  className='fw-semibold text-danger'>Delete Account</p>
             
          </div>
        </div>
      </div>
    </div>
  )
}
