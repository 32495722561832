// import { useNavigate } from "react-router-dom";
import Services from "../../Services";
import Toster from "../../Components/AdminPanelToster";
import Spinner from "../../Components/Spinner";
import React, { useEffect, useState } from "react";
import Styles from "../../scss/Card.module.scss";

function SupportDetails() {
  interface ToasterState {
    type: string;
    title: string;
    text: string;
    visiblity: string;
  }

  const [showDropdown, setShowDropdown] = useState(true);
  const [support, setSupport] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [open, setopen] = useState<String | undefined>("Open");
  const [close, setclose] = useState<String | undefined>("Close");
  const [inProgress, setInprogress] = useState<String | undefined>(
    "InProgress"
  );
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const toggleDropdown = () => {
    console.log("showDropdown")
    setShowDropdown(!showDropdown); 
  };

  const getSupport = async () => {
    setIsLoaded(true);
    try {
      const response: any = await Services.Support.GET_SUPPORT();
      setSupport(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSupport();
  }, []);

  const handleAction = async (id, selectedValue) => {
    setopen("Open");
    setclose("Close");
    setInprogress("InProgress");
    const data = { supportStatus: selectedValue };
    try {
      const response: any = await Services.Support.UPDATE_SUPPORT_BY_ID(
        id,
        data
      );
      if (response) {
        setToaster({
          type: "success",
          title: "Successful",
          text: "Status Updated successfully",
          visiblity: "show",
        });

        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 2000);
        setTimeout(() => {
          
          setShowDropdown(false);
          // getSupport();
          window.location.reload()
        }, 100);
      
      }
      setShowDropdown(true)
    } 
    
    catch (error) {}
  };
 
console.log("showwwww",showDropdown)
  return (
    <div>
      <div className="">
        <Toster
          type={toaster.type}
          title={toaster.title}
          text={toaster.text}
          visiblity={toaster.visiblity}
        />

        {!isLoaded && <Spinner />}
        <div className={Styles.support}>
          <div className="container col-lg-12  " data-aos="fade-up-left">
            <h1 className="text-center">Support</h1>
            <div className="mt-20 p-10 w-100">
              <table
                // className="bg-white table table-striped table-hover  table align-middle fs-6 gy-7 gx-8  shadow p-10 bg-body-tertiary rounded"
                // id="kt_table_users"
                className={`bg-white table table-striped table-hover table align-middle fs-6 gy-7 gx-8 shadow  bg-body-tertiary rounded ${Styles.list}`}
                id="kt_table_users"
                style={{ width: "60%", height: "300px" , }}
              >
                <thead className="" data-aos="fade-left thead-dark">
                  <tr className="text-center text-muted fw-bold fs-7 text-uppercase gs-0 w-22">
                    <th className="min-w-250px text-dark">User Name</th>
                    <th className="min-w-250px text-dark">Tittle</th>
                    <th className="min-w-250px text-dark ">Subject</th>
                    <th className="min-w-250px text-dark ">Status</th>
                    <th className="min-w-250px text-dark">Action</th>
                  </tr>
                </thead>

                <tbody className="text-gray-600" data-aos="fade-right">
                  {support.map((item: any) => {
                    return (
                      <>
                        <tr className="hover text-emphasis-dark text-muted fw-bold fs-5   text-center text-truncate ">
                          <td
                            className="pt-10"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item?.User?.firstName.substring(0, 10) + ".."}
                          </td>
                          <td className="pt-10 overflow-hidden text-nowrap  ">
                            {item.tittle.substring(0, 10) + ".."}
                          </td>
                          <td
                            className="pt-10 overflow-hidden text-nowrap"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.subject.substring(0, 20) + ".."}
                          </td>
                          {/* <td className="pt-10">{item?.supportStatus}</td> */}

                          <td className="pt-10">
                            {item.supportStatus === open && (
                              <span className="text-success">Open</span>
                            )}
                            {item.supportStatus === inProgress && (
                              <span className="text-primary">In Progress</span>
                            )}
                            {item.supportStatus === close && (
                              <span className="text-danger">Close</span>
                            )}
                          </td>
                          <td className="">
                            <div className="me-0">
                              <span
                                className="cursor-pointer"
                                data-kt-menu-trigger="click"
                                data-kt-menu-placement="bottom-end"
                                data-kt-menu-flip="top-end"
                                // onClick={toggleDropdown }
                                onChange={toggleDropdown}
                              >
                                <i className="pt-3 position-fixed text-warning fs-3 text-center bi bi-pencil-square"></i>
                              </span>
                       
                              {showDropdown? (
                                <div
                                  className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                                  data-kt-menu="true"
                                >
                                  <div className="px-7 py-5"></div>

                                  <div className="separator border-gray-200"></div>

                                  <div className="px-7 py-5">
                                    <div className="mb-10">
                                      <label className="form-label fw-bold">
                                        Status:
                                      </label>

                                      <div>
                                        <select
                                          className="form-select form-select-solid"
                                          // data-kt-select2="true"
                                          data-placeholder="Select option"
                                          // data-allow-clear="true"
                                          onChange={(e) => {
                                            const selectedValue =
                                              e.target.value;
                                              handleAction(
                                              item.id,
                                              selectedValue
                                            );
                                          }}
                                          defaultValue={item.supportStatus}
                                        >
                                          {/* <option value={1}>{item.supportStatus}</option> */}
                                          <option
                                            value={open ? open.toString() : ""}
                                          >
                                            Open
                                          </option>
                                          <option
                                            value={
                                              close ? close.toString() : ""
                                            }
                                          >
                                            Close
                                          </option>

                                          <option
                                            value={
                                              inProgress
                                                ? inProgress.toString()
                                                : ""
                                            }
                                          >
                                            In Progress
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportDetails;
