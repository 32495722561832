import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../_metronic/helpers';
import Services from '../Services';



export default function Wallet() {
  const { id } = useParams();

  const [isDisabled, setIsDisabled] = useState(true);
  const [GSGoldDetails, setGSGoldDetails] = useState({
    accountNumber: '',
    balance: '',
  });
  const [gsAccountData, setGsAccountData] = useState<any>({})
  const [primaryAccountData, setPrimaryAccountData] = useState<any>({})
  const [isverify, setIsVerify] = useState<any>()
  const [isIdentityverify, setIsIdentityverify] = useState<any>()

  const GET_USER = async () => {
    try {

      const response: any = await Services.User.GET_USER();
      if(response){
      
        if( response?.data?.data?.isIdentityVerify===1){
          setIsIdentityverify(response?.data?.data?.isIdentityVerify)
          setIsDisabled(false)
        }else{
          setIsDisabled(true)
          setIsIdentityverify(response.data.data.isIdentityVerify)
        }
      } 
      setIsVerify(response.data.data.image)
      setIsIdentityverify(response.data.data.isIdentityVerify)
     localStorage.setItem("Gsbalance",response.data.data.GsAccount.balance)

    } catch (error) { }
  };

  const GET_GS_ACCOUNT = async () => {
    try {
      const response: any = await Services.GsAccount.GET_GS_ACCOUNT();
      const data = response.data
      data.gsAccount.type = "gsAccount";
      data.primaryAccount.type = "primaryAccount";
      setPrimaryAccountData(data.primaryAccount);
      setGsAccountData(data.gsAccount);





    } catch (error) { }
  };

  useEffect(() => {
    GET_GS_ACCOUNT()
    GET_USER()


  }, []);
  return (
    <div>
      <div className=''>
        <div className='container mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded ' data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500" >
          <div className='d-flex justify-content-between align-content-start p-5 ' >
            <div className='' >
              <h2>Gs Gold Account</h2>
              <span className='fw-semibold'> {gsAccountData.accountNumber}</span><br />

            </div>

            <div className=''>

              <span className='fw-semibold'> {gsAccountData.balance} </span>

            </div>
          </div>
          <div className=''>


          </div>

        </div>
        <div className='container mt-8 shadow p-3 mb-5 bg-body-tertiary rounded' data-aos="fade-right"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500">
          <div className='d-flex  justify-content-between align-content-start  p-5'>
            <div className=''> <h2>Primary Account</h2>
              <span className='fw-semibold'>{primaryAccountData.accountNumber}</span></div>

            <div className=''>

              <span className='fw-semibold'> {primaryAccountData.balance}</span>
            </div>
          </div>
          <div className=''>

            <Link to='/fundaccount?type=primary' className=''>
              <button className='btn btn-warning col-lg-4 mt-6' disabled={isDisabled}>Fund Account</button>
            </Link>
          </div>
          {isIdentityverify === 0 && (<div className='bg-warning-subtle text-danger container col-lg-4 p-3 ms-3 mt-7 rounded'>
            <span className='m-5 '>Your account is not verified please check identity verify</span></div>)}

          {isIdentityverify === 2 && (<div className='bg-warning-subtle text-danger container col-lg-4 p-3 ms-3 mt-7 rounded'>
            <span className='m-5 '>Your account is verification in Progress</span></div>)}
          {isIdentityverify === 3 && (<div className='bg-warning-subtle text-danger container col-lg-4 p-3 ms-3 mt-7 rounded'>
            <span className='m-5 '>Your account is verification in Rejected</span></div>)}
        </div>



      </div>

      <div className='d-flex justify-content-center align-item-center ' data-aos="fade-left"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        data-aos-duration="500">
        <p className='text-center fw-bold mt-8 fs-3 mt-5'>Explore</p>

      </div>

      <div className='d-flex justify-content-between align-item-center  col-12 rounded p-5 float-end bg-warning  text-dark bg-opacity-25 ' data-aos="fade-right"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        data-aos-duration="500">
        <div > <h5 className='fw-bold '>Invite Your Friends</h5>
          <p>Invite Your friends with The Spark Code</p>
        </div>

        <div>
          <img src={toAbsoluteUrl('/media/logos/inviteicon.png')} alt='' height={40} width={40} />


        </div>

      </div>

    </div>
  )
}
