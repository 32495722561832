import Axios from "axios";
import constant from "../Constant";
import api from "../api"

// eslint-disable-next-line import/no-anonymous-default-export
export default {

    GET_MOVE_ACCOUNT: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
              constant.BASE_URL + api.Move.GET_MOVE_ACCOUNT()
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
      }, 


      UPDATE_MOVE_ACCOUNT: (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Axios.put(
            constant.BASE_URL + api.Move.UPDATE_MOVE_ACCOUNT(),
            data
          );
          resolve(response);
        } catch (err) {
          reject(err);
        }
      }); 
    },
}