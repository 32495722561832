/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
// import {KTIcon} from '../../../helpers'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
// import { Link } from 'react-router-dom'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
<AsideMenuItem  to='/profile' title='Profile'  />

<AsideMenuItem to='/mycard' title='My Card'  />
<AsideMenuItem to='/transact' title='Transact'  />
<AsideMenuItem to='/makeawithdraw' title='Make a withdraw' />
<AsideMenuItem to='/recenttransaction' title='Recent Transactions'/>
<AsideMenuItem to='/support' title='Support'/>
<AsideMenuItem to='/setting' title='Setting'/>
<AsideMenuItem to='/logoutgs' title='Logout'/>

    </>
  )
}
