/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import Constant from "../Constant";
import api from "../api";
export default {
  GET_TRANSACTION: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTIONS()
        );
        resolve(response);
      } catch (err) {
        reject(err);
       
      }
    });
  },
  GET_USER_TRANSACTIONS: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_USER_TRANSACTIONS()
        );
        resolve(response);
      } catch (err) {
        reject(err);
     
      }
    });
  },
  GET_TRANSACTION_HISTORY: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTION_HISTORY(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_USER_WITHDRAW_TRANSACTIONS: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_USER_WITHDRAW_TRANSACTIONS()
        );
        resolve(response);
      
      } catch (err) {
        reject(err);
      
      }
    }); 
  },
  GET_USER_WITHDRAW_TRANSACTIONS_BY_ID: (id,data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          Constant.BASE_URL + api.Transaction.GET_USER_WITHDRAW_TRANSACTIONS_BY_ID(id),data
        );
        resolve(response);
      
      } catch (err) {
        reject(err);
     
      }
    }); 
  },

  GET_TRANSACTION_BY_ID: (id, query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTIONS_BY_ID(id, query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
};
