import { Link } from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import Services from '../../../../Services'
import { useEffect, useState } from 'react'
import Constant from '../../../../Constant'

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const [profile,setProfile]=useState('')
  const [firstName,setFirstName]=useState('')
  const [lastName,setLastName]=useState('')

  const GET_USER = async () => {
    try {
      const response: any = await Services.User.GET_USER();
      
      setFirstName(response.data.data.firstName)
      setLastName(response.data.data.lastName)
    
      setProfile(response.data.data.profile)
   
    } catch (error) {}
  };

  useEffect(() => {
    GET_USER();
  }, []);

  const {currentUser, logout} = useAuth()

  return (
    <>
      {/*begin::User*/}
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
        {/*begin::Symbol*/}
        <div className='symbol symbol-50px' >

        {profile ? (
              <img 
              crossOrigin="anonymous"
            src={`${Constant.BASE_URL_UPLOADS}${profile}`}
            // style={{backgroundImage:`url(${toAbsoluteUrl('/media/logos/300-2.jpg')})`,backgroundSize: 'cover',backgroundRepeat: 'no-repeat',maxWidth: '100%'}}
               alt='Gold Send' />
               ) : (   
                //  <div className="position-relative">
               <img
                 src={toAbsoluteUrl('/media/logos/userimage.jpeg')} // Replace with the URL of your default profile image
                 alt="Default Profile Image"
                //  height={110}
                //  width={110}
                //  style={{ borderRadius: "50%" }}
               />
         
        
            //  </div>
          )} 
          {/* <img  crossOrigin="anonymous"
            src={`${Constant.BASE_URL_UPLOADS}${profile}`} alt="" style={{backgroundImage:`url(${toAbsoluteUrl('/media/logos/userimage.jpeg')})`,backgroundSize: 'cover',backgroundRepeat: 'no-repeat',maxWidth: '100%'}} /> */}
          {/* <img src={toAbsoluteUrl('/media/logos/300-2.jpg')} alt='' /> */}
        </div>
        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
          {/*begin::Section*/}
          <div className='d-flex'>
            {/*begin::Info*/}
            <div className='flex-grow-1 me-2 mt-3'>
              {/*begin::Username*/}

              <Link to='#' className='text-white text-hover-primary fs-6 fw-bold ' >
                      <p>Hey' {firstName.slice(0,10)} &nbsp; {lastName.slice(0,10)}.. wellcome</p>
                      {/* {item.userNotes.slice(0, 15)}.. */}
              </Link>
              {/* <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>wellcome</span> */}
              {/*end::Username*/}

              {/*begin::Description*/}
              {/* <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>wellcome</span> */}
              {/*end::Description*/}

              {/*begin::Label*/}
              {/* <div className='d-flex align-items-center text-success fs-9'>
                <span className='bullet bullet-dot bg-success me-1'></span>online
              </div> */}
              {/*end::Label*/}
            </div>
            {/*end::Info*/}

            {/*begin::User menu*/}
            {/* <div className='me-n2'>
             
              <Link
                to='#'
                className='btn btn-icon btn-sm btn-active-color-primary mt-n2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-overflow='false'
              >
                <KTIcon iconName='setting-2' className='text-muted fs-1' />
              </Link>

              <HeaderUserMenu />
            
            </div> */}
            {/*end::User menu*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}

      {/*begin::Aside search*/}
      {/* <div className='aside-search py-5'>
        <?php Theme::getView('partials/search/_inline', array(
        'class' => 'w-100',
        'menu-placement' => 'bottom-start',
        'responsive' => 'false'
    ))?>
        <Search />
      </div> */}
    
    </>
  )
}

export {AsideToolbar}
