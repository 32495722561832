import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import styles from "../../../scss/Card.module.scss"
// import {defaultAccount, IAccount} from './AccountModel'
import { defaultAccount,IAccount } from '../../../app/modules/profile/components/account/AccountModel';
import { Languages } from '../../../_metronic/partials/layout/header-menus/Languages';

export default function LanguageDisplaySetting() {
  const [data, setData] = useState<IAccount>(defaultAccount)
  //const [hasError, setHasError] = useState(false);

  const updateData = (fieldsToUpdate: Partial<IAccount>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }
  return (
    <div data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">  
    <div className='d-flex'>
      <div>
    <Link to='/accountsetting'><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div>
        <h2 className={styles.head}>Language & Display</h2>
       
    </div>

       <div className='d-flex justify-content-start p-10'>
       
        <div className=' mt-8 col-lg-12 p-5 bg-white shadow-sm p-4 mb-5 bg-body-tertiary rounded ' >
          <div className='conatiner d-flex justify-content-between mt-5'>
            <div ><h5>Display & Language</h5>
            </div>
            <div> <Languages /></div>
           
            {/* <div className=''>
              
              <Link to=''>   <i className="text-warning fs-2 bi bi-check2-circle"></i></Link>
              <Languages />
             
            </div> */}
          </div>
          <div className='conatiner d-flex justify-content-between mt-10'>
            <div ><h5>Monthly Statement</h5>
            </div>
            <div className=''>
            <i className="text-warning fs-2 bi bi-check2-circle"></i>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
