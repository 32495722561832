import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Services from "../../Services";
import Toster from "../../Components/AdminPanelToster";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const initialState = { acc: "", description: "" };

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

export default function WIthdrawalPopUp() {
  const [data, setData] = useState(initialState);
  const [description, setdescription] = useState('');

  const [PrimaryAc, setPrimaryAc] = useState<any>([]);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [balance, setBalance] = useState("");
  const [descriptionError, setDescriptionError] = useState('')



  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const getPrimaryData = async () => {
    try {
      const response: any = await Services.WithdrawalAc.GET_PRIMARY_AC();

      setBalance(response?.data?.data.balance)
      setPrimaryAc(response?.data?.data.accountNumber);
    } catch (error) { }
  };
  const getGSAccount = async () => {
    try {
      const response: any = await Services.GsAccount.GET_GS_ACCOUNT();
      const balance = response.data.primaryAccount.balance;
      setBalance(balance);
    } catch (error) { }
  };

  useEffect(() => {
    getPrimaryData();
    getGSAccount();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value.trimStart();
    const name = event.target.name.trim();
    setData({ ...data, [name]: value });
  };
  const dataSubmit = async () => {
    const data2 = {
      balance: amount.val,
      userNotes: description,
    };
    if (PrimaryAc && amount.val && description) {
      try {
        const response = await Services.WithdrawalAc.POST_WITHDRAWAL_DATA(
          data2
        );

        localStorage.removeItem("currentStep");
        setData(initialState);
        setTimeout(() => {
          navigate("/withdrawsuccessfull");
        }, 2000);
        setToaster({
          type: "success",
          title: "Successful",
          text: "Withdrawal request generated",
          visiblity: "show",
        });
        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 1000);
      } catch (error: any) { }
    } else {
    }
  };

  const [currentStep, setCurrentStep] = useState<number>(1);
  const goToNextStep = () => {
    let isValid = true;

    localStorage.setItem("currentStep", (currentStep + 1).toString());
    setCurrentStep(currentStep + 1);
  };
  const goToPreviousStep = () => {
    localStorage.setItem("currentStep", (currentStep - 1).toString());
    setCurrentStep(currentStep - 1);
  };

  // useEffect(() => {
  //   const savedStep = localStorage.getItem("currentStep");
  //   if (savedStep) {
  //     setCurrentStep(parseInt(savedStep, 10));
  //   }
  // }, []);

  const exceptThisSymbols = [
    "e",
    "E",
    "+",
    "-",
    ".",
    "a",
    "A",
    "B",
    "b",
    "c",
    "C",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    " p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "D",
  ];

  const [amount, setAmount] = useState<any>({
    val: null,
    isValid: true,
    err: "",
    validate: false,
  });
  function amountHandle(val: any) {
    if (!val.trim()) {
      setAmount({
        isValid: false,
        err: "Requeird",
        val: null,
        validate: true,
      });
    } else if (val.trim().length > 7) {
      setIsDisabled(true);
      setAmount({
        isValid: false,
        err: "Value Must Be 7 Digits",
        val: val.substr(0, 7),
        validate: true,
      });
    } else if (val === 0) {
      setAmount({
        isValid: false,
        err: "Amount should contain only numeric digits",
        val: "",
        validate: true,
      });
    } else if (parseInt(val) <= 0) {
      setIsDisabled(true);
      setAmount({
        isValid: false,
        err: "Amount should be greater than 0",
        val: val,
        validate: true,
      });
    }
    else if (val > balance) {
      setIsDisabled(false);
      setAmount({
        isValid: false,
        err: "inificent balance",
        val: val,
        validate: true,
      });
    } else {
      setAmount({
        isValid: true,
        err: "",
        val: val,
        validate: true,
      });
    }
  }

  return (
    <div className="container">
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      {currentStep === 1 && (
        <div
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <h2 className="text-center">Withdrawal</h2>
          <div className="container">
            <div className="bg-white container mt-8 p-2 shadow p-3 mb-3 bg-body-tertiary rounded">
              <div className="d-flex align-content-start p-5">
                <div className="col-lg-12">
                  {" "}
                  <h3>From Account</h3>
                  <div className="mb-3">
                    <select
                      id="disabledSelect"
                      className="form-select p-3 mb-2 text-gray fw-semibold "
                      // onChange={handleChange}
                      name="acc"
                      value={PrimaryAc}
                    >
                      <option className="fw-semibold " value={PrimaryAc}>
                        Primary Account
                      </option>

                      {/* {PrimaryAc?.map((item: any, ind) => (
                        <option key={ind} value={item.accountNumber}>
                          Primary Account
                        </option>
                      ))} */}
                    </select>
                  </div>
                </div>


              </div>
              <span className="fw-bold position-relative ms-10">Primary Account Balance : {balance}</span>
            </div>

            <div className="bg-white container mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded">
              <div className="d-flex align-content-start p-5">
                <div className="col-lg-12">
                  <h3>Description</h3>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="description"
                      value={description}
                      onChange={(e) => {
                        if (e.target.value == "") {
                          setDescriptionError("Requeired")
                        } else {
                          setDescriptionError("")
                          setdescription(e.target.value.trimStart())
                        }
                        setdescription(e.target.value.trimStart())

                      }}
                      className="form-control py-6  p-3 mb-2 text-gray"
                      placeholder="Description"
                    />
                    {descriptionError ? (
                      <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                        {descriptionError}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className=" p-6 bg-white container  mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded">
              <div className="col-lg-12 d-flex justify-content-between p-3">
                <div className="col-lg-10 ms-1 ">
                  <h3>Amount</h3>
                </div>
                <span className="fw-semibold">
                  <div className="fv-row h-100px">
                    <label className="form-label mb-3 p-3">Enter Amount</label>
                    <input
                      type="text"
                      min="1"
                      className="mb-3 form-control form-control-lg form-control-solid"
                      name="account_name"
                      placeholder="Amount"
                      value={amount.val}
                      onKeyDown={(e: any) => {
                        exceptThisSymbols.includes(e.key) && e.preventDefault();
                      }}
                      onChange={(e: any) => {
                        let val = e.target.value;
                        if (val === "") {
                          setAmount({
                            isValid: false,
                            err: "Requred",
                            val: "",
                            validate: true,
                          });
                          return;
                        }
                        let numVal = parseInt(val);
                        if (numVal > 0) {
                          amountHandle(val);
                          if (val.length < 7) {
                          }
                        } else {
                          if (amount.val > 0) {
                          }
                        }
                      }}
                    />
                    {amount.err && (
                      <div className="text-danger" style={{ fontSize: "11px" }}>
                        {amount.err}
                      </div>
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className=" fv-row ">
              <label className="d-flex align-items-center form-label mb-3">
                Choose Amount
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="tooltip"
                  title="Provide your team size to help us setup your billing"
                ></i>
              </label>
              <div className="row mb-2" data-kt-buttons="true">
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="100"
                    />
                    <span className="fw-bold fs-3">100</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="500"
                    />
                    <span className="fw-bold fs-3">500</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="1000"
                    />
                    <span className="fw-bold fs-3">1000</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="3000"
                    />
                    <span className="fw-bold fs-3">3000</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="">
              <Link to="">
                <button
                  className="btn btn-warning col-lg-12 mt-10"
                  onClick={goToNextStep}
                  disabled={!(PrimaryAc && amount.val <= balance && description && amount.val)}
                >
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
      {currentStep === 2 && (
        <div
          className=""
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          {/* <div className="d-flex">
            <div className=" d-flex center" onClick={goToPreviousStep}>
              <i className="fs-1 bi bi-arrow-left-circle-fill"></i>
            </div>
          </div> */}

          <div className="bg-white mt-10 ">
            <h2 className="text-center">Confirm</h2>



            <div className="container col-lg-12 shadow-sm p-4 mb-5 bg-body-tertiary rounded">
              <p className="text-center mt-10 fs-3">
                Please review the details and confirm your exchange
              </p>
              <div className="d-flex justify-content-between align-items-start mt-10">
                <div className="">
                  <span>From</span>
                </div>

                <div className="">
                  <span className="fw-bolder">Primary Account</span> <br />
                  <span>{PrimaryAc}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-start mt-20">
                <div className="">
                  <span>Description</span>
                </div>

                <div className="">
                  <span className="fw-bolder">Description</span> <br />
                  <span>{description}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-start mt-10">
                <div className="">
                  <span>Ammount</span>
                </div>

                <div className="">
                  <span className="fw-bolder">{amount.val}.00</span> <br />
                </div>
              </div>


              <div className='  mt-20 d-flex justify-content-between'>
                <button className='btn btn-warning col-4 mb-10' onClick={goToPreviousStep}>Cancel </button>
                <button className='btn btn-warning col-4 mb-10' onClick={dataSubmit}>Finish Funding</button></div>
              {/* <div className="  mt-20">
                <button
                  className="btn btn-warning col-12 mb-10"
                  onClick={dataSubmit}
                >
                  Finish Funding
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
