import React from 'react'
import { Link } from 'react-router-dom';
import styles from "../../../scss/Card.module.scss"

export default function Security() {
  return (
    <div data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500"> <div >
              <div className='d-flex'>
      <div>
    <Link to='/setting'>
      <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div><div></div>
        <h2 className={styles.head1}>Privacy & Security</h2>
    </div>
    </div>
       <div className='bg-white d-flex justify-content-start ms-4 shadow-sm p-10 mb-5 bg-body-tertiary rounded mt-10'>
        <div className=' col-lg-12  ' >
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>Choose setup <br/>
            2 step verification</p>

            </div>
            <div className='mt-1 ms-1'>
              <Link to='/'>  <i className="text-warning fs-2 bi bi-check2-circle"></i></Link>
             
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>Setup pin for Transactions</p>
            </div>
            <div className='mt-1 ms-1'>
             <Link to='/setuptransaction'>
             <i className="text-warning fs-2 bi bi-chevron-right"></i>

             </Link>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>Change Password</p>
            </div>
            <div className='mt-1 ms-1'>

                <Link to='/changepassword'>
                <i className="text-warning fs-2 bi bi-chevron-right"></i></Link>
              
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>GS privacy</p>
            </div>
            <div className='mt-1 ms-1'>
            <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>Gs Security</p>
            </div>
            <div className='mt-1 ms-2'>
            <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>Gs term or use</p>
            </div>
            <div className='mt-1 ms-2'>
            <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </div>
          </div>
         
          
        </div>
      </div>
    </div>
  )
}
