import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from "../../../scss/Card.module.scss"

function BusinessAddress() {
 
  const [BusinessAddress, setBusinessAddress] = useState('');
  const [Search, setSearch] = useState('');
  

  const handleBusinessAddressChange = (event) => {
    setBusinessAddress(event.target.value);
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };


  return (
    <div className='' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
      <div className='d-flex'>
      <div>
    <Link to='/businessinfo'>
      <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div><div></div>
        <h2 className={styles.head1}>Business Address</h2>
    </div>
    <div className='bg-white  mt-8 p-2 shadow-sm p-10 mb-5  rounded'>
      <div className='d-flex justify-content-between'>
     <div className='mt-5 '>
        <h3 className='ms-5'>Business Address</h3>
        <div className=''>
        <input
        className="form-control m-5 mt-2" 
          type="text"
          placeholder='Search address'
          value={BusinessAddress}
          onChange={handleBusinessAddressChange}
        />
        </div>
      </div>
      <div className='mt-5'>
        <h3 className='ms-5'> Point of contact address</h3>
        <div className=''>
        <input
        className="form-control m-5 mt-2" 
          type="text"
          placeholder='Search address'
          value={Search}
          onChange={handleSearchChange}
        />
        </div>
        </div>
      </div>
      <Link to="/reviewinfo">
      <button className='btn btn-warning m-6 col-lg-12' type="submit">Continue</button>
      </Link>
      </div>
    </div>
  )
}

export default BusinessAddress;

