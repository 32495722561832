import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../../scss/Card.module.scss";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import Services from "../../Services";
// import 'bootstrap/dist/css/bootstrap.min.css';

function User() {
  const [UserDetails, setUserDetails] = useState([]);
  console.log("data--", UserDetails);

  const [firsttName, setFirstName] = useState(12);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const queryParams = useLocation();

  // console.log("searchhhhhhhhhhhhhhhhh",limitt)

  const GET_USER = async () => {
    try {
      let object: any = {
        page,
        limit,
      };
      let payload: any = new URLSearchParams(object).toString();
      const response: any = await Services.User.GET_USERS(payload);
      console.log("--------------------------", response.data);
      setUserDetails(response.data.data.rows);
    } catch (error) {}
  };

  useEffect(() => {
    GET_USER();
  }, []);

  return (
    <div className="container col-lg-12  " data-aos="fade-up-left">
      <h1 className="text-center">User Details</h1>
      <div className="mt-20 w-100">
        <table
          className="bg-white table-hover table align-middle  fs-6 gy-7 gx-8 table-responsive responsive shadow p-10 bg-body-tertiary rounded"
          id="kt_table_users"
        >
       
          <thead data-aos="fade-left thead-dark">
            <tr className="text-center  fw-bold fs-7 text-uppercase  ">
              <th className="min-w-150px text-dark">S.N</th>
              <th className="min-w-200px text-dark">GS TAG</th>
              <th className="min-w-200px text-dark">User Name</th>
              <th className="min-w-200px text-dark ">Email</th>
              <th className="min-w-200px text-dark ">Status</th>

              <th className="min-w-125px text-dark">Action</th>
            </tr>
          </thead>
          <tbody className="text-gray-600" data-aos="fade-right  ">
            {UserDetails?.map((user: any, index) => {
              return (
                <tr
                  key={index}
                  className="hover  text-muted fw-bold fs-7  gs-0 text-center"
                >
                  <td className="pt-12 min-w-100px">{index + 1}</td>
                   <td className="pt-12">
                    {user.gsTag.length > 10
                      ? `${user.gsTag.slice(0, 10)}...`
                      : user.gsTag}
                  </td>
                  <td className="pt-12">
                    {user.firstName.length > 10
                      ? `${user.firstName.slice(0, 10)}...`
                      : user.firstName}
                  </td>
                  <td className="pt-12">
                    {user.email.length > 10
                      ? `${user.email.slice(0, 10)}...`
                      : user.email}
                  </td>
               

                  <td className="pt-12">
                    {(user.isIdentityVerify === 1 && (
                      <span className="text-success">Approved</span>
                    )) ||
                      (user.isIdentityVerify === 3 && (
                        <span className="text-danger">Reject</span>
                      )) ||
                      (user.isIdentityVerify === 2 && (
                        <span className="text-danger">InProgress</span>
                      )) ||
                      "Panding"}
                  </td>

                  <td>
                    <button className="btn s">
                      <Link to={`/viewdetails/${user.id}`} type="button">
                        <i className="pt-2 mb-1 fs-2 fa-solid fa-eye text-warning"></i>
                      </Link>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default User;
