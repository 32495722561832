import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import { useState } from 'react';
// import { login } from '../../../../app/modules/auth/core/_requests';
export default function ChangePassword() {
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [newpasswordError, setNewPasswordError] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [newPasswordType, setNewPasswordType] = useState("password");

    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  
    const [ConfirmPassword, setConfrimPassword] = useState("");
    const [isDisabled, setIsDisabled] = useState(true); ///set--true
    const [passwordType, setPasswordType] = useState("password");

  const exceptThisSymbols = ["+", "-", "*", "/", " "];
    // const [ShowLoginUserId,setShowLoginUserId]=useState("")
    // const toggleLoginUserIdVisibility = () => {
    //     setShowLoginUserId(!ShowLoginUserId);
    //   };
    //   const handleLoginUserIdChange = (e) => {
    //     const enteredLoginUserId = e.target.value.trim();
    //     setLoginUserId(enteredLoginUserId);
        
    //   };
  
    // const [LoginUserId, setLoginUserId] = useState("")
  return (
    <div data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
        <div>

        <div className='conatiner d-flex justify-content-between col-12 '>
    <Link to='/security'><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div>
  <h2 className='text-center'>Change Password</h2>
        </div>
      
     

      <div className='container bg-white shadow-sm p-3 mb-5     col-12 bg-body-tertiary rounded mt-10'>
        <div className=' container bg-warning p-2 text-dark bg-opacity-25 col-7 p-15 mt-7 flex-end rounded '>
            <h4 className='text-center text-warning'>Create your new password</h4>
            <p className='mt-5'>It should be 8-32 characters long, include at least one number and one latter.we may prompt you to
                reset your password for security reason </p>
                </div>

                <div className='container m-5 '>
                {/* <!--begin::Form--> */}
<form id="kt_docs_formvalidation_password" className="form" action="#" autoComplete="off">
    {/* <!--begin::Input group---> */}
    <div className="position-relative  mt-12 mb-5">
                    <h6 className="text-start ">Password</h6>

                    <input
                      type={passwordType}
                      id="password"
                      placeholder="Password"
                      name="password"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onChange={(e) => {
                        if (e.target.value.trim() === "") {
                          setIsDisabled(true);

                          setPasswordError("Requierd");
                        }  else {
                          setPassword(e.target.value.trimStart());
                          setPasswordError("");
                        }
                        // setEmail(e.target.value.trimStart());
                        setPassword(e.target.value.trimStart());
                      }}
                      value={password}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      // onKeyDown={(e) => {
                      //   e.key === "Enter"
                      //     ? !isDisabled
                      //       ? handleLogin()
                      //       : e.preventDefault()
                      //     : setPassword(e.target.value);
                      // }}
                      // Placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      // aria-describedby="password"
                    />
                    {/* <br /> */}
                    {passwordError ? (
                      <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                        {passwordError}
                      </p>
                    ) : null}
                    {/* {passwordError ? (
                      <small style={{ color: "red" }}>{passwordError}</small>
                    ) : null} */}
                    <span
                      className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-4"
                      data-kt-password-meter-control="visibility"
                      onClick={() => {
                        passwordType === "password"
                          ? setPasswordType("text")
                          : setPasswordType("password");
                      }}
                    >
                      {passwordType === "password" ? (
                        <i className="bi bi-eye fs-2"></i>
                      ) : (
                        <i className="bi bi-eye-slash fs-2"></i>
                      )}
                    </span>
                  </div>
    {/* <!--end::Input group---> */}

    {/* <!--begin::Input group--> */}
    <div className="position-relative  mt-12 mb-5">
                    <h6 className="text-start ">New password</h6>

                    <input
                      type={newPasswordType}
                      id="password"
                      placeholder="Password"
                      name="password"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onChange={(e) => {
                        if (e.target.value.trim() === "") {
                          setIsDisabled(true);

                          setNewPasswordError("Requierd");
                        }  else {
                          setNewPassword(e.target.value.trimStart());
                          setNewPasswordError("");
                        }
                        // setEmail(e.target.value.trimStart());
                        setNewPassword(e.target.value.trimStart());
                      }}
                      value={newpassword}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      // onKeyDown={(e) => {
                      //   e.key === "Enter"
                      //     ? !isDisabled
                      //       ? handleLogin()
                      //       : e.preventDefault()
                      //     : setPassword(e.target.value);
                      // }}
                      // Placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      // aria-describedby="password"
                    />
                    {/* <br /> */}
                    {newpasswordError ? (
                      <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                        {newpasswordError}
                      </p>
                    ) : null}
                    {/* {passwordError ? (
                      <small style={{ color: "red" }}>{passwordError}</small>
                    ) : null} */}
                    <span
                      className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-4"
                      data-kt-password-meter-control="visibility"
                      onClick={() => {
                        newPasswordType === "password"
                          ? setNewPasswordType("text")
                          : setNewPasswordType("password");
                      }}
                    >
                      {newPasswordType === "password" ? (
                        <i className="bi bi-eye fs-2"></i>
                      ) : (
                        <i className="bi bi-eye-slash fs-2"></i>
                      )}
                    </span>
                  </div>
    {/* <!--end::Input group---> */}

    {/* <!--begin::Input group---> */}
  <div className="position-relative  mt-12 mb-5">
                    <h6 className="text-start ">Confim Password</h6>

                    <input
                      type={confirmPasswordType}
                      id="password"
                      placeholder="Password"
                      name="password"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onChange={(e) => {
                        if (e.target.value.trim() === "") {
                          setIsDisabled(true);

                          setConfirmPasswordError("Requierd");
                        }  else {
                            setConfirmPassword(e.target.value.trimStart());
                          setConfirmPasswordError("");
                        }
                        // setEmail(e.target.value.trimStart());
                        setConfirmPassword(e.target.value.trimStart());
                      }}
                      value={confirmPassword}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      // onKeyDown={(e) => {
                      //   e.key === "Enter"
                      //     ? !isDisabled
                      //       ? handleLogin()
                      //       : e.preventDefault()
                      //     : setPassword(e.target.value);
                      // }}
                      // Placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      // aria-describedby="password"
                    />
                    {/* <br /> */}
                    {confirmPasswordError ? (
                      <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                        {confirmPasswordError}
                      </p>
                    ) : null}
                    {/* {passwordError ? (
                      <small style={{ color: "red" }}>{passwordError}</small>
                    ) : null} */}
                    <span
                      className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-4"
                      data-kt-password-meter-control="visibility"
                      onClick={() => {
                        passwordType === "password"
                          ? setPasswordType("text")
                          : setPasswordType("password");
                      }}
                    >
                      {passwordType === "password" ? (
                        <i className="bi bi-eye fs-2"></i>
                      ) : (
                        <i className="bi bi-eye-slash fs-2"></i>
                      )}
                    </span>
                  </div>
    {/* <!--end::Input group---> */}

    {/* <!--begin::Actions--> */}

    <div className='mt-20'>
    <Link to='/security'>
    <button id="kt_docs_formvalidation_password_submit" type="submit" className="btn btn-secondary col-5">
        <span className="indicator-label">
            Cancel
        </span>
        <span className="indicator-progress">
            Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
    </button>
    </Link>
    
<Link to='/passwordsuccessfull'><button id="kt_docs_formvalidation_password_submit" type="submit" className="btn btn-warning ms-5 col-6">
        <span className="indicator-label">
            Finish Change
        </span>
        <span className="indicator-progress">
            Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
    </button></Link>
    </div>

    
    {/* <!--end::Actions--> */}
</form>
{/* <!--end::Form--> */}
                         
                </div>
         </div>
    </div>
  )
}
