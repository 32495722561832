import React, { useEffect, useState } from "react";
import Services from "../../Services";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function TransactionList() {
  const [TransactionHistroy, setTransactionHistory] = useState([]);
  const navigate = useNavigate();
  const getPrimaryData = async () => {
    try {
      const response: any =
        await Services.Transaction.GET_USER_WITHDRAW_TRANSACTIONS();
      setTransactionHistory(response?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getPrimaryData();
  }, []);

  const clickOnBtn = (ind) => {
    const data = { ...ind };
    navigate("/withdrawalappove", { state: { data } });
  };

  return (
    <div className="container col-lg-12" data-aos="fade-up-left">
      <h1 className="text-center">Transaction List</h1>
      <div className="mt-20 w-100">
        <table
          className="table-hover table align-middle table-row-dashed fs-6 gy-7 gx-8 table-responsive responsive "
          id="kt_table_users"
        >
          <thead data-aos="fade-left thead-dark">
            <tr className="text-center text-muted fw-bold fs-7 text-uppercase gs-0 ">
              <th className="min-w-150px text-dark">User Name</th>
              <th className="min-w-150px text-dark">Amount</th>
              <th className="min-w-200px text-dark ">User Notes</th>
              <th className="min-w-200px text-dark ">Admin Notes</th>
              <th className="min-w-150px text-dark ">Date</th>
              <th className="min-w-150px text-dark ">Status</th>
              <th className="min-w-150px text-dark ">Action</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-semibold" data-aos="fade-right  ">
            {TransactionHistroy.map((item: any, index) => {
             
              return (
                <tr className="text-center" key={index}>
                  <td>{item?.User?.firstName}</td>
                  <td>{item.amount}</td>
                  {/* <td>{item.userNotes.slice(0, 15)}..</td> */}
                  <td className={item.userNotes ? "text-white-space-nowrap" : "text-white-space-nowrap empty-cell"}>{item.userNotes?item.userNotes.substring(0, 15):"-"}</td>
                    <td className={item.adminNotes ? "text-white-space-nowrap" : "text-white-space-nowrap empty-cell"}>{item.adminNotes?item.adminNotes.substring(0, 15):"-"}</td>
                  <td>  {moment(item.createdAt).format('MMM DD, YYYY hh:mm A')}</td>
                  <td>
                    {(item.requestStatus === "Requested" && (
                      <span style={{ color: "blue" }}>Requested</span>
                    )) ||
                      (item.requestStatus === "Accepted" && (
                        <span className="text-success">Accepted</span>
                      )) ||
                      (item.requestStatus === "Declined" && (
                        <span className="text-danger">Declined</span>
                      )) ||
                      ""}
                  </td>
                  <td>
                    <i
                      onClick={() => clickOnBtn(item)}
                      className="cursor fs-2 fa-solid fa-eye text-warning"
                    ></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TransactionList;
