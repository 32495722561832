import React, { useEffect, useState } from "react";
import Services from "../Services";
import { Link } from "react-router-dom";
import moment from "moment";

export default function MakeWithdraw() {
  const [data, setData] = useState<any>([]);
  const getPrimaryData = async () => {
    try {
      const response: any =
        await Services.Transaction.GET_USER_WITHDRAW_TRANSACTIONS();
      setData(response?.data?.data);
   
    } catch (error) {
  
    }
  };

  useEffect(() => {
    getPrimaryData();
  }, []);

  return (
    <>
      <div className="card ">
        <div className="card-header">
          <h3 className="card-title">Withdrawal List</h3>
          <div className="card-toolbar">
            <Link to="/withdrawalpopup">
              <button type="button" className="btn btn-sm  btn-warning ">
                Withdrawal
              </button>
            </Link>
          </div>
        </div>
        <div className="card-body">
          <table
            className="  table align-middle table-row-dashed fs-6 gy-7 gx-8 table-responsive responsive  bg-body-tertiary rounded"
            id="kt_table_users"
          >
            <div className="card-title">
              <div className="d-flex align-items-center position-relative my-1"></div>
            </div>

            <thead data-aos="fade-left thead-dark">
              <tr className="text-center text-muted fw-bold fs-7 text-uppercase gs-0 ">
                {/* <th className="min-w-150px text-dark">reg.Id</th> */}
                <th className="min-w-150px text-dark">Amount</th>
                <th className="min-w-300px text-dark ">Notes</th>
                <th className="min-w-300px text-dark ">Admin Notes</th>
                <th className="min-w-250px text-dark ">Date & Time</th>
                <th className="min-w-150px text-dark ">Status</th>
              </tr>
            </thead>
            <tbody className="text-gray-600" data-aos="fade-right  ">
              {data.map((item: any, index) => {
                return (
                  <tr className="text-center" key={index}>
                    {/* <td>{item.id}</td> */}
                    <td>{item.amount}</td>
                    {/* <td>{item.userNotes.substring(0, 12)}...</td> */}
                    <td>{item.userNotes?item.userNotes.substring(0, 15):"-"}</td>
                    <td>{item.adminNotes?item.adminNotes.substring(0, 15):"-"}</td>
                    <td>  {moment(item.createdAt).format('MMM DD, YYYY hh:mm A')}</td>
                    <td>
                      {(item.requestStatus === "Requested" && (
                        <span style={{ color: "blue" }}>Requested</span>
                      )) ||
                        (item.requestStatus === "Accepted" && (
                          <span className="text-success">Accepted</span>
                        )) ||
                        (item.requestStatus === "Declined" && (
                          <span className="text-danger">Declined</span>
                        )) ||
                        ""}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
