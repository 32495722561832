import React from 'react'
import { Link } from 'react-router-dom';
import styles from "../../scss/Card.module.scss"

export default function ConfirmSendAmmount() {
  return (
    <div className='' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
      <div className='d-flex'>
      <div>
    <Link to='/sendammount'><i className="fs-1 bi bi-arrow-left-circle-fill"></i></Link>
  </div>
        <h2 className={styles.head}>Confirm</h2>
    </div>
        

  <div className='mt-10 bg-white shadow-sm p-4 mb-5 bg-body-tertiary rounded'>
        <p className='text-center mt-10 fs-3'>Please review the details and confirm your exchange</p>
      <div className='container col-lg-12'>
        <div className='d-flex justify-content-between align-items-start mt-20'>
            <div className=''> <span>From</span></div>
           

            <div className=''> <span className='fw-bolder'>Debit Card</span> <br/>
            <span> 452145*********5464</span>
            </div>
        </div>

        <div className='d-flex justify-content-between align-items-start mt-10'>
            <div className=''> <span>To</span></div>
           

            <div className=''> <span className='fw-bolder'>GS Gold Account</span> <br/>
            <span> 452145*********5464</span>
            </div>
        </div>

        <div className='d-flex justify-content-between align-items-start mt-10'>
            <div className=''> <span>Ammount</span></div>
           

            <div className=''> <span className='fw-bolder'>00.00</span> <br/>
            <span> 00.00</span>
            </div>
        </div>



        <div className='mt-10 mb-10 d-flex justify-content-between'> 
    <Link className='col-4' to='/sendammount'> <button className='btn btn-warning '>Cancel Send</button></Link>
      <Link className='' to='/sendsuccessful'> <button className='btn btn-warning '>Finish Send </button></Link></div>

  </div>
      </div>
      </div>
  )
}
