/* eslint-disable import/no-anonymous-default-export */
export default {
  LOGIN: () => "/auth/login",
  REGISTER: () => `/auth/register`,
  IMAGE_UPLOAD: () => `/user/documentImage`,
  UPDATE_IMAGE: () => `/user/me`,
  PROFILE_UPLOAD: () => `/user/profile/`,
  SEND_OTP: () => `/userOtp/sendOtp`,
  VERIFY_OTP: () => `/userOtp/verifyOtp`,
  RESEND_OTP: () => `/userOtp/reSendOtp/1`,
  GET_GSTAG: () => `/user/getGsTag`,
  GET_iMAGE: () => `/user/image`,
  FORGOT_PASSWORD: () => `/user/changePassword`,
}; 


