import React from 'react'
import { Link } from 'react-router-dom';
import styles from "../../scss/Card.module.scss"

export default function ConfirmWithdrawsuccessfull() {
  return (
    <div className='' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
      <div className='d-flex'>
      <div>
    <Link to='/wallet'><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div>
        <h2 className={styles.head}>Confirm</h2>
    </div>
        

  <div className='bg-white mt-10 shadow-sm p-4 mb-5 bg-body-tertiary rounded'>
        <p className='text-center mt-10 fs-3'>Please review the details and confirm your exchange</p>
      <div className='container col-lg-12'>
        <div className='d-flex justify-content-between align-items-start mt-20'>
            <div className=''> <span>To</span></div>
           

            <div className=''> <span className='fw-bolder'>Debit Card</span> <br/>
            <span> 452145*********5464</span>
            </div>
        </div>

        <div className='d-flex justify-content-between align-items-start mt-10'>
            <div className=''> <span>From</span></div>
           

            <div className=''> <span className='fw-bolder'>GS Gold Account</span> <br/>
            <span> 452145*********5464</span>
            </div>
        </div>

        <div className='d-flex justify-content-between align-items-start mt-10'>
            <div className=''> <span>Ammount</span></div>
           

            <div className=''> <span className='fw-bolder'>00.00</span> <br/>
            <span> 00.00</span>
            </div>
        </div>



        <div className='  mt-20'>   <Link className='' to='/withdrawsuccessfull'> <button className='btn btn-warning col-12 mb-10'>Finish withdrawal</button></Link></div>

      </div>
      </div>
      </div>
  )
}
