import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from "../../../scss/Card.module.scss"

function ReviewBusinessinfo() {
 
  const [BusinessName, setBusinessName] = useState('');
  const [BusinessAddress, setBusinessAddress] = useState('');
  const [BusinessWebsite, setBusinessWebsite] = useState('');

  const handleBusinessNameChange = (event) => {
    setBusinessName(event.target.value);
  };
  const handleBusinessAddressChange = (event) => {
    setBusinessAddress(event.target.value);
  };
  const handleBusinessWebsiteChange = (event) => {
    setBusinessWebsite(event.target.value);
  };

  return (
    <div className='' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
             <div className='d-flex'>
      <div>
    <Link to='/reviewinfo'>
      <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div><div></div>
        <h2 className={styles.head1}>Review Business Information</h2>
    </div>
    <div className=' bg-white  mt-8 p-2 shadow-sm p-10 mb-5  rounded'>
      <div className='mt-5 text-black-50 '>
      <p className='fs-3 ms-4'>Your information will be used to verify your identity, kindly confirm everything is correct to avoid  delays.</p>
      </div>
      <div className='d-flex justify-content-between'>
      <div className='mt-10 '>
        <h3 className='ms-5'>Business Name</h3>
        <div className='mt-3'>
        <input
        className="form-control m-5 mt-2" 
          type="text"
          placeholder='Legal business name'
          value={BusinessName}
          onChange={handleBusinessNameChange}
        />
        </div>
      </div>
      <div className='mt-10'>
        <h3 className='ms-5'>Business Address</h3>
        <div className='mt-3'>
        <input
        className="form-control m-5 mt-2" 
          type="text"
          placeholder='Business Address'
          value={BusinessAddress}
          onChange={handleBusinessAddressChange}
        />
        </div>
      </div>
      <div className='mt-10 '>
        <h3 className='ms-5'>Business Website</h3>
        <div className='mt-3'>
        <input
        className="form-control m-5 mt-2" 
          type="text"
          placeholder='Business Website'
          value={BusinessWebsite}
          onChange={handleBusinessWebsiteChange}
        />
        </div>
        </div>
      </div>
      <Link to="/verifyidentity">
      <button className='btn btn-warning m-6 col-lg-12' type="submit">Continue</button>
      </Link>
      </div>
    </div>
  )
}

export default ReviewBusinessinfo
