/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
// import {KTIcon} from '../../../helpers'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
// import {AsideMenuItem} from './AsideMenuItem'
// import { Link } from 'react-router-dom'
import { AdminAsideMenuItem } from "./AdminAsideMenuItem";

export function AdminAsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AdminAsideMenuItem to="/user" title="User" />
      {/* <AdminAsideMenuItem to ='/transactions' title='Transaction' /> */}
      <AdminAsideMenuItem to="/supportdetails" title="Support Details" />
      <AdminAsideMenuItem to="/transactionlist" title="Transactions" />
      <AdminAsideMenuItem to="/logoutgs" title="Logout" />
    </>
  );
}
