/* eslint-disable import/no-anonymous-default-export */
export default {
    GET_USERS: (query) => `/user?${query ? query : ""}`,
    GET_USER_BY_ID: (id) => `/user/${id}`,
    GET_ALL_USER: () => '/user/getUserDataByUserId',
    UPDATE_USER_BY_ID: () => '/user/me',
    GET_USER: () => '/user/me',
    GET_BY_ID: (id) => `/user/${id}`,
    UPDATE_USER: () => '/user/me',
    UPDATE_VERIFY_BY_ID: (id) => `/user/identityVerify/${id}`,
    GET_USER_BY_DOCUMENT_ID: (query) => `/user/documentId?${query ? query : ''}`,
    GET_USER_BY_EMAIL: (query) => `/user/email?${query ? query : ''}`,
   GET_USER_BY_GSTAG:   (query)=> `/user/getUserByGsTag?gsTag=${query}`,
   EXCHANGE_GS_TO_GS:()=>`/gsAccount/exchangeGsToGs`
};