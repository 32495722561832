import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from "../../../scss/Card.module.scss"
function Businessinfo() {
 
  const [BusinessName, setBusinessName] = useState('');
  const [BusinessWebsite, setBusinessWebsite] = useState('');
  

  const handleBusinessNameChange = (event) => {
    setBusinessName(event.target.value);
  };
  const handleBusinessWebsiteChange = (event) => {
    setBusinessWebsite(event.target.value);
  };


  return (
    <div className='' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
      <div>
      <div className='d-flex'>
      <div>
    <Link to='/verification'>
      <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div>
  
        <h2 className={styles.head1}>Business Information</h2>
    </div>  
     <div className='mt-5'>
       
        </div>
        </div>
        <div className='bg-white  text-center mt-8 p-2 shadow-sm p-10 mb-5  rounded'>
        <div className='mt-10'>
        <input
        className="form-control m-5 mt-2" 
          type="text"
          placeholder='Legal business name'
          value={BusinessName}
          onChange={handleBusinessNameChange}
        />
        </div>
    
      <div className='mt-10'>
        <input
        className="form-control m-5 mt-2" 
          type="text"
          placeholder='Business Website'
          value={BusinessWebsite}
          onChange={handleBusinessWebsiteChange}
        />
        </div>
        <div className='text-start ms-6 mt-10'>
        <p>Does your business have a website ?</p>
       </div>
       <div className='d-flex'>
            <div className='text-start ms-5 btn btn-light'> Yes</div>
            <div className='text-start ms-20 btn btn-light'> No</div>
        </div>
        <Link to="/businessaddress">
        <button className='btn btn-warning m-6 col-lg-12' type="submit">Continue</button>
       </Link>
       </div>
    </div>
  )
}

export default Businessinfo;


