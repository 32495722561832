import React, { useState } from 'react'
import OTPInput, { ResendOTP } from 'otp-input-react' 
import { Link } from 'react-router-dom';
export default function SetupPinForTransaction() {
    const [OTP, setOTP] = useState("");
  return (
    <div data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
      <div>
      <div className='conatiner d-flex justify-content-between col-12 mt-5'>
    <Link to='/security'><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div>
      </div>
    
      <div className='bg-white col-6 mt-20  container shadow p-3 mb-5 bg-body-tertiary rounded p-20' >
       <div className=''>
        <h2 className=' text-secondary-emphasis'>Enter your 4 digit pin</h2>
        <p>Ensure you don't share this pin with anyone else</p>
       </div>
      <div className=''> <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
      <p className='mt-5'> Having trouble?</p>
     <Link to=''> <ResendOTP onResendClick={() => console.log("Resend clicked")} />
     </Link>
      </div>
      <div className='mt-15'> <Link to='/pinsetup'> <button className='btn btn-warning col-12'>Save pin</button></Link></div>
      </div>
      </div>
  )
}









