import React, { useEffect, useState } from "react";
import Services from "../Services";
import Styles from "../scss/Card.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
export default function RecentTransactions() {
  const [transactionHistroy, setTransactionHistory] = useState([]);

  const [FundAccount, setFundAcccount] = useState("");
  const [MoveAccount, setMoveAcccount] = useState("");
  const [sendAccount, setSendAcccount] = useState("");
  const [fromAccount, setFromAccount] = useState<any>("");
  const [toAccount, setToAccount] = useState<any>("");
  const [withdrawalAccount, setWithdrawalAcccount] = useState("");
  const [primaryAccount, setPrimaryAccount] = useState("");
  const [gsGoldAccount, setGSGoldAccount] = useState("");
  const [balance, setBalance] = useState("");
  const [type, setType] = useState("");
  // const [id, setID] = useState("");

  let navigate = useNavigate();

  let id = sessionStorage.getItem("id");

  const handlefundaccount = async () => {
    setFundAcccount("1");

    try {
      const response: any = await Services.Transaction.GET_TRANSACTION_BY_ID(
        id,
        1
      );
      setTransactionHistory(response.data.data);
    } catch {}
  };

  const handlemoveaccount = async () => {
    setMoveAcccount("2");

    try {
      const response: any = await Services.Transaction.GET_TRANSACTION_BY_ID(
        id,
        2
      );
      let data = response.data.data[0];

      data.GsAccount.type = "gsAccount";
      data.PrimaryAccount.type = "PrimaryAccount";
      setGSGoldAccount(data?.GsAccount?.type);
      setPrimaryAccount(data?.PrimaryAccount?.type);
      setFromAccount(data?.GsAccount);
      setToAccount(data?.PrimaryAccount);
      setBalance(data.balance);
      setType(data.type);

      setTransactionHistory(response.data.data);
    } catch {}
  };
  console.log("fromAccount", fromAccount);
  console.log("toAccount", toAccount);
  console.log("balance", balance);
  const handlesendaccount = async () => {
    setSendAcccount("3");

    try {
      const response: any = await Services.Transaction.GET_TRANSACTION_BY_ID(
        id,
        3
      );

      setTransactionHistory(response.data.data);
    } catch {}
  };

  const handlewithdrawalaccount = async () => {
    setWithdrawalAcccount("4");
    try {
      const response: any = await Services.Transaction.GET_TRANSACTION_BY_ID(
        id,
        4
      );

      setTransactionHistory(response.data.data);
      const getWithdrawalList = async () => {
        const response: any =
          await Services.Transaction.GET_USER_WITHDRAW_TRANSACTIONS();
        const darrrr = response?.data?.data.filter(
          (item) => item.requestStatus === "Accepted"
        );
        setTransactionHistory(darrrr);
      };
      getWithdrawalList();
    } catch {}
  };
  useEffect(() => {
    handlefundaccount();
    handlemoveaccount();
    handlesendaccount();
    handlewithdrawalaccount()
  }, []);

 
  return (
    <div>
      <div className={Styles.transaction} data-aos="fade-up-left">
        <div className="bg-white p-10 col-lg-12 shadow p-3 mb-5 bg-body-tertiary rounded">
          <h1 className="mb-20">Transaction List </h1>
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="" role="presentation">
              <Link to="">
                <div
                  className="fs-3 m-3 text-dark"
                  onClick={() => handlefundaccount()}
                >
                  Fund Account List
                </div>
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link to="">
                <div
                  className="fs-3 m-3 text-dark"
                  onClick={() => handlemoveaccount()}
                >
                  Move Account List
                </div>
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link to="">
                <div
                  className="fs-3 m-3 text-dark"
                  onClick={() => handlesendaccount()}
                >
                  Send Account List
                </div>
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link to="">
                <div
                  className="fs-3 m-3 text-dark"
                  onClick={() => handlewithdrawalaccount()}
                >
                  Withdrawal Account List
                </div>
              </Link>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              ...
            </div>
          </div>
          {transactionHistroy && (
            <div>
              <table
                className=" bg-white table-hover table align-middle table-row-dashed fs-6 gy-7 gx-8 table-responsive responsive "
                id="kt_table_users"
              >
                <thead data-aos="fade-left thead-dark">
                  {withdrawalAccount && (
                    <tr className="text-center text-muted fw-bold fs-7 text-uppercase gs-0 ">
                      <th className="min-w-225px text-dark">User Name</th>
                      <th className="min-w-225px text-dark">Amount</th>
                      <th className="min-w-225px text-dark ">Notes</th>
                      <th className="min-w-225px text-dark ">Status</th>
                    </tr>
                  )}
                </thead>
                <tbody
                  className="text-gray-600 fw-semibold"
                  data-aos="fade-right"
                >
                  {transactionHistroy.map((item: any) => {
                    return (
                      <tr className="text-center">
                        <td>{item?.User?.firstName}</td>
                        <td>{item.amount}</td>
                        <td>{item?.userNotes?.slice(0, 9)}...</td>
                        <td>
                          <span className="text-success">
                            {item.requestStatus}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          <div className="container col-lg-12" data-aos="fade-up-left">
            <div className={Styles.table}>
              <table
                className=" bg-white table-hover table align-middle table-row-dashed fs-6 gy-7 gx-8 "
                id="kt_table_users"
              >
                <thead data-aos="fade-left thead-dark">
                  <tr className="text-center text-muted fw-bold fs-7 text-uppercase gs-0 ">
                    <th className="min-w-300px text-dark">Ammount</th>
                    
                    <th className="min-w-300px text-dark ">From Account</th>
                    <th className="min-w-300px text-dark ">To Account</th>
                    <th className="min-w-300px text-dark ">Type</th>
                  </tr>
                </thead>
                <tbody
                  className="text-gray-600 fw-semibold"
                  data-aos="fade-right"
                >
                       {transactionHistroy.map((item: any, index) => {
             return(

              <tr className="hover  text-center">
              <td>{item.balance}</td>
              <td className="pt-12">
                    {(item.type === "CR"&& (
                      <span className="">Primary Acccount </span>
                    )) ||
                      (item.type === "DR" && (
                        <span className="">Gs Gold Account</span>
                      )) ||
                      ""}
                  </td>
                  <td className="pt-12">
                    {(item.type === "CR"&& (
                      <span className="">Gs Gold Account </span>
                    )) ||
                      (item.type === "DR" && (
                        <span className="">Primary Acccount</span>
                      )) ||
                      ""}
                  </td>
              {/* <td>{fromAccount?.type}</td>
              <td>{toAccount?.type}</td> */}

              <td className="pt-12">
                    {(item.type === "CR"&& (
                      <span className="text-success">Credit</span>
                    )) ||
                      (item.type === "DR" && (
                        <span className="text-danger">Debit</span>
                      )) ||
                      ""}
                  </td>
            </tr>
             )
          })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <>
     
        </>
      </div>
    </div>
  );
}
