import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../scss/Card.module.scss";

export default function Transact() {
  const [showArrowButtons, setShowArrowButtons] = useState(false);

  // Function to toggle the visibility of the arrow buttons
  const toggleArrowButtons = () => {
    setShowArrowButtons(!showArrowButtons);
  };
  return (
    <div className="container">
      <div>
        <h1 className="text-center">Transact component</h1>

        <div>
          <div className="container mt-8 p-2 ">
            <div
              className=" bg-white d-flex justify-content-between p-5 shadow p-3 mb-5 bg-body-tertiary rounded"
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h3 className=" mt-2 ms-5">Deposit</h3>
                </div>
                <div>
                  <Link to="/fundaccount">
                    <i
                      className="bi bi-arrow-up-right-circle fs-1 text-warning "
                      id={styles.transactI}
                    ></i>
                  </Link>
                </div>
              </div>

              {/* <div className='col-lg-11'>
     <h2>Gs Gold Account</h2>
      <span>2545454554274</span></div> */}

              {/* <div className=''> 
      <span className='fw-bolder '>xAcu256346844</span><br/>
        <span className='fw-bolder'>256346844</span>
        </div> */}
            </div>

            <div
              className="bg-white d-flex justify-content-between p-5 shadow p-3 mb-5 bg-body-tertiary rounded"
              data-aos="fade-right"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h3 className=" mt-2 ms-5">Withdrawal</h3>
                </div>
                <div>
                  <Link to="/makeawithdraw">
                    <i
                      className="bi bi-arrow-up-right-circle fs-1 text-warning "
                      id={styles.transactI1}
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className=""
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <Link to="/addcard">
                <button className="btn btn-warning col-lg-12 mt-6 ">
                  Add new card
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-start ms-4"
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className=" mt-8 col-12  ">
            <div className="conatiner d-flex justify-content-between mt-5">
              <div>
                <h5>Debit Card</h5>
                <p>7854*****4645</p>
              </div>

              <div className="mt-1">
                <i
                  className="text-warning fs-2 bi bi-chevron-right me-8"
                  onClick={toggleArrowButtons}
                ></i>
              </div>
            </div>

            <div className="conatiner d-flex justify-content-between mt-5">
              <div>
                <h5>Credit card</h5>
                <p>4564******5544</p>
              </div>
              {showArrowButtons && (
                <div className="d-flex">
                  {/* <p className='text-center m-2'>Select</p> */}
                  <button className="btn btn-warning m-1">Delete</button>
                  <button className="btn btn-warning m-1">Cancel</button>
                </div>
              )}
              <div className="mt-1">
                <i
                  className="text-warning fs-2 bi bi-chevron-right me-8"
                  onClick={toggleArrowButtons}
                ></i>
              </div>
            </div>

            <div className="conatiner d-flex justify-content-between mt-5">
              <div>
                <h5>Debit Card</h5>
                <p>9875******985</p>
              </div>
              <div className="mt-1">
                <i
                  className="text-warning fs-2 bi bi-chevron-right me-8"
                  onClick={toggleArrowButtons}
                ></i>
              </div>
            </div>

            <div className="conatiner d-flex justify-content-between mt-5">
              <div>
                <h5>Credit card</h5>
                <p>886*********44</p>
              </div>
              <div className="mt-1">
                <i
                  className="text-warning fs-2 bi bi-chevron-right me-8"
                  onClick={toggleArrowButtons}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
