import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export default function PasswordSuccessfull() {
  return (
    <div className=''data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">

    <div  className='container mt-20  col-log-lg-12'> 
          <div className='d-flex justify-content-center align-items-center mt-5'>
      <div > 
         <img src={toAbsoluteUrl('/media/logos/tick.png')} alt='' height={300} width={300}/><br/>
          <h4 className='fs-bolder mt-5 ms-20'>Password successful</h4>
      </div>
      </div>
      <div className='d-flex justify-content-center align-items-center col-lg-12'>  
        <Link to="/security"> <button className='btn btn-primary  m-6 '>Security</button></Link>
        <Link to="/"> <button className='btn btn-primary  m-6 '>Home</button></Link>


      </div>
    </div>
  </div>
  )
}
