import Axios from 'axios'
const initialiseInterceptor = () => {
  Axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('access_token')}`

  // Add a request interceptor
  Axios.interceptors.request.use(
    (config) => {
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  // Add a response interceptor
  Axios.interceptors.response.use(
    (response) => {
     return response
    },
    (error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        localStorage.clear()
        setTimeout(() => {
          if (window.location.pathname !== "/auth") {
            window.location.replace("/auth");
          }
          // window.location.replace("/auth");
        }, 2000);
        return Promise.reject(error);
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export default initialiseInterceptor