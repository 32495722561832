/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import constant from "../Constant";
import api from "../api";

export default {
    GET_LINKED_ACCOUNT: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.AccountLink.GET_LINKED_ACCOUNT()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    }); 
  },
  GET_BY_ACCOUNT_NUMBER: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.AccountLink.GET_BY_ACCOUNT_NUMBER(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

};