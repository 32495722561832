import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from "../../scss/Card.module.scss"
import Services from '../../Services';

export default function ConfirmFundPrimary() {

  const [primaryConfirmDetials, setPrimarytConfirmDetials] = useState([])

  const Get_Confirm_Primary_fund_detials = async (id) => {
    try {
      const response: any = await Services.GsAccount.GET_CONFIRM_DETIALS(id);
      setPrimarytConfirmDetials(response.data.data);
    } catch (error) { }
  };
  useEffect(() => {

  }, []);
  return (
    <div className='' data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500">
      <div className='d-flex'>
        <div>
          <Link to='/fundaccount'><i className="fs-1 bi bi-arrow-left-circle-fill"></i></Link>
        </div>
        <h2 className={styles.head}>Confirm</h2>
      </div>


      <div className='bg-white mt-10 shadow-sm p-4 mb-5 bg-body-tertiary rounded'>
        <p className='text-center mt-10 fs-3'>Please review the details and confirm your exchange</p>
        <div className='container col-lg-12'>
          <div className='d-flex justify-content-between align-items-start mt-20'>
            <div className=''> <span>To</span></div>


            <div className=''> <span className='fw-bolder'>Debit Card</span> <br />
              <span> 452145*********5464</span>
            </div>
          </div>

          <div className='d-flex justify-content-between align-items-start mt-10'>
            <div className=''> <span>From</span></div>


            <div className=''> <span className='fw-bolder'>GS Gold Account</span> <br />
              <span> 452145*********5464</span>
            </div>
          </div>

          <div className='d-flex justify-content-between align-items-start mt-10'>
            <div className=''> <span>Ammount</span></div>


            <div className=''> <span className='fw-bolder'>00.00</span> <br />
              <span> 00.00</span>
            </div>
          </div>



          <div className='  mt-20'>   <Link className='' to='/accountfundding'> <button className='btn btn-warning col-12 mb-10'>Finish Funding</button></Link></div>

        </div>
      </div>
    </div>


  )
}
