/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Services from "../../Services";

import Spinner from "../../Components/Spinner";
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers";
import { Dropdown1 } from "../../_metronic/partials";
import BusinessAddress from "../Setting/VerifyIdentity/BusinessAddress";
import Toster from "../../Components/Toster";
import Constant from "../../Constant";
import { boolean } from "yup";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

export default function EditProfileDetials() {
  let navigate = useNavigate();
  const [gsTag, setGsTag] = useState("");
  const [Email, setEmail] = useState("");
  const [Address, setAddress] = useState("");
  const [Phone, setPhone] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [BusinessAddress, setBusinessAddress] = useState("");
  const [BusinessWeb, setBusinessWeb] = useState("");

  const [isDisabled, setIsDisabled] = useState(true); ///set--true
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [emailError, setEmailError] = useState("");
  const [BusinessAddressError, setBusinessAddressError] = useState("");
  const [BusinessWebError, setBusinessWebError] = useState("");
  const [bussinessNameError, setBusinessNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [toggle,settoggle]=useState(true)

  const handleImageUpload = (e) => {
    setuploadButtonHide(true);
    const file = e.target.files[0];

    if (file) {
      // settoggle(false)
      setProfile(file);
    } else {
    }
  };
  const ImageUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (profile) {
      const formData = new FormData();
      formData.append("profile", profile);

      try {
        const response: any = await Services.Auth.PROFILE_UPLOAD(formData);
        if (response) {
          setProfile(response.data.image);
          setIsLoaded(true);
          // settoggle(true)

          setTimeout(() => {
            setToaster((prevState) => ({
              ...prevState,
              visiblity: "hide",
            }));


          }, 800);
          setToaster({
            type: "success",
            title: "Successfull",
            text: "Update detials successfully",
            visiblity: "show",
          });
          setuploadButtonHide(false);
        }
      } catch (error: any) {
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : "An unknown error occured",
          visiblity: "show",
        });
      }
    }
  };

  const [dob, setDob] = useState("");
  const [profile, setProfile] = useState<any>('');
  const [uploadButtonHide, setuploadButtonHide] = useState(false);

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const exceptThisSymbols = ["+", "-", "."];

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const [userData, setUserData] = useState([]);

  const GET_USER = async () => {
    try {
      const response: any = await Services.User.GET_USER();
      setGsTag(response.data.data.gsTag);
      setEmail(response.data.data.email);
      setFirstName(response.data.data.firstName);
      setLastName(response.data.data.lastName);
      setBusinessName(response.data.data.businessName);
      setDob(response.data.data.dob);
      setProfile(response.data.data.profile);
      setPhone(response.data.data.phoneNumber);
      setAddress(response.data.data.address);
      setBusinessAddress(response.data.data.businessAddress);
      setBusinessWeb(response.data.data.businessWebsite);

      setUserData(response.data.data);
    } catch (error) { }
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      let data = {
        // email: Email,
        businessName: businessName,
        businessAddress: BusinessAddress,
        businessWebsite: BusinessWeb,
        // password: password.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        // phoneNumber: Phone,
        gsTag: gsTag,
        address: Address,
        profile: profile ? profile : '',
        dob: dob,
      };
      // let id:any

      const response = await Services.User.UPDATE_USER_BY_ID(data);
      setIsLoaded(true);
      setTimeout(() => {
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 500);

        navigate("/profile");
        // alert("jjjjjjjjjj")
      }, 500);
      setToaster({
        type: "success",
        title: "Successfull",
        text: "Update detials successfully",
        visiblity: "show",
      });

      // if (response) {
      //   setIsLoaded(true);
      //   setTimeout(() => {
      //     setToaster({
      //       type: "success",
      //       title: "Successfull",
      //       text: "Update detials successfully",
      //       visiblity: "show",
      //     });
      //     navigate("/profile");
      //   }, 1500);
      // }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  useEffect(() => {
    GET_USER();
  }, []);

  return (
    <div className=" bg-white  col-12  " data-aos="fade-left">
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      {!isLoaded && <Spinner />}
      {/* <h2 className=" text-center ">Profile Details</h2> */}
      <div className="card  ">
        {/* <div className="col-md-4 mt-4">
                            {categoryImage && (
                              // eslint-disable-next-line jsx-a11y/img-redundant-alt
                              <img
                                 src={URL.createObjectURL(categoryImage)}
                                alt={`Uploaded Image `}
                                className="uploaded-image"
                                height={120}
                                width={120}
                                style={{borderRadius:"5px"}}
                              />
                            )}
                            <br/>
                            {categoryImage && (
                              <>
                                {" "}
                                <button
                                  className="btn btn-primary"
                                //   onClick={uploadCategoryImage}
                                  style={{
                                    position: "absolute",
                                  }}
                                >
                                  upload
                                </button>
                              </>
                            )}
                            &nbsp;&nbsp;&nbsp;
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Category Image
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              className="form-control"
                              id="defaultFormControlInput"
                              placeholder="Enter Value"
                              aria-describedby="defaultFormControlHelp"
                              onChange={handleImageUpload}
                            />
                          </div> */}
        <div id="kt_account_profile_details" className="collapse show">
          <form noValidate className="form">
            <div className="card-body border-top p-9">
              <div className="row mb-6 ">
                <div className="col-lg-12 container d-flex justify-content-center align-items-center  ">
                  <div
                    className="image-input image-input-outline "
                    data-kt-image-input="true"
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(profile)})`,
                    }}
                  >
                    <div
                      className="image-input-wrapper w-120px h-100px"
                      style={{
                        borderRadius: "50%",
                      }}
                    >
                      {profile ? (
                        <div className="position-relative">
                          <img
                            src={
                              profile instanceof Blob
                                ? URL.createObjectURL(profile)
                                : `${Constant.BASE_URL_UPLOADS}${profile}`
                            }
                            crossOrigin="anonymous"
                            alt={`Uploaded Image`}
                            className="uploaded-image position-relative"
                            height={110}
                            width={110}
                            style={{ borderRadius: "50%" }}
                          />
                          <label
                            className="float-end"
                            data-action="change"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Change avatar"
                          >
                            <i
                              className="fa fa-pen icon-sm position-absolute translate-middle p-1 radius-full"
                              style={{
                                bottom: "2px",
                                right: "0px",
                                background: "black",
                                color: "white",
                                borderRadius: "50%",
                                padding: "2px",
                              }}
                            >
                              <input
                                id="fileInput"
                                className="form-control d-none"
                                type="file"
                                placeholder="Choose File"
                                onChange={handleImageUpload}
                              />
                            </i>
                          </label>
                        </div>
                      ) : (
                        <div className="position-relative">
                          <img
                            src={toAbsoluteUrl('/media/logos/userimage.jpeg')} // Replace with the URL of your default profile image
                            alt="Default Profile Image"
                            height={110}
                            width={110}
                            style={{ borderRadius: "50%" }}
                          />

                          <label
                            className="float-end"
                            data-action="change"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Change avatar"
                          >
                            <i
                              className="fa fa-pen icon-sm position-absolute translate-middle p-1 radius-full"
                              style={{
                                bottom: "2px",
                                right: "0px",
                                background: "black",
                                color: "white",
                                borderRadius: "50%",
                                padding: "2px",
                              }}
                            >
                              <input
                                id="fileInput"
                                className="form-control d-none"
                                type="file"
                                placeholder="Choose File"
                                onChange={handleImageUpload}
                              />
                            </i>
                          </label>
                        </div>
                      )}
                    </div>

                    {uploadButtonHide && (
                    <div className="mt-6 ms-9 position-absolute ">
                      <button
                        className=" bg-primary border border-0 rounded fs-6 bg-light"
                        // style={{width:"10px"}}
                        disabled={!profile}
                        onClick={ImageUpload}
                      >
                        Upload
                      </button>
                    </div>
                  )}
                    <label className=" fw-bold fs-3 text-center ms-6 mt-13">
                      My Profile
                    </label>
                  </div>

                
                </div>
              </div>

              <div className="container">
                {" "}
                <div className="col-12">
                  {/* <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Full Name
                </label> */}

                  <div className="col-lg-12 ">
                    <div className="row d-flex justify-content-between">
                      <div className="col-lg-5 fv-row ">
                        <label className="  fw-bold fs-6">
                          <span className="required">First Name</span>
                        </label>
                        {/* <span>First Name</span> */}
                        <input
                          type="text"
                          className="form-control form-control-lg  mb-3 mb-lg-0"
                          placeholder="First name"
                          value={firstName}
                          onChange={(e) => {
                            if (e.target.value.trim() === "") {
                              setIsDisabled(true);

                              setFirstNameError("Requierd");
                            }
                            // else if(e.target.value >10){
                            //   setIsDisabled(true);

                            //   setFirstNameError("Requierd");
                            // }
                            else {
                              setIsDisabled(false);
                              setFirstName(e.target.value.trim());
                              setFirstNameError("");
                            }
                            setFirstName(e.target.value.trim());
                          }}
                        />
                        {!firstName && (
                          <span className="position-absolute alert-danger  text-capitalize">
                            {firstNameError}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-5  fv-row">
                        <label className="  fw-bold fs-6">
                          <span className="required">Last Name</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg "
                          placeholder="Last name"
                          value={lastName}
                          onChange={(e) => {
                            if (e.target.value.trim() === "") {
                              setIsDisabled(true);

                              setLastNameError("Requierd");
                            } else {
                              setIsDisabled(false);
                              setLastName(e.target.value.trim());
                              setLastNameError("");
                            }
                            setLastName(e.target.value.trim());
                          }}
                        />
                        {!lastName && (
                          <span className="position-absolute alert-danger  text-capitalize">
                            {lastNameError}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row mt-6 d-flex justify-content-between">
                      <div className="col-lg-5 fv-row">
                        <label className="  fw-bold fs-6">
                          <span className="required">Gs Tag</span>
                        </label>
                        <input
                          className="form-control form-control-lg "
                          type="number"
                          name="Gs Tag"
                          value={gsTag}
                          disabled
                        //   onChange={(e) => {
                        //     if (e.target.value === "") {
                        //       setIsDisabled(true);
                        //       setGsTagError("Requierd");
                        //     } else {
                        //       setIsDisabled(false);
                        //       setGsTag(e.target.value);
                        //       setGsTagError("");
                        //     }
                        //     setGsTag(e.target.value);
                        //   }}
                        //   onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        />
                        {/* {formik.touched.contactPhone && formik.errors.contactPhone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.contactPhone}</div>
                  </div>
                )} */}
                      </div>

                      <div className="col-lg-5 fv-row">
                        <label className="  fw-bold fs-6">
                          <span className="required">Business Address</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg f"
                          placeholder="Business website"
                          value={BusinessAddress}
                          onChange={(e) => {
                            if (e.target.value.trim() === "") {
                              setIsDisabled(true);

                              setBusinessAddressError("Requierd");
                            } else {
                              setIsDisabled(false);
                              setBusinessAddress(e.target.value.trim());
                              setBusinessAddressError("");
                            }
                            setBusinessAddress(e.target.value.trim());
                          }}
                        />
                        {!BusinessAddress && (
                          <span className="position-absolute alert-danger  text-capitalize">
                            {BusinessAddressError}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row mt-8 d-flex justify-content-between">
                      <div className="col-lg-5 fv-row">
                        <label className="  fw-bold fs-6">
                          <span className="required">Business Website</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg f"
                          placeholder="Business website"
                          value={BusinessWeb}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              setIsDisabled(true);

                              setBusinessWebError("Requierd");
                            } else {
                              setIsDisabled(false);
                              setBusinessWeb(e.target.value.trim());
                              setBusinessWebError("");
                            }
                            setBusinessWeb(e.target.value.trim());
                          }}
                        />
                        {!BusinessWeb && (
                          <span className="position-absolute alert-danger  text-capitalize">
                            {BusinessWebError}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-5 fv-row">
                        <label className="  fw-bold fs-6">
                          <span className="required"> Business Name</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg f"
                          placeholder="Business website"
                          value={businessName}
                          onChange={(e) => {
                            if (e.target.value.trim() === "") {
                              setIsDisabled(true);

                              setBusinessNameError("Requierd");
                            } else {
                              setIsDisabled(false);
                              setBusinessName(e.target.value.trim());
                              setBusinessNameError("");
                            }
                            setBusinessName(e.target.value.trim());
                          }}
                        />
                        {!businessName && (
                          <span className="position-absolute alert-danger  text-capitalize">
                            {bussinessNameError}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row mt-8 d-flex justify-content-between">
                      <div className="col-lg-5 fv-row">
                        <label className="  fw-bold fs-6">
                          <span className="required"> Email</span>
                        </label>
                        <input
                          className="form-control form-control-lg f"
                          type="email"
                          name="Email"
                          value={Email}
                          // onChange={(e) => {
                          //   if (e.target.value.trim() === "") {
                          //     setIsDisabled(true);

                          //     setEmailError("Requierd");
                          //   } else if (!validateEmail(e.target.value.trim())) {
                          //     setIsDisabled(true);
                          //     setEmailError("Enter a valid email address");
                          //   } else {
                          //     setIsDisabled(false);
                          //     setEmail(e.target.value.trim());
                          //     setEmailError("");
                          //   }
                          //   setEmail(e.target.value.trim());
                          // }}
                          // onKeyDown={(e) =>
                          //   exceptThisSymbols.includes(e.key) &&
                          //   e.preventDefault()
                          // }
                          disabled
                        />
                        {/* {formik.touched.contactPhone && formik.errors.contactPhone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.contactPhone}</div>
                  </div>
                )} */}
                      </div>

                      <div className="col-lg-5 fv-row">
                        <label className="  fw-bold fs-6">
                          <span className="required"> Address</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg f"
                          placeholder="Address"
                          value={Address}
                          onChange={(e) => {
                            if (e.target.value.trim() === "") {
                              setIsDisabled(true);

                              setAddressError("Requierd");
                            } else {
                              setIsDisabled(false);
                              setAddress(e.target.value.trim());
                              setAddressError("");
                            }
                            setAddress(e.target.value.trim());
                          }}
                        />
                        {!Address && (
                          <span className="position-absolute alert-danger  text-capitalize">
                            {addressError}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row mt-8 d-flex justify-content-between">
                      <div className="col-lg-5 fv-row ">
                        <label className="  fw-bold fs-6">
                          <span className="required"> Date of Birth</span>
                        </label>
                        <input
                          type="date"
                          className="form-control form-control-lg f"
                          placeholder="Company website"
                          value={dob}
                          onChange={(e) => {
                            if (e.target.value.trim() === "") {
                              setIsDisabled(true);

                              // setFistNameError("Requierd");
                            } else {
                              setIsDisabled(false);
                              setDob(e.target.value);
                              // setEmailError("");
                            }
                            setDob(e.target.value);
                          }}
                        />
                        {/* {formik.touched.contactPhone && formik.errors.contactPhone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.contactPhone}</div>
                  </div>
                )} */}
                      </div>

                      <div className="col-lg-5 fv-row">
                        <label className="  fw-bold fs-6">
                          <span className="required"> Phone Number</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg f"
                          placeholder="Phone Number"
                          value={Phone}
                          disabled
                        //   {...formik.getFieldProps('companySite')}
                        />

                        {/* {formik.touched.lName && formik.errors.lName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lName}</div>
                      </div>
                    )} */}
                      </div>
                    </div>
                    <div className="row  d-flex justify-content-between p-8">
                      <button
                        disabled={
                          !(
                            gsTag &&
                            firstName &&
                            lastName &&
                            Email &&
                            Phone &&
                            Address &&
                            businessName &&
                            BusinessAddress &&
                            BusinessWeb &&
                            // toggle &&

                            dob
                          )
                        }
                        className="btn btn-primary me-2  col-11"
                        onClick={handleSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9"></div>
          </form>
        </div>
      </div>
    </div>
  );
}
