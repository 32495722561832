/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {HeaderToolbar} from './HeaderToolbar'
import { AdminHeaderToolbar } from './AdminHeader/AdminHeaderToolbar '

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {aside} = config

  const role = sessionStorage.getItem("role");

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      {/* begin::Brand */}
      <div className='header-brand'>
        {/* begin::Logo */}
        <Link to='/' className='d-flex  mt-2'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/gsreference.jpg')}
            className='h-25px h-lg-25px'
          />
          <h3 className='text-white ms-2 mt-1'>GS App</h3>
        </Link>
        {/* end::Logo */}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTIcon iconName='exit-left' className='fs-1 me-n1 minimize-default' />
            <KTIcon iconName='entrance-left' className='fs-1 minimize-active' />
          </div>
        )}

        {/* begin::Aside toggle */}
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <KTIcon iconName='abstract-14' className='fs-1' />
          </div>
        </div>
        {/* end::Aside toggle */}
      </div>
      {/* end::Brand */}
      {role === "User" ? (
       <HeaderToolbar /> 
       ) : role === "Admin" ? (
        <AdminHeaderToolbar />
        ) : null}
        
      {/* <h1 className='mt-10 ms-10 '>User Details </h1> */}
    </div>
  )
}
