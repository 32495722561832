import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../_metronic/helpers'

export default function Setting() {
  return (
<div data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
      <h2 className='text-center'>Settings</h2>

<div className=' bg-white container mt-6 shadow-sm p-3 mb-5 bg-body-tertiary rounded'>
      <div className='container mt-10 ' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
      <h2>Good day GS Tag</h2>
      
      <div className='mt-10 d-flex justify-content-sm-between'>
        <h6>Account Verified </h6>
        <i className="text-warning fs-2 bi bi-check2-circle"></i>
        
      </div>
      
      <div className='mt-5 d-flex justify-content-sm-between'>
        <h6>Email Address
        </h6>
        <i className="text-warning fs-2 bi bi-check2-circle"></i>
      </div>
      
      <div className='mt-5 d-flex justify-content-sm-between'>
        <h6>Phone number
       </h6>
       <i className="text-warning fs-2 bi bi-check2-circle"></i>
      </div>
      </div>

      <div className='p-5'data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
        <Link to='/profile'><button className='btn btn-warning col-lg-12 mt-10 '>Update GS profile</button></Link>
        
      </div>
    
      </div>
      <div className='d-flex justify-content-start ms-4 m-10 ms-8' data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
        <div className='  col-lg-12  ' >
          
          <div className='conatiner d-flex justify-content-between mt-5'>
            <div ><h5>Account Setting</h5>
            </div>
            <div className='mt-1'>
              <Link to='/accountsetting'> 
              <i className="text-warning fs-2 bi bi-chevron-right"></i>
              {/* <img src={toAbsoluteUrl('/media/icons/graterthenicon.png')} alt='' height={30} width={30}/> */}
              </Link>
             
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
            <div ><h5>Verify your identity</h5>
            </div>
            <div className='mt-1'>
            <Link to='/verifyidentity'> <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </Link>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
            <div ><h5>Security & Privacy</h5>
              </div>
            <div className='mt-1'>
              <Link to='/security'><i className="text-warning fs-2 bi bi-chevron-right"></i></Link>
              
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
            <div ><h5>Refer a friend</h5>
             </div>
            <div className='mt-1'>
            <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
            <div >
              <Link to='/logoutgs'><h5 className='text-warning' >Log out</h5></Link>
              
             </div>
            {/* <div className='mt-1'>
              <img src='https://img.icons8.com/?size=1x&id=86520&format=png' height={30} width={30} alt='' />
            </div> */}
          </div>
        </div>
      </div>
     </div>

  )
}