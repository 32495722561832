import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../Services";
import Toster from "../Components/Toster";
import Spinner from "../Components/Spinner";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

 function Support() {
  let navigate = useNavigate();
  const [tittle, setTittle] = useState("");
  const [subject, setSubject] = useState("");
 
  const [tittleError, setTittleError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true); ///set--true
  
 
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const exceptThisSymbols = ["~"];
  let data:any
  let text:any



  const [userData, setUserData] = useState([]);
  console.log("dataaaaaaaaaaaaaaaaaaaUser-----------", userData);


  
  // const createsupport = async (payload:any) => {
  //   try {
  //     const response: any = await Services.Support.CREATE_SUPPORT(payload)
      
  //    setTittle(response.data.data.tittle)
  //    setSubject(response.data.data.subject)
      
  //     // console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk==============",response.data.data.address);
  //     setUserData(response.data.data);
  //   } catch (error) {}
  // };

  // const payload ={
  //   data,
  //   type:'support'
  // }

  // createsupport(payload)



  const handleSubmit = async () => {
   setIsLoaded(true)
      try {

        let data = {
        
          // password: password.trim(),
          // firstName:fName.trim(),
          // lastName:lName.trim(),
          tittle:tittle,
           subject:subject,
          // image:imageData,
          // dob:date
        };

      const response:any  = await Services.Support.CREATE_SUPPORT(data);
      console.log("responseeeeeeeeeeeeeeeeeeeee",response)
      if (response) {
        setToaster({
          type: "success",
          title: "Successfull",
          text: "Done Successfully",
          visiblity: "show",
        });
        setTimeout(() => {
          navigate("/wallet");
        }, 700);
      }
    }catch (error) {
      // setToaster({
      //   type: "danger",
      //   title: "Error Occured",
      //   text: error.response.data.message,
      //   visiblity: "show",
      // });
      // setTimeout(() => {
      //   setToaster({
      //     visiblity: "hide",
      //   });
      // }, 1500);
      // setToaster({
      //   type: "danger",
      //   title: "Error Occured",
      //   text: error?.response?.data?.message
      //     ? error.response.data.message
      //     : error?.message
      //     ? error.message
      //     : "An unknown error has occured, try again later",
      //   visiblity: "show",
      // });
      setTimeout(() => {
        // setToaster({
        //   visiblity: "hide",
        // });
      }, 1500);

      
    }
    
  }

  
  return (
    <div>
       <div className="container mt-20 bg-white p-5 col-6 shadow p-3 mb-5 bg-body-tertiary rounded  mt-10"
      data-aos="fade-left">
    
    <h2 className=" text-center">Support</h2>

    <div
      className=""
    >
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      {!isLoaded && <Spinner />}

      <div className="">
        <div id="kt_app_content" className="">
          <div
            id="kt_app_content_container"
            className=""
          >
            <div className="card">
              <div>
                <div className=" align-items-center position-relative my-1">
                  <span className="svg-icon svg-icon-1 position-absolute ms-6"></span>
                  {/* <h1 className="fs-3 m-10">Master Card Detail</h1> */}

                  <div>
                    <div className="row align-items-end m-10">
                    <div className="mt-6 ">
                        <label htmlFor="type" className="form-label">
                          Tittle
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="Address"
                          value={tittle}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              setIsDisabled(true);
                              setTittleError("Requierd");
                            } else {
                              setIsDisabled(false);
                              setTittle(e.target.value);
                              setTittleError("");
                            }
                            setTittle(e.target.value);
                          }}
                          onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        />
                        {!tittle && (
                          <span className="position-absolute alert-danger  text-capitalize">
                            {tittleError}
                          </span>
                        )}
                      </div>
               
                

                      <div className="mt-6 ">
                        <label htmlFor="type" className="form-label">
                          Subject
                        </label>
                        <textarea
                        //   type="text"
                          className="form-control"
                          name="Address"
                          value={subject}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              setIsDisabled(true);
                              setSubject("Requierd");
                            } else {
                              setIsDisabled(false);
                              setSubject(e.target.value);
                              setSubjectError("");
                            }
                            setSubject(e.target.value);
                          }}
                          onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        />
                        {!subject && (
                          <span className="position-absolute alert-danger  text-capitalize">
                            {subjectError}
                          </span>
                        )}
                       
                      </div>
                      <div className="col-12  py-5 mt-4">
                          <button
                            disabled={!( tittle && subject)}
                            className="btn btn-primary me-2 col-12"
                            onClick={() => handleSubmit()}
                          >
                           Done
                          </button>
                        </div>
               
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
  
    
                        }                     
                  

export default Support;
