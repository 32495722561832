import { Link } from 'react-router-dom'
import {useAuth} from '../../../../../app/modules/auth'
import {KTIcon, toAbsoluteUrl} from '../../../../helpers'
import {HeaderUserMenu} from '../../../../partials'
import Constant from '../../../../../Constant'
import { useEffect, useState } from 'react'
import Services from '../../../../../Services'

/* eslint-disable jsx-a11y/anchor-is-valid */
const AdminAsideToolbar = () => {
  const {currentUser, logout} = useAuth()

  const [profile,setProfile]=useState('')
  const [firstName,setFirstName]=useState('')
  const [lastName,setLastName]=useState('')

  // const GET_USER = async () => {
  //   try {
  //     const response: any = await Services.User.GET_USER();
  //     // setGsTag(response.data.data.gsTag);
  //     // setEmail(response.data.data.email);
  //     setFirstName(response.data.data.firstName)
  //     setLastName(response.data.data.lastName)
  //     // setBusinessName(response.data.data.businessName)
  //     // setDob(response.data.data.dob)
  //     setProfile(response.data.data.profile)
  //     // setPhone(response.data.data.phoneNumber);
  //     // setAddress(response.data.data.address);
  //     // setBusinessAddress(response.data.data.businessAddress);
  //     // setBusinessWeb(response.data.data.businessWebsite);

  //     // console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk==============",response.data.data.address);
  //     // setUserData(response.data.data);
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   GET_USER();
  // }, []);

  return (
    <>
      {/*begin::User*/}
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
        {/*begin::Symbol*/}
        <div className='symbol symbol-50px'>
          <img  crossOrigin="anonymous"
            src={`${Constant.BASE_URL_UPLOADS}${profile}`} alt="" />
          {/* <img src={toAbsoluteUrl('/media/logos/300-2.jpg')} alt='' /> */}
        </div>
        {/* <div className='symbol symbol-50px'>
          <img src={toAbsoluteUrl('/media/logos/300-2.jpg')} alt='' />
        </div> */}
        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
          {/*begin::Section*/}
          <div className='d-flex'>
            {/*begin::Info*/}
            <div className='flex-grow-1 me-2 mt-3'>
              {/*begin::Username*/}

              <Link to='#' className='text-white text-hover-primary fs-6 fw-bold '>
                      <p>Hey' {firstName} &nbsp; {lastName} wellcome</p>
              </Link>
              {/* <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>wellcome</span> */}
              {/*end::Username*/}

              {/*begin::Description*/}
              {/* <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>wellcome</span> */}
              {/*end::Description*/}

              {/*begin::Label*/}
              {/* <div className='d-flex align-items-center text-success fs-9'>
                <span className='bullet bullet-dot bg-success me-1'></span>online
              </div> */}
              {/*end::Label*/}
            </div>
            {/*end::Info*/}

            {/*begin::User menu*/}
            {/* <div className='me-n2'>
             
              <Link
                to='#'
                className='btn btn-icon btn-sm btn-active-color-primary mt-n2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-overflow='false'
              >
                <KTIcon iconName='setting-2' className='text-muted fs-1' />
              </Link>

              <HeaderUserMenu />
            
            </div> */}
            {/*end::User menu*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}

      {/*begin::Aside search*/}
      {/* <div className='aside-search py-5'>
        <?php Theme::getView('partials/search/_inline', array(
        'class' => 'w-100',
        'menu-placement' => 'bottom-start',
        'responsive' => 'false'
    ))?>
        <Search />
      </div> */}
    
    </>
  )
}

export {AdminAsideToolbar}
