import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Services from "../../Services";
import Toster from "../../Components/Toster";
import Spinner from "../../Components/Spinner";
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers";

import Constant from "../../Constant";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

export default function Profile() {
  let navigate = useNavigate();
  const [gsTag, setGsTag] = useState("");
  const [Email, setEmail] = useState("");
  const [Address, setAddress] = useState("");
  const [Phone, setPhone] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [firstName,setFirstName]=useState('')
  const [lastName,setLastName]=useState('')
  const [businessName,setBusinessName]=useState('')
  const [BusinessAddress,setBusinessAddress]=useState('')
  const [BusinessWeb,setBusinessWeb]=useState('')


  const [dob,setDob]=useState('')
  const [profile,setProfile]=useState('')

 
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const exceptThisSymbols = [ "+", "-","."];

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const [userData, setUserData] = useState([]);


  
  const GET_USER = async () => {
    try {
      const response: any = await Services.User.GET_USER();
      setGsTag(response.data.data.gsTag);
      setEmail(response.data.data.email);
      setFirstName(response.data.data.firstName)
      setLastName(response.data.data.lastName)
      setBusinessName(response.data.data.businessName)
      setDob(response.data.data.dob)
      setProfile(response.data.data.profile)
      setPhone(response.data.data.phoneNumber);
      setAddress(response.data.data.address);
      setBusinessAddress(response.data.data.businessAddress);
      setBusinessWeb(response.data.data.businessWebsite);

      setUserData(response.data.data);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      let data = {
        email: Email,
        // password: password.trim(),
        // firstName:fName.trim(),
        // lastName:lName.trim(),
        phoneNumber: Phone,
        gsTag: gsTag,
        address: Address,
        // image:imageData,
        // dob:date
      };


      const response = await Services.User.UPDATE_USER(data);

      if (response) {
       
        setIsLoaded(true);
        setTimeout(() => {
          navigate("/wallet");
        }, 700);
        setToaster({
          type: "success",
          title: "Successfull",
          text: "Update detials successfully",
          visiblity: "show",
        });
      }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  
 
  useEffect(() => {
    GET_USER();
  }, []);


  

 
  return (
    <div className="container">
    
    
    


      <div className='card  '>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-60px symbol-fixed position-relative'>
            {profile ? (
              <img 
              crossOrigin="anonymous"
            src={`${Constant.BASE_URL_UPLOADS}${profile}`}
           
               alt='Gold Send' />
               ) : (   
              
               <img
                 src={toAbsoluteUrl('/media/logos/userimage.jpeg')}
                 
                 alt="Default Profile Image"
             
               />
         
        
            
          )} 
            
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column '>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 text-nowrap'>
                 
                    {firstName} &nbsp;{lastName}
                    {/* {firstName.slice(0,10)} &nbsp;{lastName.slice(0,10)} */}
                  </a>
                  
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 overflow-auto'>
                 
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                   {gsTag}
                  </a>
                </div>
              </div>


              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div>
              
            </div>

           
          </div>
        </div>

      
      </div>
    </div>


    <div className='card-body p-9'>
          <div className='row mb-7'>

          <label className=' col-lg-4 fw-bold text-muted'>Full Name</label>
           

           <div className='col-lg-8 text-nowrap'>
             <span className='fw-bolder fs-6 text-dark  '> {firstName.substring(0, 10)} &nbsp;{lastName.substring(0, 10)}</span>
           </div>

            
          </div>

          <div className='row mb-7'>
          <label className=' col-lg-4 fw-bold text-muted'>Business Name</label>

<div className=' col-lg-8 fv-row text-nowrap' >
  <span className='fw-bold fs-6'>{businessName.substring(0, 15)}</span>
</div>
         
          </div>

          <div className='row mb-7'>
       
          <label className=' col-lg-4 fw-bold text-muted'>Business Address</label>

<div className=' col-lg-8 fv-row text-nowrap' >
  <span className='fw-bold fs-6'>{BusinessAddress.substring(0, 15)}</span>
</div>
           
          </div>

          <div className='row mb-7'>
          <label className=' col-lg-4 fw-bold text-muted'>Bussiness Website</label>

<div className=' col-lg-8 fv-row text-nowrap' >
  <span className='fw-bold fs-6'>{BusinessWeb.substring(0, 15)}</span>
</div>
           
          </div>

          <div className='row mb-7'>
          <label className=' col-lg-4 fw-bold text-muted'>Email</label>

<div className=' col-lg-8 fv-row text-nowrap' >
  <span className='fw-bold fs-6'>{Email}</span>
</div>
          
          </div>

          <div className='row mb-7'>
          <label className=' col-lg-4 fw-bold text-muted'>Address</label>

<div className=' col-lg-4 fv-row text-break' >
  <span className='fw-bold fs-6  '>{Address.substring(0, 15)}</span>
</div>
           
          </div>

          <div className='row mb-7'>
          <label className=' col-lg-4 fw-bold text-muted'>Date of Birth</label>

<div className=' col-lg-8 fv-row text-nowrap' >
  <span className='fw-bold fs-6'>{dob}</span>
</div>
         
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Contact Phone
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center text-nowrap'>
              <span className='fw-bolder fs-6 me-2'>{Phone}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>GS Tag</label>

            <div className='col-lg-8 text-nowrap'>
            <span className='fw-bolder fs-6 me-2'>{gsTag}</span>
            </div>
          </div>

          <div className='row' id='kt_profile_details_view'>  <Link to='/editprofiledetials' className='btn btn-primary align-self-center col-12'>
            Edit Profile
          </Link></div>
       
        </div>

  

   




    
    </div>
  );
}
