/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useLayout } from "../../core";
// import {KTIcon} from '../../../helpers'
// import {AsideMenu} from './AsideMenu'
import { AdminAsideMenu } from "./AdminAsideBar/AdminjAsideMenu";
import { AdminAsideToolbar } from "../aside/AdminAsideBar/AdminAsideToolBar";
import { AsideMenu } from "./AsideMenu";
import { AsideToolbar } from "./AsideToolbar";


const AsideDefault: FC = () => {
  const { classes } = useLayout();

  //     const  handlerole =()=>{
  //       const data = sessionStorage.getItem("role");

  //     if(data==="admin"){
  //       <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
  //     }
  //     else
  //     {
  //       <AdminAsideMenu asideMenuCSSClasses={classes.asideMenu} />
  //     }
  // }

  const role = sessionStorage.getItem("role");

  return (
    <div
      id="kt_aside"
      className="aside"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      {/* begin::Aside Toolbarl */}
      <div className="aside-toolbar flex-column-auto" id="kt_aside_toolbar">
      {role === "User" ? (
        <AsideToolbar />
        ) : role === "Admin" ? (
        <AdminAsideToolbar />
        ) : null}
      </div>

      <div className="aside-menu flex-column-fluid">
        {/* //  onChange={()=>handlerole()}> */}
      
        {role === "User" ? (
          <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
        ) : role === "Admin" ? (
          <AdminAsideMenu asideMenuCSSClasses={classes.asideMenu} />
        ) : null}

        {/* <AsideMenu asideMenuCSSClasses={classes.asideMenu} />

      <AdminAsideMenu asideMenuCSSClasses={classes.asideMenu} />
           */}
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}

      {/* end::Footer */}
    </div>
  );
};

export { AsideDefault };
