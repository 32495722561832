// import imgicon from '../../../../assets/walleticon.png'
// /* eslint-disable jsx-a11y/anchor-is-valid */
// import {useEffect} from 'react'
// import noUiSlider, {target} from 'nouislider'
// import {useLayout} from '../../core'
// // import {KTIcon} from '../../../helpers'
// import {DefaultTitle} from './page-title/DefaultTitle'
// import {ThemeModeSwitcher} from '../../../partials'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../helpers'



const AdminHeaderToolbar  = () => {



   return (
    <div>
      <div>


    
   

    <div className="d-flex p-7">
     <div>
      <h1 className=''>
        
          Admin Pannel
    
      </h1 >
      </div>
      <div>
     <i className="ms-5 fs-1 text-dark bi bi-person-fill-gear"></i>
     </div>
    </div>
  </div>

    </div>
    

 )
 }

 export {AdminHeaderToolbar }
