import React from 'react'

function UpdateSupport() {
  return (
    <div>
      
    </div>
  )
}

export default UpdateSupport
