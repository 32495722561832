import React from 'react'
import { Link } from 'react-router-dom'
import styles from "../../scss/Card.module.scss"

export default function ConfirmWithdraw() {
  return (
    <div data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500" >
    <h2 className='text-center'>Withdraw</h2>
    <div className='container'>
    {/* <h2 className=''>Wallet</h2> */}
 {/* <div className=''> */}
<div className='container mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded' > 
<div className='d-flex align-content-start p-5'> 
<div className='col-lg-12'> <h3>From  Account</h3>
<div className="mb-3">
        <select id="disabledSelect" className="form-select p-3 mb-2 text-gray fw-semibold ">
        <option className='fw-semibold '>Select From Account</option>
        <option className=''>item1</option>
        <option>item2</option>
        <option>item3</option>
        <option>item4</option>
        <option>item5</option>


      </select>
    </div>
    </div>
    </div>
  </div>
  <div className='container mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded' > 
<div className='d-flex align-content-start p-5'> 
<div className='col-lg-12'> <h3>To Account</h3>
<div className="mb-3">
<select id="disabledSelect" className="form-select p-3 mb-2 text-gray fw-semibold ">
        <option className='fw-semibold '>Select From Account</option>
        <option className=''>item1</option>
        <option>item2</option>
        <option>item3</option>
        <option>item4</option>
        <option>item5</option>


      </select>
    </div>
    </div>
    </div>
  </div>

  <div className='container mt-8 p-2 shadow-sm p-3 mb-5 bg-body-tertiary rounded' > 

<div className='col-lg-12 d-flex justify-content-between'> 
<span className='fw-bold'>Amount</span>
<span className='fw-semibold'>0.00</span>

   
    </div>
    <div className='grid gap-4 d-flex justify-content-between mt-10'> 
    <input type="number" name="" className='form-control ' id="" placeholder='$50'/>
    <input type="number" name="" className='form-control ms-2' id="" placeholder='$100'/>
    <input type="number" name="" className='form-control ms-2' id="" placeholder='$250' />

    </div>
   
  </div>
  <div className=''>
  <Link to='/confirmdetails'>
  <button className='btn btn-warning col-lg-12 mt-20  '  >Continue</button>
  </Link>
   
      </div>
 </div>

  </div>
  )
  //confirmwithdrawsuccessfull
//     <div data-aos="fade-left"
//     data-aos-anchor="#example-anchor"
//     data-aos-offset="500"
//     data-aos-duration="500">
//         <h2 className='text-center'>Withdraw Account</h2>
//         <div className='conatiner d-flex justify-content-between col-12 mt-5'>
//     <Link to='/setting'><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
//   </div>
//       <div>
//       {/* <h2 className=''>Wallet</h2> */}
//    {/* <div className=''> */}
// <div className='container mt-8 p-2 shadow-sm p-3 mb-5  rounded' > 
//   <div className='d-flex align-content-start p-5'> 
//   <div className='col-lg-11'> <h3>From  Account</h3>
//       <span>Select From Account</span></div>
//       </div>
//     </div>
//     <div className='container mt-8 p-2 shadow-sm p-3 mb-5  rounded' > 
//   <div className='d-flex align-content-start p-5'> 
//   <div className='col-lg-11'> <h3>To Account</h3>
//       <span>Select To Account</span></div>
//       </div>
//     </div>
//     <div className='container mt-8 p-2 shadow-sm p-3 mb-5  rounded' > 
//   <div className='d-flex align-content-start p-5'> 
//   <div className='col-lg-11 d-flex justify-content-between '> <b><span>Amount</span></b>
//       <span  className='mt-1' >0.00</span></div>
//       <br></br>
//       <br></br>
      
//       </div>
//       <div className='d-flex align-content-start p-5'>
//       <div className='col-lg-11 d-flex justify-content-between '>
//       <span >$50</span>
//       <span >$100</span>
//       <span >$250</span>
//       </div>
//       </div>
//     </div>
//    </div>
//    <div className=''>
//     <Link to="/confirmwithdrawsuccessfull">
//     <button className='btn btn-warning col-lg-2 mt-10'>Continue</button>
//     </Link>
       
//         </div>
//     </div>
  // )
}
