/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import Constant from "../Constant";
import api from "../api";


export default {
    GET_GS_CARD: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
              Constant.BASE_URL + api.GsAccount.GET_GS_CARD()
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
        // GET_CONFIRMDETIALS
      },
      GET_CONFIRM_DETIALS: (id) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
              Constant.BASE_URL + api.GsAccount.GET_CONFIRM_DETIALS(id)
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
        // GET_CONFIRMDETIALS
      },
      GET_GS_ACCOUNT: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
              Constant.BASE_URL + api.GsAccount.GET_GS_ACCOUNT()
            );
           const number= parseInt(response.data.gsAccount.accountNumber)
            sessionStorage.setItem('gsAccount',JSON.stringify(number))
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
      },
      ADD_FUND: (data) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.put(
              Constant.BASE_URL + api.GsAccount.ADD_FUND(),
              data
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        });
    //  ADD_FUND
      },
}

// GsAccount