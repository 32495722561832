/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import Services from "../../../../Services";
import Spinner from "../../../../Components/Spinner";
import Toaster from "../../../../Components/Toster";
import { Verify } from "crypto";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}
export function Registration() {
  let navigate = useNavigate();
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [phone, setphone] = useState("");
  const [verify, setVerify] = useState("");
  // const [gsTag, setGstag] = useState("");
  const [email, setEmail] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [businessWeb, setBusinessWeb] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [pointContactAddress, setPointContactAddress] = useState("");
  const [role, setRole] = useState("User");

  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");

  const [emailError, setEmailError] = useState("");

  const [bussinessAddressError, setBusinessAddressError] = useState("");
  const [bussinessNameError, setBusinessNameError] = useState("");
  const [bussinessWebError, setBusinessWebError] = useState("");
  const [fnameError, setfnameError] = useState("");
  const [LnameError, setLnameError] = useState("");
  const [pointError, setPointError] = useState("");
  const [addressError, setAddressError] = useState("");







  // const [gsTagError, setGsTagError] = useState("");
  const [phoneError, setphoneError] = useState("");
  const [verifyError, setVerifyError] = useState("");

  const exceptThisSymbols = ["+", "-", "*", "/", " "];

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const [isLoaded, setIsLoaded] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [isDisabled, setIsDisabled] = useState(true); ///set--true

  const [checkBox, setCheckbox] = useState(false);
  const [check, setCheck] = useState(false);

  const [storeData, setStoreData] = useState({});
  const [currentStep, setCurrentStep] = useState<number>(1);

  // const [imageData, setImageData] = useState("");

  const handleVerifyOtp = async () => {
    if (verify.length < 4 && verify.length > 0) {
      setVerifyError("please enter 4 digit opt");
    } else {
      setVerify(verify.trim());
      setVerifyError("");
    }
    // localStorage.removeItem("currentStep");
    // localStorage.removeItem("previousStep");

    try {
      let data = {
        // email: email.trim().toLocaleLowerCase(),
        // password: password.trim(),
        // firstName: fName.trim(),
        // lastName: lName.trim(),
        // phoneNumber: phone.trim(),
        otp: verify.trim(),
        // gsTag: getGSTag,
        // address: address.trim(),
        // dob: date,
        // role:role
      };
      const response = await Services.Auth.VERIFY_OTP(data);

      if (response) {
        setIsLoaded(true);
        localStorage.setItem("currentStep", (currentStep + 1).toString());
        // Move to the next step
        setCurrentStep(currentStep + 1);

        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        // setTimeout(() => {
        setToaster({
          type: "success",
          title: "Successfull",
          text: "Verify Otp successfully",
          visiblity: "show",
        });
      }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message // setIsDisabled(false);ge
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  const handleResendOtp = async () => {
    // localStorage.removeItem("currentStep");
    // localStorage.removeItem("previousStep");

    try {
      let data = {
        // email: email.trim().toLocaleLowerCase(),
        // password: password.trim(),
        // firstName: fName.trim(),
        // lastName: lName.trim(),
        phoneNumber: phone.trim(),
        // gsTag: getGSTag,
        // address: address.trim(),
        // dob: date,
        // role:role
      };
      const response = await Services.Auth.SEND_OTP(data);

      if (response) {
        setIsLoaded(true);
        // localStorage.setItem("currentStep", (currentStep + 1).toString());

        // Move to the next step
        // setCurrentStep(currentStep + 1);

        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        // setTimeout(() => {
        setToaster({
          type: "success",
          title: "Successfull",
          text: "Resend Otp successfully",
          visiblity: "show",
        });
        startTimer();
      }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message // setIsDisabled(false);ge
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  const handleSendOtp = async () => {
    localStorage.setItem("fname",(fName).toString())
    localStorage.setItem("lname",(lName).toString())

    localStorage.setItem("address",(address).toString())
    localStorage.setItem("phone",(phone).toString())
    localStorage.setItem("date",(date).toString())
    if (phone.length >= 7 && phone.length <= 13) {
      setphoneError("");

      try {
        let data = {
          // email: email.trim().toLocaleLowerCase(),
          // password: password.trim(),
          // firstName: fName.trim(),
          // lastName: lName.trim(),
          phoneNumber: phone.trim(),
          // gsTag: getGSTag,
          // address: address.trim(),
          // dob: date,
          // role:role
        };
        const response:any = await Services.Auth.SEND_OTP(data);
        setphone(response.data.data.phoneNumber)

        if (response) {
          setTimeout(() => {
            setToaster((prevState) => ({
              ...prevState,
              visiblity: "hide",
            }));
          }, 1500);

          // setTimeout(() => {
          setToaster({
            type: "success",
            title: "Successfull",
            text: "Send Otp successfully",
            visiblity: "show",
          });
          // }, 1500);
          setIsLoaded(true);
          localStorage.setItem("currentStep", (currentStep + 1).toString());

          // Move to the next step
          setCurrentStep(currentStep + 1);
        }
      } catch (error: any) {
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error?.response?.data?.message
            ? error?.response?.data?.message // setIsDisabled(false);ge
            : "An unknown error occured",
          visiblity: "show",
        });
      }
    } else {
      // setphone(phone.trim());
      setphoneError("Number beetween  be 7 and 13 digits.");
    }

    //  if(phone.value<0){
    //   setphoneError("mobile number must be a positive number")
    //   setIsDisabled(true)
    //  }
    // localStorage.removeItem("currentStep");
    // localStorage.removeItem("previousStep");
  };

  const goToNextStep = () => {
   
    // localStorage.setItem("bussinessname",(businessName).toString())
    // localStorage.setItem("bussinesswebname",(businessWeb).toString())
    // localStorage.setItem("bussinesaddress",(businessAddress).toString())
    // localStorage.setItem("point",(pointContactAddress).toString())

    let isValid = true;
    setEmailError("");
    setPasswordError("");
    if (email.trim() === "") {
      setIsDisabled(true);
      setEmailError("Requierd");
      isValid = false;
    } else if (!validateEmail(email.trim())) {
      setIsDisabled(true);
      setEmailError("Enter a valid email address");
      isValid = false;
    }
    if (password.trim() === "") {
      setIsDisabled(true);
      setPasswordError("Please enter password");
      isValid = false;
    } else {
      setIsDisabled(false);
      localStorage.setItem("currentStep", (currentStep + 1).toString());

      setCurrentStep(currentStep + 1);
    }
    // localStorage.setItem("email",(email).toString())
    setStoreData({
      phone,
      lName,
      fName,
      verify,
      getGSTag,
      email,
      password,
      checkBox,
      businessName,
      businessWeb,
      businessAddress,
      pointContactAddress,
      date,
      address,
      role,
    });
   
    localStorage.setItem("currentStep", (currentStep + 1).toString());
    // alert("jjjjjjjjj")
    // Move to the next step
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    setStoreData({
      phone,
      lName,
      fName,
      verify,
      getGSTag,
      email,
      password,
      checkBox,
      businessName,
      businessWeb,
      businessAddress,
      pointContactAddress,
      date,
      address,
      role,
    });

    localStorage.setItem("currentStep", (currentStep - 1).toString());

    // Move to the previous step
    setCurrentStep(currentStep - 1);
  };

  const [getGSTag, setGetGSTag] = useState<any>();
  const GetGsTag = async () => {
    try {
      const response: any = await Services.Auth.GET_GSTAG();
      setGetGSTag(response.data.gsTag);
    } catch (error) {}
  };

  useEffect(() => {
    GetGsTag();
    // Retrieve the current step from localStorage
    const savedStep = localStorage.getItem("currentStep");
    const email:any = localStorage.getItem("email");
    const password :any= localStorage.getItem("password");
    const gstag:any = localStorage.getItem("gsTag");
    const fname:any = localStorage.getItem("fname");
    const lname:any = localStorage.getItem("lname");
    const phone:any = localStorage.getItem("phone");
    const address:any = localStorage.getItem("address");
    const bussinessname:any = localStorage.getItem("bussinessname");
    const bussinessweb:any = localStorage.getItem("bussinesswebname");
    const bussinessaddress:any = localStorage.getItem("bussinesaddress");
    const point:any = localStorage.getItem("point");
    const date:any = localStorage.getItem("date");
setDate(date)
setBusinessAddress(bussinessaddress)
setBusinessName(bussinessname)
setBusinessWeb(bussinessweb)
setPointContactAddress(point)
    setAddress(address)
    setphone(phone)
    setFname(fname)
    setLname(lname)

   setEmail(email)
   setPassword(password)
   setGetGSTag(gstag)

    // Check if there's a saved step, and update the current step state accordingly
    if (savedStep) {
      setCurrentStep(parseInt(savedStep, 10));
    }
  }, []);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const handleSubmit = async () => {
    localStorage.removeItem("currentStep");
    localStorage.removeItem("previousStep");
    localStorage.removeItem("currentStep");
    localStorage.removeItem("previousStep");
    localStorage.removeItem("address");
    localStorage.removeItem("email");
    localStorage.removeItem("gsTag");
    localStorage.removeItem("fname");
    localStorage.removeItem("lname");
    localStorage.removeItem("password");
    localStorage.removeItem("phone");
    localStorage.removeItem("bussinessname");
    localStorage.removeItem("bussinesswebname");
    localStorage.removeItem("point");
    localStorage.removeItem("date");


    try {
      let data = {
        email: email.trim().toLocaleLowerCase(),
        password: password.trim(),
        firstName: fName.trim(),
        lastName: lName.trim(),
        phoneNumber: phone.trim(),
        gsTag: getGSTag,
        address: address.trim(),
        dob: date,
        businessName: businessName.trim(),
        businessWebsite: businessWeb.trim(),
        businessAddress: businessAddress.trim(),
        pocAddress: pointContactAddress.trim(),

        // role:role
      };
      const response = await Services.Auth.REGISTER_USER(data);

      if (response) {
        setIsLoaded(true);

        setTimeout(() => {
          navigate("/auth/finalstep");
        }, 700);

        setToaster({
          type: "success",
          title: "Successfull",
          text: "Account create successfully",
          visiblity: "show",
        });
      }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message // setIsDisabled(false);ge
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  const handleSkipSubmit = async () => {
    localStorage.removeItem("currentStep");
    localStorage.removeItem("previousStep");
    localStorage.removeItem("currentStep");
    localStorage.removeItem("previousStep");
    localStorage.removeItem("address");
    localStorage.removeItem("email");
    localStorage.removeItem("gsTag");
    localStorage.removeItem("fname");
    localStorage.removeItem("lname");
    localStorage.removeItem("password");
    localStorage.removeItem("phone");
    localStorage.removeItem("bussinessname");
    localStorage.removeItem("bussinesswebname");
    localStorage.removeItem("bussinesaddress");
    localStorage.removeItem("point");
    localStorage.removeItem("date");
    try {
      let data = {
        email: email.trim().toLocaleLowerCase(),
        password: password.trim(),
        firstName: fName.trim(),
        lastName: lName.trim(),
        phoneNumber: phone.trim(),
        gsTag: getGSTag,
        address: address.trim(),
        dob: date,
        businessName: businessName.trim(),
        businessWebsite: businessWeb.trim(),
        businessAddress: businessAddress.trim(),
        pocAddress: pointContactAddress.trim(),
        // role:role
      };
      // alert('hhhhhhhhhhh')
      const response:any = await Services.Auth.REGISTER_USER(data);
      // if (response.data.user.role === "User") {
        setIsLoaded(true);
        setTimeout(() => {
          navigate("/wallet");
        }, 700);

        setToaster({
          type: "success",
          title: "Successfull",
          text: "Account create successfully",
          visiblity: "show",
        });
      // }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message // setIsDisabled(false);ge
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  const handleFirstStep = () => {
    let isValid = true;
    localStorage.setItem("email",(email).toString())
    localStorage.setItem("password",(password).toString())
    localStorage.setItem("gsTag",(getGSTag).toString())
    
    setEmailError("");
    setPasswordError("");
    if (email.trim() === "") {
      setIsDisabled(true);
      setEmailError("Requierd");
      isValid = false;
    } else if (!validateEmail(email.trim())) {
      setIsDisabled(true);
      setEmailError("Enter a valid email address");
      isValid = false;
    }
    if (password.trim() === "") {
      setIsDisabled(true);
      setPasswordError("Please enter password");
      isValid = false;
    } else {
      if (validateEmail(email.trim())) {
        setIsDisabled(false);
        localStorage.setItem("currentStep", (currentStep + 1).toString());

        setCurrentStep(currentStep + 1);
      }
    }
  };

  const [timer, setTimer] = useState(30); // Initialize the timer to 30 seconds
  const [isResendEnabled, setIsResendEnabled] = useState(true);

  // Function to handle the Resend Code click

  // Function to start the timer
  const startTimer = () => {
    setIsResendEnabled(false);

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clear the interval and reset the timer when it reaches 0
    setTimeout(() => {
      clearInterval(intervalId);
      setTimer(30); // Reset the timer to 30 seconds
      setIsResendEnabled(true); // Re-enable the link
    }, 30000); // 30 seconds in milliseconds
  };

  // Use useEffect to clear the timer when the component unmounts
  // useEffect(() => {
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  // const inputStyle = {
  //   WebkitAppearance: 'none', // Removes the spin buttons in Webkit browsers
  //   margin: 0, // Removes any margin
  //   // Add any other styles you need
  // };
  return (
    <div>
      <div className="container">
        <Toaster
          type={toaster.type}
          title={toaster.title}
          text={toaster.text}
          visiblity={toaster.visiblity}
        />
        {!isLoaded && <Spinner />}
        {currentStep === 1 && (
          <div>
            <>
              <div
                className="text-center  "
                data-aos="fade-left"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="500"
              >
                <div className="text-center mb-5">
                  <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
                </div>

                <div>
                  <div className="mt-10">
                    <h6 className="text-start ">Let's create your account</h6>
                    <div className="mt-9 mb-5 ">
                      <h6 className="text-start ">Gs Tag</h6>
                      <input
                        className="form-control  mt-2"
                        type="number"
                        placeholder="GS tag"
                        value={getGSTag}
                        disabled
                        // onChange={(e) => {
                        //   if (e.target.value === "") {
                        //     setIsDisabled(true);
                        //     setGsTagError("Requierd");
                        //   } else {
                        //     setGstag(e.target.value.trim());
                        //     setGsTagError("");
                        //   }
                        //   setGstag(e.target.value.trim());
                        // }}
                        // onKeyDown={(e) =>
                        //   exceptThisSymbols.includes(e.key) &&
                        //   e.preventDefault()
                        // }
                      />
                    </div>
                  </div>
                  {/* {gsTagError ? (
                    <p className="text-start" style={{ color: "red" }}>
                      {gsTagError}
                    </p>
                  ) : null} */}
                  <div className="mt-9 mb-7">
                    <h6 className="text-start ">Email</h6>
                    <input
                      className="form-control  mt-2"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        if (e.target.value.trim() === "") {
                          setIsDisabled(true);

                          setEmailError("Requierd");
                        } else if (!validateEmail(e.target.value.trim())) {
                          setIsDisabled(true);
                          setEmailError("Enter a valid email address");
                        } else {
                          setEmail(e.target.value.trim());
                          setEmailError("");
                        }
                        setEmail(e.target.value.trimStart());
                      }}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />{" "}
                   {emailError ? (
                      <p className="text-start position-absolute mt-2" style={{ color: "red" }}>
                        {emailError}
                      </p>
                    ) : null}
                  </div>
                   

                  <div className="position-relative  mt-12 mb-5">
                    <h6 className="text-start ">Password</h6>

                    <input
                      type={passwordType}
                      id="password"
                      placeholder="Password"
                      name="password"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onChange={(e) => {
                        if (e.target.value.trim() === "") {
                          setIsDisabled(true);

                          setPasswordError("Requierd");
                        }  else {
                          setPassword(e.target.value.trimStart());
                          setPasswordError("");
                        }
                        // setEmail(e.target.value.trimStart());
                        setPassword(e.target.value.trimStart());
                      }}
                      value={password}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      // onKeyDown={(e) => {
                      //   e.key === "Enter"
                      //     ? !isDisabled
                      //       ? handleLogin()
                      //       : e.preventDefault()
                      //     : setPassword(e.target.value);
                      // }}
                      // Placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      // aria-describedby="password"
                    />
                    {/* <br /> */}
                    {passwordError ? (
                      <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                        {passwordError}
                      </p>
                    ) : null}
                    {/* {passwordError ? (
                      <small style={{ color: "red" }}>{passwordError}</small>
                    ) : null} */}
                    <span
                      className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-4"
                      data-kt-password-meter-control="visibility"
                      onClick={() => {
                        passwordType === "password"
                          ? setPasswordType("text")
                          : setPasswordType("password");
                      }}
                    >
                      {passwordType === "password" ? (
                        <i className="bi bi-eye fs-2"></i>
                      ) : (
                        <i className="bi bi-eye-slash fs-2"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="fv-row mb-7 float-start mt-10 ">
                  <label
                    className="form-check form-check-inline d-flex justify-content-sm-evenly align-items-center"
                    htmlFor="kt_login_toc_agree"
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="kt_login_toc_agree"
                      onClick={() => {
                        setCheckbox(true);
                      }}
                    />
                    <span className="ms-2 mt-1">
                      I agree with the term of use and privacy policy{" "}
                      <Link
                        to="https://keenthemes.com/metronic/?page=faq"
                        target="_blank"
                        className=" link-primary"
                      ></Link>
                      .
                    </span>
                  </label>
                </div>

                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      disabled={!(getGSTag && email && password && checkBox)}
                      // onClick={goToNextStep}
                      onClick={handleFirstStep}
                    >
                      Continue
                    </button>
                  )}

                  {currentStep === 1 && (
                    <Link to="/auth">
                      <h6 className="link-icon fw-bold text-center text-primary  fs-5">
                        {" "}
                        Alrady have an Account?
                      </h6>
                    </Link>
                  )}

                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      Previous
                    </button>
                  )}
                </div>
              </div>
            </>
          </div>
        )}
        {currentStep === 2 && (
          <div>
            <div
              className="text-center mt-2 p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="text-center mb-11">
                <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
              </div>

              <div>
                <div className="mt-9 mb-5">
                  <h6 className="text-start ">
                    We need to know you to serve you better
                  </h6>
                  <div className="mt-9 mb-5 ">
                    <h6 className="text-start ">Fist Name</h6>

                    <input
                      className="form-control mt-2"
                      type="text"
                      placeholder="First Name , Middle Name"
                      value={fName}
                      onChange={(e) => {
                        if (e.target.value.trim() === "") {
                          setIsDisabled(true);

                          setfnameError("Requierd");
                        }  else {
                          setFname(e.target.value.trimStart());
                          setfnameError("");
                        }
                        // setEmail(e.target.value.trimStart());
                        // setPassword(e.target.value.trimStart());
                        setFname(e.target.value.trimStart());
                      }}
                    />

{fnameError ? (
                      <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                        {fnameError}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-9 mb-">
                  <h6 className="text-start ">Last Name</h6>

                  <input
                    className="form-control  "
                    type="text"
                    placeholder="Last Name"
                    value={lName}
                    onChange={(e) => {
                      if (e.target.value.trim() === "") {
                        setIsDisabled(true);

                        setLnameError("Requierd");
                      }  else {
                        setLname(e.target.value.trimStart());
                        setLnameError("");
                      }
                      // setEmail(e.target.value.trimStart());
                      // setPassword(e.target.value.trimStart());
                      // setFname(e.target.value.trimStart());
                      setLname(e.target.value.trimStart());
                    }}
                  />
                  {LnameError ? (
                      <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                        {LnameError}
                      </p>
                    ) : null}
                </div>

                <div className="mt-9 mb-5">
                  <h6 className="text-start ">Phone Number</h6>
                  <input
                    className="form-control  mt-2"
                    type="number"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => {
                      const enteredNumber: any = e.target.value;
                      // Ensure that the entered number is not negativeNumber should be 10 digits.
                      if (enteredNumber >= 0 || enteredNumber === "") {
                        setphone(enteredNumber);
                        if (enteredNumber.length > 13) {
                          setIsDisabled(true);
                          setphoneError(
                            "must be enter beetween  7 and  13 digit"
                          );
                        } else if (enteredNumber.length === 0) {
                          setphoneError("Required");
                        } else {
                          setphoneError("");
                        }
                      }
                    }}
                    onKeyDown={(e: any) => {
                      exceptThisSymbols.includes(e.key) && e.preventDefault();
                      if (
                        e.target.value.length >= 13 &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete"
                      ) {
                        e.preventDefault();
                        setphoneError(
                          "must be enter beetween  7 and  13 digit"
                        );
                      }
                    }}
                    // onKeyDown={(e) =>
                    //   exceptThisSymbols.includes(e.key) && e.preventDefault()
                    // }
                  />{" "}

{phoneError ? (
                      <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                        {phoneError}
                      </p>
                    ) : null}
                  {/* {phoneError ? (
                    <p
                      className="text-start mt-1 "
                      style={{
                        color: "red",
                        fontSize: "12px",
                        position: "absolute",
                      }}
                    >
                      {phoneError}
                    </p>
                  ) : null} */}
                </div>
              </div>

              <div className="mt-9 mb-5">
                <h6 className="text-start "> Address</h6>
                <div className="mt-2">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder=" Address"
                    value={address}
                    onChange={(e) => {
                      if (e.target.value.trim() === "") {
                        setIsDisabled(true);

                        setAddressError("Requierd");
                      }  else {
                        setAddress(e.target.value.trimStart());
                        setAddressError("");
                      }
                      
                      setAddress(e.target.value.trimStart());
                    }}
                  />
                  {addressError ? (
                      <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                        {addressError}
                      </p>
                    ) : null}
                </div>
              </div>
              <div className="mt-9 mb-5">
                <h6 className="text-start ">Date of birth</h6>
                <div className="mt-2">
                  <input
                    className="form-control  mt-2"
                    type="date"
                    value={date}
                    placeholder="DD/MM/YY"
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  />


                </div>
              </div>

              <div className="text-center ">
                {currentStep < 9 && (
                  <button
                    type="submit"
                    id="kt_sign_up_submit"
                    className="btn btn-lg btn-primary w-100 mb-2"
                    disabled={!(fName && lName && phone && date && address)}
                    // onClick={goToNextStep}
                    onClick={handleSendOtp}
                  >
                    Continue
                  </button>
                )}

                {currentStep > 1 && (
                  <button
                    type="button"
                    id="kt_login_signup_form_cancel_button"
                    className="btn btn-lg btn-light-primary w-100 mb-5"
                    onClick={goToPreviousStep}
                  >
                    Previous
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {currentStep === 3 && (
          <div>
            <div
              className=" p-5 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="mt-20 ms-2 ">
                <h1 className="text-secondary-emphasis">
                  Enter Verification code
                </h1>
              </div>
              <div className="mt-5 ms-2 text-warning-emphasis">
                <span>
                  {" "}
                  A 4 digit verification code was sent to ********45{" "}
                </span>
              </div>
              <div className=" mt-4 mb-5 col-12 ">
                <input
                  className="form-control "
                  type="number"
                  name=""
                  id=""
                  // value={verify}
                  onChange={(e) => {
                    const enteredNumber: any = e.target.value;
                    // Ensure that the entered number is not negativeNumber should be 10 digits.
                    if (enteredNumber >= 0 || enteredNumber === "") {
                      setVerify(enteredNumber);
                      if (enteredNumber.length > 4) {
                        setIsDisabled(true);
                        setVerifyError("please enter 4 digit opt");
                      } else if (enteredNumber.length === "") {
                        setVerifyError("Required");
                      } else {
                        setVerify(e.target.value.trimStart());
                        setVerifyError("");
                      }
                    }
                  }}
                  // onChange={(e) => {
                  //   if(e.target.value.length>4){
                  //     setVerifyError("please enter 4 digit opt")
                  //   }else if(e.target.value.trim()===""){
                  //     setVerifyError("Requeird")
                  //   }
                  //   else{
                  //     setVerify(e.target.value.trim());
                  //     setVerifyError("")
                  //   }
                  //   setVerify(e.target.value.trimStart());
                  // }}

                  onKeyDown={(e: any) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                    if (
                      e.target.value.length >= 4 &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete"
                    ) {
                      e.preventDefault();
                      setVerifyError("must be enter 4 digit");
                    }
                  }}
                />{" "}
                {verifyError ? (
                  <p className="text-start mt-2 position-absolute" style={{ color: "red" }}>
                    {verifyError}
                  </p>
                ) : null}
              </div>
              {/* <div className="d-flex justify-content-between me-10 ">
                <div className="mt-5  fw-bold ">
                  <Link className="text-warning-emphasis" to="">
                    Having Trouble ?
                  </Link>
                </div> */}
              <div className="mt-2  fw-bold float-end">
                <div>
                  <Link
                    className={`text-warning-emphasis float-end ${
                      isResendEnabled ? "" : "disabled"
                    }`}
                    to="#"
                    onClick={isResendEnabled ? handleResendOtp : undefined}
                  >
                    <span>Resend Code</span>
                  </Link>

                  {/* <Link className="text-warning-emphasis float-end" to="">
                    <span onClick={handleResendOtp}> Resend Code</span>
                   
                  </Link> */}
                  <br />
                  <div className=" float-end ">
                    {" "}
                    {isResendEnabled ? null : (
                      <p className="fw-semibold">
                        Resend Code in {timer} seconds
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* </div> */}

              <div className="text-center  mt-20">
                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      disabled={!verify}
                      // onClick={goToNextStep}
                      onClick={handleVerifyOtp}
                    >
                      Verify
                    </button>
                  )}

                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      Previous
                    </button>
                  )}
                </div>{" "}
              </div>

              <div></div>
            </div>
          </div>
        )}
        {currentStep === 4 && (
          <div>
            <div
              className="text-center mt-8 p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="mt-9 mb-5">
                <h1>Business Information</h1>
                <div className="mt-10">
                  <h6 className="text-start ">Business Name</h6>

                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder="Legal business name"
                    value={businessName}
                    onChange={(e) => {

                      if (e.target.value.trim() === "") {
                        setIsDisabled(true);

                        setBusinessNameError("Requierd");
                      }  else {
                        setBusinessName(e.target.value.trimStart());
                        setBusinessNameError("");
                      }
                      
                      // setAddress(e.target.value.trimStart());
                      setBusinessName(e.target.value.trimStart());

                   
                    }}
                  />
                  {bussinessNameError ? (
                      <p className="text-start  position-absolute  mt-2" style={{ color: "red" }}>
                        {bussinessNameError}
                      </p>
                    ) : null}
                </div>
              </div>
              <div className="mt-9 mb-5">
                <h6 className="text-start ">Business Website</h6>

                <input
                  className="form-control  mt-2"
                  type="text"
                  placeholder="Business Website"
                  value={businessWeb}
                  onChange={(e) => {
                    if (e.target.value.trim() === "") {
                      setIsDisabled(true);

                      setBusinessWebError("Requierd");
                    }  else {
                      setBusinessWeb(e.target.value.trimStart());
                      setBusinessWebError("");
                    }
                    
                    // setAddress(e.target.value.trimStart());
                    // setBusinessName(e.target.value.trimStart());

                 
                    setBusinessWeb(e.target.value.trimStart());
                  }}
                />
                    {bussinessWebError ? (
                      <p className="text-start  position-absolute  mt-2" style={{ color: "red" }}>
                        {bussinessWebError}
                      </p>
                    ) : null}
              </div>
              {/* <div className="text-start  mt-9 mb-5">
                <p>Does your business have a website ?</p>
              </div>

              <div className="fv-row mb-8 float-start d-flex ">
                <div
                  className="text-start "
                  onClick={() => {
                    setCheck(true);
                  }}
                >
                  {" "}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="kt_login_toc_agree"
                  />
                  <span className="ms-2">Yes</span>
                </div>
                <div className="text-start ms-7">
                  {" "}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="kt_login_toc_agree"
                    onClick={() => {
                      setCheck(false);
                    }}
                  />{" "}
                  <span className="ms-2">No</span>
                </div>
              </div> */}
              <div className="text-center  mt-10">
                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      onClick={goToNextStep}
                      disabled={!(businessName && businessWeb )}
                    >
                      Continue
                    </button>
                  )}

                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      Previous
                    </button>
                  )}
                </div>{" "}
              </div>
            </div>
          </div>
        )}
        {currentStep === 5 && (
          <div>
            <div
              className="text-center mt-8 p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className=" text-warning-emphasis">
                <h1>Business Address</h1>
              </div>
              <div className="mt-15  mb-5">
                <h6 className="text-start ">Business Address</h6>
                <div className="mt-2 ">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder="Business Address"
                    value={businessAddress}
                    onChange={(e) => {
                      if (e.target.value.trim() === "") {
                        setIsDisabled(true);
  
                        setBusinessAddressError("Requierd");
                      }  else {
                        setBusinessAddress(e.target.value.trimStart());
                        setBusinessAddressError("");
                      }
                      
                      // setAddress(e.target.value.trimStart());
                      // setBusinessName(e.target.value.trimStart());
  
                   
                      // setBusinessWeb(e.target.value.trimStart());
                      setBusinessAddress(e.target.value);
                    }}
                  />
                    {bussinessAddressError ? (
                      <p className="text-start  position-absolute  mt-2" style={{ color: "red" }}>
                        {bussinessAddressError}
                      </p>
                    ) : null}
                </div>
              </div>
              <div className="mt-9 mb-5">
                <h6 className="text-start ">Point of contact address</h6>

                <div className="mt-2">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder="Point of contact address"
                    value={pointContactAddress}
                    onChange={(e) => {
                      if (e.target.value.trim() === "") {
                        setIsDisabled(true);
  
                        setPointError("Requierd");
                      }  else {
                        setPointContactAddress(e.target.value.trimStart());
                        setPointError("");
                      }
                      
                      // setAddress(e.target.value.trimStart());
                      // setBusinessName(e.target.value.trimStart());
  
                   
                      // setBusinessWeb(e.target.value.trimStart());
                      // setBusinessAddress(e.target.value);
                      setPointContactAddress(e.target.value);
                    }}
                  />
                    {pointError ? (
                      <p className="text-start  position-absolute  mt-2" style={{ color: "red" }}>
                        {pointError}
                      </p>
                    ) : null}
                </div>
              </div>
              <div className="text-center  mt-10">
                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      onClick={goToNextStep}
                      disabled={!(businessAddress && pointContactAddress)}
                    >
                      Continue
                    </button>
                  )}

                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      Previous
                    </button>
                  )}
                </div>{" "}
              </div>
            </div>
          </div>
        )}
        {currentStep === 6 && (
          <div>
            <div
              className="text-center mt-8 p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className=" text-warning-emphasis">
                <h1>Review your information</h1>
              </div>
              <div className="mt-4 text-black-50">
                <p>
                  your information will be used to verify your identity, kindly
                  confirm everything is correct to avoid delays.
                </p>
              </div>
              <div className="mt-5">
                <h6 className="text-start ">Name</h6>
                <div className="mt-2 ">
                  <input
                    className="form-control mt-2"
                    type="text"
                    placeholder="Name"
                    disabled
                    value={`${fName}${" "}${lName}`}
                  />
                </div>
              </div>
              <div className="mt-5">
                <h6 className="text-start "> Address</h6>
                <div className="mt-2">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder=" Address"
                    disabled
                    value={address}
                  />
                </div>
              </div>
              <div className="mt-5 mb-6">
                <h6 className="text-start ">Date of birth</h6>
                <div className="mt-2">
                  <input
                    className="form-control  mt-2"
                    type="date"
                    value={date}
                    placeholder="DD/MM/YY"
                    disabled
                  />
                </div>
              </div>
              <div className="text-center  mt-10">
                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      onClick={goToNextStep}
                    >
                      Continue
                    </button>
                  )}

                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      Previous
                    </button>
                  )}
                </div>{" "}
              </div>
            </div>
          </div>
        )}
        {currentStep === 7 && (
          <div>
            <div
              className="text-center mt-8 p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className=" text-warning-emphasis">
                <h1>Review your business information</h1>
              </div>
              <div className="mt-5 text-black-50">
                <p>
                  your information will be used to verify your identity, kindly
                  confirm everything is correct to avoid delays.
                </p>
              </div>
              <div className="mt-5">
                <h6 className="text-start ">Business Name</h6>

                <div className=" ">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder="business name"
                    disabled
                    value={businessName}
                  />
                </div>
              </div>
              <div className="mt-5">
                <h6 className="text-start ">Business Address</h6>

                <div className="">
                  <input
                    className="form-control mt-2"
                    type="text"
                    disabled
                    placeholder="Business Address"
                    value={businessAddress}
                  />
                </div>
              </div>
              <div className="mt-5 mb-6">
                <h6 className="text-start ">Business Website</h6>

                <div className="">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder="Business Website"
                    value={businessWeb}
                    disabled
                  />
                </div>
              </div>
              <div className="text-center  mt-10">
                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      onClick={goToNextStep}
                    >
                      Continue
                    </button>
                  )}

                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      Previous
                    </button>
                  )}
                </div>{" "}
              </div>
            </div>
          </div>
        )}
        {currentStep === 8 && (
          <div>
            <div
              className="  p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="conatiner d-flex justify-content-between ">
                <span className="mb-5" onClick={handleSkipSubmit}>
                  <Link to="#">Skip</Link>
                </span>
              </div>
              <div className="conatiner text-center mt-5">
                <div>
                  <h2>Complete account verification</h2>
                </div>
                <div>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
                    height={10}
                    width={20}
                    alt=""
                  />
                  2 minutes
                </div>
                <div className="container  p-2 text-dark bg-opacity-25 col-12  rounded ">
                  <p className="text-start mt-5 text-muted">
                    Take full advantage of GS App, we recommend completing this
                    step now by submitting a government ID. Doing this now will
                    give you full access to all of GS features{" "}
                  </p>
                  <p className="text-start mt-5 text-muted">
                    You may skip this step but we'll likely ask you again
                    sometime soon
                  </p>
                </div>
                <div>
                  <div className="text-center  mt-10">
                    <div className="text-center ">
                      {currentStep < 9 && (
                        <button
                          type="submit"
                          id="kt_sign_up_submit"
                          className="btn btn-lg btn-primary w-100 mb-2"
                          onClick={handleSubmit}
                        >
                          Continue
                        </button>
                      )}

                      {currentStep > 1 && (
                        <button
                          type="button"
                          id="kt_login_signup_form_cancel_button"
                          className="btn btn-lg btn-light-primary w-100 mb-5"
                          onClick={goToPreviousStep}
                        >
                          Previous
                        </button>
                      )}
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
