/* eslint-disable import/no-anonymous-default-export */
export default {
 
    GET_TRANSACTIONS: () => `/transaction/`,
    GET_USER_TRANSACTIONS: () => `/transaction/getByUserId`,
    GET_TRANSACTION_HISTORY: (id) => `/transactionHistory/${id}`,
    GET_TRANSACTIONS_BY_ID: (id,query) => `/gsTransitionHistory/gs/user/${id}?typeOfTxn=${query}`,
    GET_USER_WITHDRAW_TRANSACTIONS: () => `/withdrawalRequest`,
    GET_USER_WITHDRAW_TRANSACTIONS_BY_ID:(id)=>`/withdrawalRequest/${id}`,
  };
