
import React from 'react';
import styles from '../scss/Card.module.scss'; // Import CSS module
import { toAbsoluteUrl } from '../_metronic/helpers';

const Card = () => {
  return (
    <div className="">
      <div className="row justify-content-center">
    <div className={styles.container}>
    {/* <h4 className={styles.text}>Rahul Sharma</h4>  */}
    <div className={styles.imgwifi}>
<img  src={toAbsoluteUrl('/media/icons/wifi.png')} alt='' height={30} width={30}/>
</div>
   <div className='d-flex'>
    <h3 className={styles.handlename}>Rahul Sharma 
   <span>07/26</span> 
   </h3>
  </div>


    <div>
    </div>
    <div className={styles.handleDigits}>
    519075
    </div>
    </div>
     <div className='text-center me-10 '>
      <img className="border border-warning" src={toAbsoluteUrl('/media/icons/QRScan.png')} height={200} width={200} alt='' />
      </div>
      </div>
      </div>
  );
};

export default Card;


// import React from 'react';
// import newcard from "../assets/newCard.png";
// import paypassicon from "../assets/PayPassIcon.svg";
// import payicon from "../assets/paymentmethodicon.svg"


// export default function Card() {
//   return (
//     <div className=''>
      
//     <div className='container col-6 '>
//       <div  className={`ts_vr-card ${"ts_vr-sm-card"}`}>
//         {/* <img src={}></img> */}

//       </div>
//         <div className={`ts_vr-card ${"ts_vr-sm-card"}`} style={{backgroundImage:`url(${newcard})`,backgroundSize: 'cover',backgroundRepeat: 'no-repeat',maxWidth: '100%'}}>
//       {/* <img src={cardLines} alt="" /> */}
//       <div className="ts_vr-card-tps">
//         <h3 className=''> Rahul Sharma</h3>
//         <img src={paypassicon} alt="" />
//       </div>
//       <div className="ts_vr-card-bt p-10">
//         <div className="ts_vr-card-dt ">
//           <div className="d-flex gap-2 flex-wrap justify-content-between ">
//             <p>Rahul Sharma</p>
//             <p>0000 00000 0</p>
//             <p>
//               5/16
//             </p>
//           </div>
//           <p className="ts_vr-card-number ">353454</p>
          
//         </div>
        
//         <img  className="p-10" src={payicon} alt="" />
//         <p>jwdnjasn</p>
//         <p>sndjasndaijiajxiak</p>

//       </div>
//     </div>
      {/* <div>
         <img  src = "https://img.freepik.com/free-vector/realistic-credit-card-design_23-2149126090.jpg?w=2000" height={500} width={500}></img>
        </div> */}
        {/* <div className='mt-20'>
        <img src='https://c8.alamy.com/comp/2GFA1J8/black-qr-code-with-text-scanning-isolated-on-white-background-vector-2GFA1J8.jpg' height={300} width={300}/>
      </div> */}
      {/* </div>

      </div>

  )
} */}
