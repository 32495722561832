import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'



import {AuthLayout} from './AuthLayout'
import { Login } from './components/Login'
import SignUpStepOne from './components/SignUpStepOne'
import { ForgotPassword } from './components/ForgotPassword'
import FinalStep from './components/steps/FinalStep'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
   
      <Route path='registration' element={<Registration />} />
      <Route path='signupstepone' element={<SignUpStepOne />} />
    
          <Route path="finalstep" element={<FinalStep />}/>
        

      
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
