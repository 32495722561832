import React from 'react'
import { Link } from 'react-router-dom';
import styles from "../../scss/Card.module.scss"

export default function Statment() {
  return (
    <div data-aos="fade-left"
    data-aos-anchor="#example-anchor"
    data-aos-offset="500"
    data-aos-duration="500">
      
      <div className='d-flex'>
      <div>
    <Link to='/accountsetting'>
      <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU' height={20} width={30} alt='' /></Link>
  </div>
        <h2 className={styles.head}>Statement & Documents</h2>
    </div>
    
       <div className='bg-white d-flex justify-content-start ms-4 shadow-sm p-10 mb-5 bg-body-tertiary rounded mt-10'>
        <div className='  col-lg-12  ' >
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>Date Range <br/>
            Last 6 months</p>

            </div>
            <div className='mt-1 ms-1'>
              <Link to='/accountsetting'>   <i className="text-warning fs-2 bi bi-check2-circle"></i></Link>
             
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>Jan 2023</p>
            </div>
            <div className='mt-1 ms-1'>
            <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>Fab 2023</p>
            </div>
            <div className='mt-1 ms-1'>
            <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>March 2023</p>
            </div>
            <div className='mt-1 ms-1'>
            <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>April 2023</p>
            </div>
            <div className='mt-1 ms-2'>
            <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>may 2023</p>
            </div>
            <div className='mt-1 ms-2'>
            <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </div>
          </div>
          <div className='conatiner d-flex justify-content-between mt-5'>
          <div >
            <p className='fw-semibold'>June 2023</p>
            </div>
            <div className='mt-1 ms-1'>
            <i className="text-warning fs-2 bi bi-chevron-right"></i>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}
